import { FormControl, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import styled from '@emotion/styled';
import { DatePicker } from '@mui/x-date-pickers';
import FilterWrapper from './FilterWrapper';
import { PopoverFiltersProps } from './helpers/types';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:not(.Mui-error):hover fieldset': {
      borderColor: 'transparent',
    },
    '&:not(.Mui-error).Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: '#666666',
  },
  '& .MuiIconButton-root': {
    marginRight: 0,
  },
});

function TimeStampPopoverFilters({
  filter,
  onValidate = undefined,
  onRemoveFilter = undefined,
  advancedSearchRequest = undefined,
  setAdvancedSearchRequest = undefined,
  titleSx,
}: PopoverFiltersProps) {
  const {
    id,
    title,
    periode: isPeriod = false,
    showSearchButton,
    termSheetValueType = undefined,
  } = filter;
  const [searchRequest, setSearchRequest] = useState(
    advancedSearchRequest ?? {}
  );
  const getCurrentValue = (type: 'from' | 'to') => {
    if (termSheetValueType) {
      const index = searchRequest?.termSheetQuery?.findIndex(
        (x: any) => x?.elementId === id
      );
      if (index >= 0 && isPeriod) {
        return type === 'from'
          ? searchRequest?.termSheetQuery[index].items.from
          : searchRequest?.termSheetQuery[index].items.to;
      }
      if (index >= 0 && !isPeriod) {
        return searchRequest?.termSheetQuery[index].items;
      }
      return '';
    }
    if (isPeriod) {
      return type === 'from'
        ? searchRequest?.[id]?.from ?? null
        : searchRequest?.[id]?.to ?? null;
    }
    return searchRequest?.[id] ?? null;
  };
  const [fromDate, setFromDate] = useState(getCurrentValue('from'));
  const prevFromDateRef = useRef<Date>(getCurrentValue('from'));
  const [toDate, setToDate] = useState(isPeriod ? getCurrentValue('to') : null);
  const prevToDateRef = useRef<Date | undefined>(
    isPeriod ? getCurrentValue('to') : null
  );
  const setCurrentValue = (clean = false) => {
    const tmpValue = cloneDeep(searchRequest);
    if (termSheetValueType) {
      const index = tmpValue.termSheetQuery?.findIndex(
        (x: any) => x?.elementId === id
      );
      if (index >= 0) {
        tmpValue.termSheetQuery.splice(index, 1);
      }
      if (
        (fromDate !== '' && isPeriod && !clean) ||
        (!isPeriod && fromDate !== undefined && toDate !== undefined && !clean)
      ) {
        if (tmpValue.termSheetQuery === undefined) {
          tmpValue.termSheetQuery = [];
        }
        tmpValue.termSheetQuery.push({
          elementId: id,
          items: isPeriod
            ? {
                from: fromDate,
                to: toDate,
              }
            : fromDate,
        });
      }
      setSearchRequest(tmpValue);
    } else if (
      ((fromDate === null || fromDate === '') && !isPeriod) ||
      (isPeriod &&
        (fromDate === null || fromDate === '') &&
        (toDate === null || toDate === '')) ||
      clean
    ) {
      // eslint-disable-next-line no-param-reassign
      delete searchRequest[id];
      setSearchRequest({ ...searchRequest });
      setFromDate(null);
      setToDate(null);
    } else {
      setSearchRequest({
        ...searchRequest,
        [id]: isPeriod
          ? {
              from: fromDate,
              to: toDate,
            }
          : fromDate,
      });
    }
  };

  useEffect(() => {
    if (
      prevFromDateRef.current !== fromDate ||
      prevToDateRef.current !== toDate
    ) {
      setCurrentValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate]);

  const onchange = (value: any, type: 'from' | 'to') => {
    if (
      setAdvancedSearchRequest === undefined ||
      advancedSearchRequest === undefined
    ) {
      return;
    }
    const newValue = value;
    if (type === 'from') {
      if (newValue instanceof Date) {
        newValue.setUTCHours(0, 0, 0, 0);
      }
      setFromDate(newValue);
    } else {
      if (newValue instanceof Date) {
        newValue.setUTCHours(23, 59, 59, 59);
      }
      setToDate(newValue);
    }
  };

  const onClear = () => {
    setCurrentValue(true);
  };
  const onDelete = () => {
    onClear();
    if (onRemoveFilter) {
      onRemoveFilter(id);
    }
  };
  const onLocalValidate = () => {
    if (setAdvancedSearchRequest) {
      setAdvancedSearchRequest(searchRequest);
    }
    if (onValidate) {
      onValidate();
    }
  };
  return (
    <FilterWrapper
      showSearchButton={showSearchButton}
      onValidate={onLocalValidate}
      onClear={onClear}
      onDelete={onDelete}
      title={title}
      isRemovable={termSheetValueType !== undefined}
      titleSx={titleSx}
    >
      <FormControl fullWidth>
        <Stack
          direction="row"
          sx={{ width: '320px' }}
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <DatePicker
            value={fromDate}
            onChange={value => onchange(value, 'from')}
            maxDate={isPeriod ? toDate ?? new Date() : new Date()}
            renderInput={params => (
              <StyledTextField
                {...params}
                sx={{ width: !isPeriod ? '320px' : 'auto' }}
              />
            )}
          />
          {isPeriod && (
            <>
              <Typography variant="caption">au</Typography>
              <DatePicker
                value={toDate}
                minDate={fromDate ?? null}
                maxDate={new Date()}
                onChange={value => onchange(value, 'to')}
                renderInput={params => <StyledTextField {...params} />}
              />
            </>
          )}
        </Stack>
      </FormControl>
    </FilterWrapper>
  );
}

export default TimeStampPopoverFilters;
