import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import useFetch from '@hooks/useFetch';
import { bold } from '@utils/chunks';
import { useWorkflowContext } from '@components/WorkflowGeneric/WorkflowContextProvider';

function TemplateWorkflowHeader({ id, name = '', backUrl }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const {
    dirty,
    valid,
    methods: { undirtify, mapForApi },
  } = useWorkflowContext();
  const { enqueueSnackbar } = useSnackbar();
  const { doFetch: doPost } = useFetch({
    url: `/templates/${id}/workflow-schema`,
    method: 'PUT',
    cachePolicy: 'network-only',
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: 'Workflow.Settings.Save.Success' }), {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      undirtify();
    },
  });
  const onSaveCallback = React.useCallback(() => {
    doPost({ body: mapForApi() });
  }, [mapForApi, doPost]);
  return (
    <AppBar position="relative" elevation={0} color="transparent">
      <Toolbar variant="dense" disableGutters sx={{ mr: 2, px: 4 }}>
        <Typography variant="body">
          <FormattedMessage
            id="Workflow.Settings.Title"
            values={{ name, b: bold }}
          />
        </Typography>
        <IconButton
          edge="start"
          color="grey"
          aria-label="back"
          sx={{ ml: 'auto', mr: 1 }}
          onClick={() => navigate(location?.state?.backUrl ?? backUrl ?? '..')}
        >
          <ArrowBackIcon />
        </IconButton>
        <Button
          variant="outlined"
          disabled={!dirty || !valid}
          onClick={onSaveCallback}
        >
          <FormattedMessage id="Verbs.Save" />
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default TemplateWorkflowHeader;
