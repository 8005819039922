import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import MoveModal from '@components/Modals/MoveModal';
import withPermissions from '@components/withTenantPermissions';
import { getTemplateDriveUrl } from './MoveTemplateModal';

function MoveTemplateFolderModal({ onSuccess, basePath }) {
  const { id: templateFolderId } = useParams();
  const url = `templates/folders/${templateFolderId}/move`;
  return (
    <MoveModal
      title={
        <FormattedMessage
          id="MoveTemplateFolderModal.Label"
          defaultMessage="Déplacer dossier"
        />
      }
      url={url}
      folderIdKeyName="parentId"
      getDriveUrl={getTemplateDriveUrl}
      onSuccess={onSuccess}
      basePath={basePath}
    />
  );
}

export const MoveTemplateFolderModalWithPermissions = withPermissions(
  MoveTemplateFolderModal
);

export default MoveTemplateFolderModal;
