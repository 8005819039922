const tenantPermissions = {
  None: 0,
  All: 32767,
  UsersList: 1,
  UsersCreate: 2,
  UsersUpdate: 3,
  UsersDetail: 4,
  UsersDelete: 5,
  UsersExternalList: 6,
  UsersExternalCreate: 7,
  UsersExternalUpdate: 8,
  UsersExternalDetail: 9,
  UsersExternalDelete: 10,
  RolesList: 100,
  RolesCreate: 101,
  TemplatesList: 200,
  TemplatesCreate: 201,
  TemplatesUpdate: 202,
  TemplatesDetail: 203,
  TemplatesDelete: 204,
  TemplateFoldersList: 205,
  TemplateFoldersCreate: 206,
  TemplateFoldersUpdate: 207,
  TemplateFoldersDetail: 208,
  TemplateFoldersDelete: 209,
  TemplatesPublish: 210,
  TemplatesUnpublish: 211,
  ReferentialsList: 300,
  ReferentialsCreate: 301,
  ReferentialsUpdate: 302,
  ReferentialsDetail: 303,
  ReferentialsDelete: 304,
  ReferentialElementsList: 305,
  ReferentialElementsCreate: 306,
  ReferentialElementsUpdate: 307,
  ReferentialElementsDetail: 308,
  ReferentialElementsDelete: 309,
  ReferentialProviderInfoList: 310,
  ReferentialProviderInfoCreate: 311,
  ReferentialProviderInfoUpdate: 312,
  ReferentialProviderInfoDetail: 313,
  ReferentialProviderInfoDelete: 314,
  ReferentialProviderInfoTemplateUpdate: 315,
  DepartmentsList: 400,
  DepartmentsCreate: 401,
  DepartmentsUpdate: 402,
  DepartmentsDetail: 403,
  DepartmentsDelete: 404,
  DepartmentExpertiseUsersList: 405,
  DepartmentExpertiseUsersCreate: 406,
  DepartmentExpertiseUsersUpdate: 407,
  DepartmentExpertiseUsersDetail: 408,
  DepartmentExpertiseUsersDelete: 409,
  TeamsList: 500,
  TeamsCreate: 501,
  TeamsUpdate: 502,
  TeamsDetail: 503,
  TeamsDelete: 504,
  TeamUsersList: 505,
  TeamUsersCreate: 506,
  TeamUsersUpdate: 507,
  TeamUsersDetail: 508,
  TeamUsersDelete: 509,
  TenantsList: 600,
  TenantsCreate: 601,
  TenantsUpdate: 602,
  TenantsDetail: 603,
  TenantsDelete: 604,
  DocumentsList: 700,
  DocumentsCreate: 701,
  DocumentsUpdate: 702,
  DocumentsDetail: 703,
  DocumentsDelete: 704,
  DocumentFoldersList: 705,
  DocumentFoldersCreate: 706,
  DocumentFoldersUpdate: 707,
  DocumentFoldersDetail: 708,
  DocumentFoldersDelete: 709,
  DocumentsUpload: 710,
  ContractsList: 800,
  ContractsCreate: 801,
  ContractsUpdate: 802,
  ContractsDetail: 803,
  ContractsDelete: 804,
  ContractsAccessRead: 805,
  ContractsAccessWrite: 806,
  ContractsPdf: 807,
  DashboardView: 900,
  SuppliersList: 1000,
  SuppliersCreate: 1001,
  SuppliersUpdate: 1002,
  SuppliersDetail: 1003,
  SuppliersDelete: 1004,
  SuppliersLock: 1005,
  SuppliersUnlock: 1006,
  ExpertisesList: 1100,
  ExpertisesCreate: 1101,
  ExpertisesUpdate: 1102,
  ExpertisesDetail: 1103,
  ExpertisesDelete: 1104,
  ContractualPacksList: 1200,
  ContractualPacksCreate: 1201,
  ContractualPacksUpdate: 1202,
  ContractualPacksDetail: 1203,
  ContractualPacksDelete: 1204,
  CampaignsList: 1300,
  CampaignsCreate: 1301,
  CampaignsUpdate: 1302,
  CampaignsDetail: 1303,
  CampaignsDelete: 1304,
  HtmlContentsList: 1400,
  HtmlContentsCreate: 1401,
  HtmlContentsUpdate: 1402,
  HtmlContentsDetail: 1403,
  HtmlContentsDelete: 1404,
  HelpCenterFilesList: 1500,
  HelpCenterFilesCreate: 1501,
  HelpCenterFilesUpdate: 1502,
  HelpCenterFilesDetail: 1503,
  HelpCenterFilesDelete: 1504,
  TermSheetElementsList: 1700,
  TermSheetElementsCreate: 1701,
  TermSheetElementsUpdate: 1702,
  TermSheetElementsDetail: 1703,
  TermSheetElementsDelete: 1704,
  TermSheetValuesList: 1705,
  TermSheetValuesCreate: 1706,
  TermSheetValuesUpdate: 1707,
  TermSheetValuesDetail: 1708,
  TermSheetValuesDelete: 1709,
  FormsCreate: 1800,
  FormsStatus: 1801,
  DefaultValuesList: 1900,
  DefaultValuesCreate: 1901,
  DefaultValuesUpdate: 1902,
  DefaultValuesDetail: 1903,
  DefaultValuesDelete: 1904,
  TokensList: 2000,
  TokensCreate: 2001,
  TokensUpdate: 2002,
  TokensDetail: 2003,
  TokensDelete: 2004,
  SignatureCreate: 2102,
  SignatureDelete: 2103,
  SeedingUsers: 2200,
  SeedingTenants: 2201,
  ClausesList: 2300,
  ClausesCreate: 2301,
  ClausesUpdate: 2302,
  ClausesDetail: 2303,
  ClausesDelete: 2304,
  ClausesPublish: 2305,
  ClausesUnpublish: 2306,
  ClauseFoldersList: 2320,
  ClauseFoldersCreate: 2321,
  ClauseFoldersUpdate: 2322,
  ClauseFoldersDetail: 2323,
  ClauseFoldersDelete: 2324,
  ClauseFoldersRestore: 2325,
};

export const featurePermissions = {
  Users: [
    tenantPermissions.UsersList,
    tenantPermissions.UsersCreate,
    tenantPermissions.UsersUpdate,
    tenantPermissions.UsersDetail,
    tenantPermissions.UsersDelete,
    tenantPermissions.UsersExternalList,
    tenantPermissions.UsersExternalCreate,
    tenantPermissions.UsersExternalUpdate,
    tenantPermissions.UsersExternalDetail,
    tenantPermissions.UsersExternalDelete,
  ],
  Roles: [tenantPermissions.RolesList, tenantPermissions.RolesCreate],
  Templates: [
    tenantPermissions.TemplatesList,
    tenantPermissions.TemplatesCreate,
    tenantPermissions.TemplatesUpdate,
    tenantPermissions.TemplatesDetail,
    tenantPermissions.TemplatesDelete,
    tenantPermissions.TemplateFoldersList,
    tenantPermissions.TemplateFoldersCreate,
    tenantPermissions.TemplateFoldersUpdate,
    tenantPermissions.TemplateFoldersDetail,
    tenantPermissions.TemplateFoldersDelete,
    tenantPermissions.TemplatesPublish,
    tenantPermissions.TemplatesUnpublish,
  ],
  Referentials: [
    tenantPermissions.ReferentialsList,
    tenantPermissions.ReferentialsCreate,
    tenantPermissions.ReferentialsUpdate,
    tenantPermissions.ReferentialsDetail,
    tenantPermissions.ReferentialsDelete,
    tenantPermissions.ReferentialElementsList,
    tenantPermissions.ReferentialElementsCreate,
    tenantPermissions.ReferentialElementsUpdate,
    tenantPermissions.ReferentialElementsDetail,
    tenantPermissions.ReferentialElementsDelete,
    tenantPermissions.ReferentialProviderInfoList,
    tenantPermissions.ReferentialProviderInfoCreate,
    tenantPermissions.ReferentialProviderInfoUpdate,
    tenantPermissions.ReferentialProviderInfoDetail,
    tenantPermissions.ReferentialProviderInfoDelete,
    tenantPermissions.ReferentialProviderInfoTemplateUpdate,
  ],
  Departments: [
    tenantPermissions.DepartmentsList,
    tenantPermissions.DepartmentsCreate,
    tenantPermissions.DepartmentsUpdate,
    tenantPermissions.DepartmentsDetail,
    tenantPermissions.DepartmentsDelete,
    tenantPermissions.DepartmentExpertiseUsersList,
    tenantPermissions.DepartmentExpertiseUsersCreate,
    tenantPermissions.DepartmentExpertiseUsersUpdate,
    tenantPermissions.DepartmentExpertiseUsersDetail,
    tenantPermissions.DepartmentExpertiseUsersDelete,
  ],
  Teams: [
    tenantPermissions.TeamsList,
    tenantPermissions.TeamsCreate,
    tenantPermissions.TeamsUpdate,
    tenantPermissions.TeamsDetail,
    tenantPermissions.TeamsDelete,
    tenantPermissions.TeamUsersList,
    tenantPermissions.TeamUsersCreate,
    tenantPermissions.TeamUsersUpdate,
    tenantPermissions.TeamUsersDetail,
    tenantPermissions.TeamUsersDelete,
  ],
  Tenants: [
    tenantPermissions.TenantsList,
    tenantPermissions.TenantsCreate,
    tenantPermissions.TenantsUpdate,
    tenantPermissions.TenantsDetail,
    tenantPermissions.TenantsDelete,
  ],
  Documents: [
    tenantPermissions.DocumentsList,
    tenantPermissions.DocumentsCreate,
    tenantPermissions.DocumentsUpdate,
    tenantPermissions.DocumentsDetail,
    tenantPermissions.DocumentsDelete,
    tenantPermissions.DocumentFoldersList,
    tenantPermissions.DocumentFoldersCreate,
    tenantPermissions.DocumentFoldersUpdate,
    tenantPermissions.DocumentFoldersDetail,
    tenantPermissions.DocumentFoldersDelete,
    tenantPermissions.DocumentsUpload,
  ],
  Contracts: [
    tenantPermissions.ContractsList,
    tenantPermissions.ContractsCreate,
    tenantPermissions.ContractsUpdate,
    tenantPermissions.ContractsDetail,
    tenantPermissions.ContractsDelete,
    tenantPermissions.ContractsAccessRead,
    tenantPermissions.ContractsAccessWrite,
    tenantPermissions.ContractsPdf,
  ],
  Dashboard: [tenantPermissions.DashboardView],
  Suppliers: [
    tenantPermissions.SuppliersList,
    tenantPermissions.SuppliersCreate,
    tenantPermissions.SuppliersUpdate,
    tenantPermissions.SuppliersDetail,
    tenantPermissions.SuppliersDelete,
    tenantPermissions.SuppliersLock,
    tenantPermissions.SuppliersUnlock,
  ],
  Expertises: [
    tenantPermissions.ExpertisesList,
    tenantPermissions.ExpertisesCreate,
    tenantPermissions.ExpertisesUpdate,
    tenantPermissions.ExpertisesDetail,
    tenantPermissions.ExpertisesDelete,
  ],
  ContractualPacks: [
    tenantPermissions.ContractualPacksList,
    tenantPermissions.ContractualPacksCreate,
    tenantPermissions.ContractualPacksUpdate,
    tenantPermissions.ContractualPacksDetail,
    tenantPermissions.ContractualPacksDelete,
  ],
  Campaigns: [
    tenantPermissions.CampaignsList,
    tenantPermissions.CampaignsCreate,
    tenantPermissions.CampaignsUpdate,
    tenantPermissions.CampaignsDetail,
    tenantPermissions.CampaignsDelete,
  ],
  HtmlContents: [
    tenantPermissions.HtmlContentsList,
    tenantPermissions.HtmlContentsCreate,
    tenantPermissions.HtmlContentsUpdate,
    tenantPermissions.HtmlContentsDetail,
    tenantPermissions.HtmlContentsDelete,
  ],
  HelpCenterFiles: [
    tenantPermissions.HelpCenterFilesList,
    tenantPermissions.HelpCenterFilesCreate,
    tenantPermissions.HelpCenterFilesUpdate,
    tenantPermissions.HelpCenterFilesDetail,
    tenantPermissions.HelpCenterFilesDelete,
  ],
  TermSheets: [
    tenantPermissions.TermSheetElementsList,
    tenantPermissions.TermSheetElementsCreate,
    tenantPermissions.TermSheetElementsUpdate,
    tenantPermissions.TermSheetElementsDetail,
    tenantPermissions.TermSheetElementsDelete,
    tenantPermissions.TermSheetValuesList,
    tenantPermissions.TermSheetValuesCreate,
    tenantPermissions.TermSheetValuesUpdate,
    tenantPermissions.TermSheetValuesDetail,
    tenantPermissions.TermSheetValuesDelete,
  ],
  Forms: [tenantPermissions.FormsCreate, tenantPermissions.FormsStatus],
  DefaultValues: [
    tenantPermissions.DefaultValuesList,
    tenantPermissions.DefaultValuesCreate,
    tenantPermissions.DefaultValuesUpdate,
    tenantPermissions.DefaultValuesDetail,
    tenantPermissions.DefaultValuesDelete,
  ],
  Tokens: [
    tenantPermissions.TokensList,
    tenantPermissions.TokensCreate,
    tenantPermissions.TokensUpdate,
    tenantPermissions.TokensDetail,
    tenantPermissions.TokensDelete,
  ],
  Signature: [
    tenantPermissions.SignatureCreate,
    tenantPermissions.SignatureDelete,
  ],
  Seeding: [tenantPermissions.SeedingUsers, tenantPermissions.SeedingTenants],
  Clauses: [
    tenantPermissions.ClausesList,
    tenantPermissions.ClausesCreate,
    tenantPermissions.ClausesUpdate,
    tenantPermissions.ClausesDetail,
    tenantPermissions.ClausesDelete,
    tenantPermissions.ClausesPublish,
    tenantPermissions.ClausesUnpublish,
    tenantPermissions.ClauseFoldersList,
    tenantPermissions.ClauseFoldersCreate,
    tenantPermissions.ClauseFoldersUpdate,
    tenantPermissions.ClauseFoldersDetail,
    tenantPermissions.ClauseFoldersDelete,
    tenantPermissions.ClauseFoldersRestore,
  ],
};

export default tenantPermissions;
