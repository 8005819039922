import { defineMessages } from 'react-intl';

export default defineMessages({
  CampaignsListInformation: {
    id: 'Campaigns.List.Information',
    defaultMessage:
      'Before creating a new campaign, it is recommended to configure the contractual pack in the "contract package" tab.',
  },
  CampaignsListLabel: {
    id: 'Campaigns.List.Label',
    defaultMessage: 'Manage your negociation campaigns',
  },
  CampaignsListButtonsAdd: {
    id: 'Campaigns.List.Buttons.Add',
    defaultMessage: 'Add a campaign',
  },
  CampaignsItemTitle: {
    id: 'Campaigns.Item.Title',
    defaultMessage: 'Negociation campaign of {year}',
  },
  CampaignsItemInformation: {
    id: 'Campaigns.Item.Information',
    defaultMessage:
      'By default all documents will be taken from the previous year, select the items you want to renegociate totally or partially from the previous year.',
  },
  CampaignsItemNegociatedLabel: {
    id: 'Campaigns.Item.Negociated.Label',
    defaultMessage: 'Negociated documents',
  },
  CampaignsItemSignedLabel: {
    id: 'Campaigns.Item.Signed.Label',
    defaultMessage: 'Signed documents',
  },
  CampaignsItemSupportingLabel: {
    id: 'Campaigns.Item.Supporting.Label',
    defaultMessage: 'Supporting documents',
  },
  CampaignsItemButtonsLaunch: {
    id: 'Campaigns.Item.Buttons.Launch',
    defaultMessage: 'Launch the {year} campaign',
  },
  CampaignsItemNegociatedTypeAmendment: {
    id: 'Campaigns.Item.Negociated.Type.Amendment',
    defaultMessage: 'Amendment',
  },
  CampaignsItemNegociatedTypeRenegociation: {
    id: 'Campaigns.Item.Negociated.Type.Renegociation',
    defaultMessage: 'Global renegociation',
  },
  CampaignsItemNegociatedTypeLabel: {
    id: 'Campaigns.Item.Negociated.Type.Label',
    defaultMessage: 'Type',
  },
  CampaignsFieldsNameLabel: {
    id: 'Campaigns.Fields.Name.Label',
    defaultMessage: 'Name',
  },
  CampaignsFieldsNameRequired: {
    id: 'Campaigns.Fields.Name.Required',
    defaultMessage: 'The campaign name is required',
  },
  CampaignsFieldsYearLabel: {
    id: 'Campaigns.Fields.Year.Label',
    defaultMessage: 'Year',
  },
  CampaignsFieldsYearRequired: {
    id: 'Campaigns.Fields.Year.Required',
    defaultMessage: 'The campaign year is required',
  },
  CampaignsFieldsYearInvalid: {
    id: 'Campaigns.Fields.Year.Invalid',
    defaultMessage: 'The campaign year must be a number',
  },
  CampaignsDialogsCreateTitle: {
    id: 'Campaigns.Dialogs.Create.Title',
    defaultMessage: 'Create a new campaign',
  },
});
