/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowUpwardSharp from '@mui/icons-material/ArrowUpwardSharp';
import ArrowDownwardSharp from '@mui/icons-material/ArrowDownwardSharp';
import SortIcon from '@mui/icons-material/Sort';
import { sortModes } from '@views/constants';
import { FormattedMessage } from 'react-intl';

export default function TemplatesHeader({ sortMode, setSortMode }) {
  return (
    <>
      <Grid item md={6}>
        <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 4 }}>
          <Typography sx={{ color: '#666666', fontSize: 13 }}>
            <FormattedMessage id="Template.Header.Name.Label" />
          </Typography>
          <IconButton
            onClick={() => {
              setSortMode(
                sortMode === sortModes.nameAtoZ
                  ? sortModes.nameZtoA
                  : sortMode === sortModes.nameZtoA
                  ? sortModes.default
                  : sortModes.nameAtoZ
              );
            }}
          >
            {sortMode === sortModes.nameAtoZ ? (
              <ArrowUpwardSharp fontSize="inherit" />
            ) : sortMode === sortModes.nameZtoA ? (
              <ArrowDownwardSharp fontSize="inherit" />
            ) : (
              <SortIcon fontSize="inherit" />
            )}
          </IconButton>
        </Stack>
      </Grid>
      <Grid item md={3}>
        <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 4 }}>
          <Typography sx={{ color: '#666666', fontSize: 13 }}>
            <FormattedMessage id="Template.Header.Statut.Label" />
          </Typography>
          <IconButton
            onClick={() => {
              setSortMode(
                sortMode === sortModes.statusAsc
                  ? sortModes.statusDesc
                  : sortMode === sortModes.statusDesc
                  ? sortModes.default
                  : sortModes.statusAsc
              );
            }}
          >
            {sortMode === sortModes.statusAsc ? (
              <ArrowUpwardSharp fontSize="inherit" />
            ) : sortMode === sortModes.statusDesc ? (
              <ArrowDownwardSharp fontSize="inherit" />
            ) : (
              <SortIcon fontSize="inherit" />
            )}
          </IconButton>
        </Stack>
      </Grid>
      <Grid item md={3}>
        <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 4 }}>
          <Typography sx={{ color: '#666666', fontSize: 13 }}>
            <FormattedMessage id="Template.Header.LastModif.Label" />
          </Typography>
          <IconButton
            onClick={() => {
              setSortMode(
                sortMode === sortModes.dateModifiedNewestFirst
                  ? sortModes.dateModifiedOldestFirst
                  : sortMode === sortModes.dateModifiedOldestFirst
                  ? sortModes.default
                  : sortModes.dateModifiedNewestFirst
              );
            }}
          >
            {sortMode === sortModes.dateModifiedNewestFirst ? (
              <ArrowUpwardSharp fontSize="inherit" />
            ) : sortMode === sortModes.dateModifiedOldestFirst ? (
              <ArrowDownwardSharp fontSize="inherit" />
            ) : (
              <SortIcon fontSize="inherit" />
            )}
          </IconButton>
        </Stack>
      </Grid>
    </>
  );
}
