import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormikProvider from '@components/Formik/FormikProvider';
import FormikTextField from '@components/Formik/FormikTextField';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import { FormattedMessage } from 'react-intl';
import withPermissions from '@components/withTenantPermissions';

const schema = Yup.object().shape({
  name: Yup.string().required('Generic.Fields.Required'),
  folderId: Yup.string(),
});

function NewBlankTemplateModal({
  folderId,
  templates,
  onSubmit,
  handleSubmit,
  disabled,
}) {
  const { id } = useParams();

  const template = useMemo(
    () => templates?.find(a => a.id === id),
    [templates, id]
  );

  const initialValues = useMemo(
    () => ({
      name: template?.name ?? '',
      folderId: template ? template?.folderId : folderId ?? '',
    }),
    [folderId, template]
  );

  return (
    <FormikProvider
      schema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmit ? handleSubmit(id) : onSubmit}
      validateOnBlur={false}
      enableReinitialize
    >
      <DialogWithClose
        titleLabel={<FormattedMessage id="Template.Create.Blank.Title" />}
        dialogActions={
          <Button type="submit" disabled={disabled}>
            <FormattedMessage id="Template.Create.Button.Label" />
          </Button>
        }
      >
        <Stack gap={2} key={disabled}>
          <FormikTextField
            required
            name="name"
            label={<FormattedMessage id="Template.Fields.Name.Label" />}
            color="secondary"
            margin="dense"
            disabled={disabled}
          />
        </Stack>
      </DialogWithClose>
    </FormikProvider>
  );
}

export const NewBlankTemplateModalWithPermissions = withPermissions(
  NewBlankTemplateModal
);

export default NewBlankTemplateModal;
