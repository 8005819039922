import { defineMessages } from 'react-intl';

export default defineMessages({
  TemplateContentSaveSuccess: {
    id: 'Template.Content.Save.Success',
    defaultMessage: 'Document content has been saved successfully',
  },
  TemplateContentSaveError: {
    id: 'Template.Content.Save.Error',
    defaultMessage: 'An error occured during the saving process',
  },
  TemplateCreateButtonLabel: {
    id: 'Template.Create.Button.Label',
    defaultMessage: 'Create the template',
  },
  TemplateCreateBlankTitle: {
    id: 'Template.Create.Blank.Title',
    defaultMessage: 'Create a blank template',
  },
  TemplateCreateBlankButtonLabel: {
    id: 'Template.Create.Blank.Button.Label',
    defaultMessage: 'Create a blank template',
  },
  TemplateCreateImportWordButtonLabel: {
    id: 'Template.Create.ImportWord.Button.Label',
    defaultMessage: 'Import a word file',
  },
  TemplateCreateImportZipButtonLabel: {
    id: 'Template.Create.ImportZip.Button.Label',
    defaultMessage: 'Import a zip file',
  },
  TemplateFieldsNameLabel: {
    id: 'Template.Fields.Name.Label',
    defaultMessage: 'Name',
  },
  TemplateFolderCreateButtonLabel: {
    id: 'Template.Folder.Create.Button.Label',
    defaultMessage: 'Create the folder',
  },
  TemplateFolderFieldsNameLabel: {
    id: 'Template.Folder.Fields.Name.Label',
    defaultMessage: 'Name',
  },
  TemplatesFolderFieldsNameLabel: {
    id: 'Templates.Folder.Fields.Name.Label',
    defaultMessage: 'Name',
  },
  TemplatesFolderDeleteDescription: {
    id: 'Templates.Folder.Delete.Description',
    defaultMessage:
      'You are about to delete a folder. Are you sure you want to continue?',
  },
  TemplatesViewButtonsAddTemplateLabel: {
    id: 'TemplatesView.Buttons.AddTemplate.Label',
    defaultMessage: 'Template',
  },
  TemplatesViewButtonsAddFolderLabel: {
    id: 'TemplatesView.Buttons.AddFolder.Label',
    defaultMessage: 'Folder',
  },
  TemplatesViewButtonsNewLabel: {
    id: 'TemplatesView.Buttons.New.Label',
    defaultMessage: 'New',
  },
  TemplatesViewPopupTitle: {
    id: 'TemplatesView.Popup.Title',
    defaultMessage: 'New Template',
  },
  TemplatesCreatePopupTitle: {
    id: 'Templates.Create.Popup.Title',
    defaultMessage: 'New folder',
  },
  TemplateHeaderNameLabel: {
    id: 'Template.Header.Name.Label',
    defaultMessage: 'Name',
  },
  TemplateHeaderStatutLabel: {
    id: 'Template.Header.Statut.Label',
    defaultMessage: 'Statut',
  },
  TemplateHeaderLastModifLabel: {
    id: 'Template.Header.LastModif.Label',
    defaultMessage: 'Last modification',
  },
  TemplateComponentsTemplateOptionsButtonsEditLabel: {
    id: 'TemplateComponents.TemplateOptions.Buttons.Edit.Label',
    defaultMessage: 'Edit',
  },
});
