import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import { SigninLayout } from '../SignIn/SigninView';

function ForgotPasswordConfirmation({ signInUrl }) {
  return (
    <SigninLayout hideWelcome>
      <Typography mt={2}>
        <FormattedMessage
          id="ForgetPassword.Reset.Confirmation.Message"
          defaultMessage="Vous allez recevoir un email sous peu afin de réinitialiser
                  votre mot de passe."
        />
      </Typography>
      {Boolean(signInUrl) && (
        <Button component={Link} to={signInUrl} variant="text" sx={{ mt: 2 }}>
          Se connecter
        </Button>
      )}
    </SigninLayout>
  );
}

export default ForgotPasswordConfirmation;
