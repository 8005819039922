import { ComponentProps, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Spinner from '@components/Spinner';

type FetchingProps = ComponentProps<typeof Stack> &
  PropsWithChildren<{
    fetching?: boolean;
  }>;

// eslint-disable-next-line react/function-component-definition
export default function Fetching({
  fetching,
  children,
  ...props
}: FetchingProps) {
  if (!fetching) {
    if (!children) return null;
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <Stack alignItems="center" justifyContent="center" {...props}>
      <Box p={0.5}>
        <Spinner />
      </Box>
    </Stack>
  );
}
