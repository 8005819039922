import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@components/Buttons/IconButtons/IconButton';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

interface FilterWrapperProps {
  showSearchButton: boolean;
  title?: string;
  isRemovable?: boolean;
  onClear?: () => void;
  onDelete?: () => void;
  onValidate?: () => void;
  children: any;
  titleSx?: object;
}

function FilterWrapper({
  showSearchButton = false,
  title = '',
  isRemovable = false,
  onClear,
  onDelete,
  onValidate,
  children,
  titleSx,
}: FilterWrapperProps) {
  return (
    <Stack spacing={2}>
      <Grid
        container
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
        direction="row"
      >
        <Grid item xs={isRemovable ? 10 : 12}>
          <Typography sx={titleSx}>{title}</Typography>
        </Grid>
        {isRemovable && (
          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <IconButton
              aria-label="delete"
              color="warning"
              size="small"
              onClick={onDelete}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Box>{children}</Box>
      {Boolean(showSearchButton) && (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          {onClear && (
            <Button variant="text" onClick={onClear}>
              Effacer
            </Button>
          )}
          {onValidate && (
            <Button variant="contained" onClick={onValidate}>
              Valider
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default FilterWrapper;
