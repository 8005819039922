import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import * as Yup from 'yup';

import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import FormikProvider from '@components/Formik/FormikProvider';
import FormikTextField from '@components/Formik/FormikTextField';
import { Box } from '@mui/material';
import { hostIsAuthenticatedSelector } from '@recoil/host-token';
import { signin } from '@utils/authentication';

export const defaultSchema = Yup.object().shape({
  email: Yup.string()
    .ensure()
    .trim()
    .email('Signin.Fields.Email.Invalid')
    .required('Signin.Fields.Email.Required'),
  password: Yup.string()
    .ensure()
    .trim()
    .required('Signin.Fields.Password.Required'),
});

export const defaultValues = {
  email: '',
  password: '',
};

const loggedUserNamesLocalStorageKey = 'logged-user-emails';

function tryParseJson(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
}

function addUser(userName) {
  // Parse any JSON previously stored in allEntries
  let existingUsers = tryParseJson(
    localStorage.getItem(loggedUserNamesLocalStorageKey)
  );
  if (!Array.isArray(existingUsers)) existingUsers = [];
  if (existingUsers.includes(userName)) return;
  // Save existingUsers back to local storage
  existingUsers.push(userName);
  localStorage.setItem(
    loggedUserNamesLocalStorageKey,
    JSON.stringify(existingUsers)
  );
}

function SigninForm({ firstConnectionUrl }) {
  const [searchParams] = useSearchParams();
  const redirectToParams = searchParams.get('redirectTo') || '';
  const navigate = useNavigate();
  const location = useLocation();
  const returnUrl =
    // eslint-disable-next-line no-nested-ternary
    redirectToParams !== ''
      ? redirectToParams
      : location.state?.from
      ? `${location.state.from.pathname}${location.state.from.search}${location.state.from.hash}`
      : '/dashboard';
  const [error, setError] = React.useState('');
  const setHostAuthenticationState = useSetRecoilState(
    hostIsAuthenticatedSelector
  );
  const onSubmitCallback = async form => {
    const result = await signin(form);
    if (!result.succeeded) {
      setError(result.error);
      setHostAuthenticationState(null);
    } else {
      const loggedUserNamesAsAny = tryParseJson(
        localStorage.getItem(loggedUserNamesLocalStorageKey)
      );
      const loggedUserNames = Array.isArray(loggedUserNamesAsAny)
        ? loggedUserNamesAsAny
        : [];

      addUser(form.email);
      setHostAuthenticationState(result);
      let localFirstConnectionUrl = firstConnectionUrl?.pathname;
      if (redirectToParams !== '' && localFirstConnectionUrl) {
        localFirstConnectionUrl += `?redirectTo=${redirectToParams}`;
      }

      navigate(
        localFirstConnectionUrl && !loggedUserNames?.some(i => i === form.email)
          ? localFirstConnectionUrl
          : `/signin/authorize${
              redirectToParams !== '' ? `?redirectTo=${redirectToParams}` : ''
            }`,
        { state: location.state }
      );
    }
  };
  return (
    <Box>
      <FormikProvider
        schema={defaultSchema}
        initialValues={defaultValues}
        onSubmit={onSubmitCallback}
      >
        {error !== '' && <ErrorMessage error={error} sx={{ mt: 1, mb: 2 }} />}
        <FormikTextField
          as="fastfield"
          autoFocus
          name="email"
          required
          autoComplete="email"
          label={<FormattedMessage id="Signin.Fields.Email.Label" />}
        />
        <FormikTextField
          as="fastfield"
          type="password"
          name="password"
          required
          autoComplete="current-password"
          label={<FormattedMessage id="Signin.Fields.Password.Label" />}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          <FormattedMessage id="Signin.Button" />
        </Button>
        <Button
          component={Link}
          to="/authentication/forgot-password"
          fullWidth
          variant="text"
          color="secondary"
          size="small"
          sx={{ mt: 2 }}
        >
          Mot de passe oublié ?
        </Button>
      </FormikProvider>
      <form
        action={`${process.env.REACT_APP_API_URI}/nexityavenanttravaux/login`}
      >
        <input type="hidden" name="returnUrl" value={returnUrl} />
        <Button
          type="submit"
          fullWidth
          variant="text"
          color="secondary"
          size="small"
          sx={{ mt: 2 }}
        >
          Connexion Nexity
        </Button>
      </form>
    </Box>
  );
}

export default SigninForm;
