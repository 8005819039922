import ResetPasswordForm from './ResetPasswordForm';
import { SigninLayout } from '../SignIn/SigninView';

function ResetPassword({ signinUrl }) {
  return (
    <SigninLayout hideWelcome>
      <ResetPasswordForm signinUrl={signinUrl} />
    </SigninLayout>
  );
}

export default ResetPassword;
