import { defineMessages } from 'react-intl';

export default defineMessages({
  DocumentFieldsStatus0: {
    id: 'Document.Fields.Status.0',
    defaultMessage: 'Missing',
  },
  DocumentFieldsStatus1: {
    id: 'Document.Fields.Status.1',
    defaultMessage: 'To be validated',
  },
  DocumentFieldsStatus2: {
    id: 'Document.Fields.Status.2',
    defaultMessage: 'To send for signature',
  },
  DocumentFieldsStatus3: {
    id: 'Document.Fields.Status.3',
    defaultMessage: 'To be signed',
  },
  DocumentFieldsStatus4: {
    id: 'Document.Fields.Status.4',
    defaultMessage: 'Signed by PM',
  },
  DocumentFieldsStatus5: {
    id: 'Document.Fields.Status.5',
    defaultMessage: 'Signed by supplier',
  },
  DocumentFieldsStatus6: {
    id: 'Document.Fields.Status.6',
    defaultMessage: 'Signed',
  },
  DocumentFieldsStatus7: {
    id: 'Document.Fields.Status.7',
    defaultMessage: 'Canceled',
  },
  DocumentFieldsStatusUnknwon: {
    id: 'Document.Fields.Status.Unknown',
    defaultMessage: 'Unknown',
  },
  DocumentTableHeaderProductManager: {
    id: 'Document.Table.Header.ProductManager',
    defaultMessage: 'Product manager',
  },
  DocumentTableHeaderDepartment: {
    id: 'Document.Table.Header.Department',
    defaultMessage: 'Department',
  },
  DocumentTableHeaderId: {
    id: 'Document.Table.Header.Id',
    defaultMessage: 'ID Legal Supplier',
  },
  DocumentTableHeaderCreatedOn: {
    id: 'Document.Table.Header.CreatedOn',
    defaultMessage: 'Created on',
  },
  DocumentTableHeaderEditedOn: {
    id: 'Document.Table.Header.EditedOn',
    defaultMessage: 'Edited on',
  },
  DocumentButtonsShowAll: {
    id: 'Document.Buttons.Show.All',
    defaultMessage: 'Show all documents',
  },
  DocumentButtonsShowLess: {
    id: 'Document.Buttons.Show.Less',
    defaultMessage: 'Show less documents',
  },
  DocumentCardCreatedOn: {
    id: 'Document.Card.CreatedOn',
    defaultMessage: 'Creation date',
  },
  DocumentCardEditedOn: {
    id: 'Document.Card.EditedOn',
    defaultMessage: 'Last modification',
  },
  DocumentCardNegotiationType: {
    id: 'Document.Card.NegotiationType',
    defaultMessage: 'Negotiation Type',
  },
  DocumentCardAmendment: {
    id: 'Document.Card.Amendment',
    defaultMessage: 'Amendment',
  },
  DocumentCardPreviousVersions: {
    id: 'Document.Card.PreviousVersions',
    defaultMessage: 'Previous versions',
  },
});
