import useFetch from '@hooks/useFetch';
import { roleNames } from '@shells/nexity-avenant-travaux/nexity-avenant-travaux-constants';
import TemplateWorkflowView from '@views/Administration/Templates/TemplateWorkflowView';
import TemplatesView from '@views/Administration/Templates/TemplatesView';

export function useWorkflowExcludeRoleIds() {
  const fetch = useFetch({
    url: `/identity/roles`,
  });

  const excludeRoleIds =
    fetch.data?.filter(r => r.name === roleNames.lecteur).map(r => r.id) ?? [];

  return { excludeRoleIds, fetching: fetch.fetching, error: fetch.error };
}

function NexityAvenantTravauxTemplateWorkflowView(props) {
  const { excludeRoleIds } = useWorkflowExcludeRoleIds();
  return <TemplateWorkflowView {...props} excludeRoleIds={excludeRoleIds} />;
}

function NexityAvenantTravauxTemplatesView(props) {
  return (
    <TemplatesView
      {...props}
      TemplateWorkflowView={NexityAvenantTravauxTemplateWorkflowView}
    />
  );
}

export default NexityAvenantTravauxTemplatesView;
