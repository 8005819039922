export enum SearchFilterType {
  'Text',
  'Number',
  'Select',
  'Timestamp',
  'AutoComplete',
  'Checkbox',
}

export type SearchFilter = {
  id: string;
  title: string;
  showSearchButton: boolean;
  type: SearchFilterType;
  multiple?: boolean;
  periode?: boolean;
  data?: any;
  urlData?: string;
  dataMappingFromUrl?: (data: any) => any;
  termSheetValueType?: string;
  getValueFromOption?: (dataItem: any, dataItemIndex: number) => any;
};

export type PopoverFiltersProps = {
  filter: SearchFilter;
  isTrash: boolean;
  advancedSearchRequest?: any;
  setAdvancedSearchRequest?: any;
  onRemoveFilter?: (id: string) => void;
  onValidate?: () => void;
  titleSx?: object;
};
