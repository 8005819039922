import { defineMessages } from 'react-intl';

export default defineMessages({
  add: {
    id: 'Verbs.Add',
    defaultMessage: 'Add',
  },
  save: {
    id: 'Verbs.Save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'Verbs.Cancel',
    defaultMessage: 'Cancel',
  },
  choose: {
    id: 'Verbs.Choose',
    defaultMessage: 'Choose',
  },
  create: {
    id: 'Verbs.Create',
    defaultMessage: 'Create',
  },
  delete: {
    id: 'Verbs.Delete',
    defaultMessage: 'Delete',
  },
  search: {
    id: 'Verbs.Search',
    defaultMessage: 'Search',
  },
  update: {
    id: 'Verbs.Update',
    defaultMessage: 'Update',
  },
  close: {
    id: 'Verbs.Close',
    defaultMessage: 'Close',
  },
  browse: {
    id: 'Verbs.Browse',
    defaultMessage: 'Browse',
  },
  done: {
    id: 'Verbs.Done',
    defaultMessage: 'Done',
  },
  validate: {
    id: 'Verbs.Validate',
    defaultMessage: 'Validate',
  },
  consult: {
    id: 'Verbs.Consult',
    defaultMessage: 'Consult',
  },
  complete: {
    id: 'Verbs.Complete',
    defaultMessage: 'Complete',
  },
  rectify: {
    id: 'Verbs.Rectify',
    defaultMessage: 'Rectify',
  },
  link: {
    id: 'Verbs.Link',
    defaultMessage: 'Link',
  },
  previous: {
    id: 'Verbs.Previous',
    defaultMessage: 'Previous',
  },
  next: {
    id: 'Verbs.Next',
    defaultMessage: 'Next',
  },
  invalidate: {
    id: 'Verbs.Invalidate',
    defaultMessage: 'Invalidate',
  },
  sign: {
    id: 'Verbs.Sign',
    defaultMessage: 'Sign',
  },
  renegociate: {
    id: 'Verbs.Renegociate',
    defaultMessage: 'Renegociate',
  },
  publish: {
    id: 'Verbs.Publish',
    defaultMessage: 'Publish',
  },
  unpublish: {
    id: 'Verbs.Unpublish',
    defaultMessage: 'Unpublish',
  },
  accept: {
    id: 'Verbs.Accept',
    defaultMessage: 'Accept',
  },
  reject: {
    id: 'Verbs.Reject',
    defaultMessage: 'Reject',
  },
  test: {
    id: 'Verbs.Test',
    defaultMessage: 'Test',
  },
  download: {
    id: 'Verbs.Download',
    defaultMessage: 'Download',
  },
  apply: {
    id: 'Verbs.Apply',
    defaultMessage: 'Apply',
  },
  rename: {
    id: 'Verbs.Rename',
    defaultMessage: 'Rename',
  },
  restore: {
    id: 'Verbs.Restore',
    defaultMessage: 'Restore',
  },
  show: {
    id: 'Verbs.Show',
    defaultMessage: 'Show',
  },
  import: {
    id: 'Verbs.Import',
    defaultMessage: 'Import',
  },
  edit: {
    id: 'Verbs.Edit',
    defaultMessage: 'Edit',
  },
  modify: {
    id: 'Verbs.Modify',
    defaultMessage: 'Modify',
  },
  move: {
    id: 'Verbs.Move',
    defaultMessage: 'Move',
  },
  duplicate: {
    id: 'Verbs.Duplicate',
    defaultMessage: 'Duplicate',
  },
  confirm: {
    id: 'Verbs.Confirm',
    defaultMessage: 'Confirm',
  },
});
