import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ConfirmDialog from '@components/Dialog/ConfirmDialog';

import { bold } from '@utils/chunks';
import { label } from '@utils/contributor-helpers';

function WorkflowContributorDeleteDialog({
  open,
  onClose,
  onConfirm,
  contributor,
}) {
  const { formatMessage } = useIntl();
  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      titleComponent={
        <FormattedMessage id="Workflow.Contributor.Dialogs.Delete.Title" />
      }
      actionComponent={
        <Button variant="contained" color="primary" onClick={onConfirm}>
          <FormattedMessage id="Verbs.Delete" />
        </Button>
      }
    >
      <Typography>
        <FormattedMessage
          id="Workflow.Contributor.Dialogs.Delete.Message"
          values={{
            name: label(contributor, formatMessage),
            b: bold,
          }}
        />
      </Typography>
    </ConfirmDialog>
  );
}

export default WorkflowContributorDeleteDialog;
