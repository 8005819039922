import React from 'react';
import * as Yup from 'yup';

import FormikProvider from '@components/Formik/FormikProvider';
import { useWorkflowContext } from './WorkflowContextProvider';

export const defaultSchema = Yup.object().shape({
  id: Yup.string().required('Workflow.Step.Fields.Identifier.Required'),
  name: Yup.string()
    .ensure()
    .trim()
    .required('Workflow.Step.Fields.Name.Required'),
  signature: Yup.boolean().required('Workflow.Step.Fields.Signature.Required'),
  mandatoryContributors: Yup.array().of(
    Yup.object().shape({
      contributor: Yup.object()
        .nullable()
        .required('Workflow.Contributor.Fields.Entity.Required'),
      access: Yup.number().required(
        'Workflow.Contributor.Fields.Access.Required'
      ),
    })
  ),
  otherContributors: Yup.array().of(
    Yup.object().shape({
      contributor: Yup.object()
        .nullable()
        .required('Workflow.Contributor.Fields.Entity.Required'),
      access: Yup.number().required(
        'Workflow.Contributor.Fields.Access.Required'
      ),
    })
  ),
  notifyContributors: Yup.array().of(
    Yup.object().shape({
      contributor: Yup.object()
        .nullable()
        .required('Workflow.Contributor.Fields.Entity.Required'),
    })
  ),
  hasCompletionMessage: Yup.boolean().nullable(),
  completionMessage: Yup.string().nullable(),
});

export const defaultValues = {
  id: null,
  name: '',
  signature: false,
  mandatoryContributors: [{ contributor: null, access: '', assignments: [] }],
  otherContributors: [{ contributor: null, access: '', assignments: [] }],
};

function WorkflowFormikProvider({ children, ...rest }) {
  const {
    methods: { update },
  } = useWorkflowContext();
  return (
    <FormikProvider
      schema={defaultSchema}
      initialValues={defaultValues}
      onSubmit={update}
      {...rest}
    >
      {children}
    </FormikProvider>
  );
}

export default WorkflowFormikProvider;
