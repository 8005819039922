import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconGrabber from '@views/MangeData/components/IconGrabber';
import withPermissions from '@components/withTenantPermissions';
import { TermSheetSchemaEditorValue } from './template-term-sheet-schema-values';

function EditTermSheetSchemaValueCard({
  value,
  selected,
}: {
  value: TermSheetSchemaEditorValue;
  selected?: boolean;
}) {
  return (
    <Card
      variant="elevation"
      sx={{
        borderRadius: 2,
        border: '1px solid',
        borderColor: selected ? 'primary.main' : 'transparent',
        color: selected ? 'text.primary' : 'text.primary',
      }}
    >
      <CardContent sx={{ py: 1, px: 2, '&:last-child': { pb: 1 } }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <IconGrabber icon={value.icon} iconSize="small" />
            <Typography variant="subtitle2">
              {value.name || <em>vide</em>}
            </Typography>
          </Stack>
          <Box
            sx={{
              width: 15,
              height: 15,
              p: 0.2,
              ml: 2,
              bgcolor: selected ? 'primary.main' : 'transparent',
              borderRadius: '50%',
              border: '1px solid',
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

export const EditTermSheetSchemaValueCardWithPermissions = withPermissions(
  EditTermSheetSchemaValueCard
);

export default EditTermSheetSchemaValueCard;
