import React from 'react';
import { v4 as uuid } from 'uuid';
import { arrayMove } from 'react-movable';
import isEqual from 'react-fast-compare';

export const WorkflowContext = React.createContext({
  steps: [],
  movable: [],
  first: null,
  signature: null,
  active: null,
  isfirst: false,
  islast: false,
  dirty: false,
  valid: true,
  methods: {
    set: () => {},
    add: () => {},
    update: () => {},
    remove: () => {},
    move: () => {},
  },
});

export const useWorkflowContext = () => React.useContext(WorkflowContext);

export const contributorUsers = stepContributor =>
  // eslint-disable-next-line no-nested-ternary
  stepContributor?.type === 1
    ? [stepContributor.contributor]
    : stepContributor?.type === 2
    ? stepContributor.assignments ?? []
    : [];

export const stepActiveContributors = step => [
  ...(step?.mandatoryContributors ?? []),
  ...(step?.otherContributors ?? []),
];

const mapStep = step => ({
  ...step,
  signature:
    step.mandatoryContributors.some(c => c.access === 1) ||
    step.otherContributors.some(c => c.access === 1),
});

const mapSteps = steps => {
  const newSteps = steps?.map(mapStep) ?? [];
  if (!newSteps.length) {
    newSteps.unshift(
      mapStep({
        name: 'Creation',
        mandatoryContributors: [],
        otherContributors: [],
      })
    );
  }
  return newSteps;
};

const mapAssignmentForApi = assignment => ({
  userId: assignment?.entity?.id,
  id: assignment?.id,
});

const mapContributorForApi = contributor => ({
  id: contributor.id,
  type: contributor.contributor.type,
  entityId: contributor.contributor.entity.id,
  access: contributor.access,
  condition: contributor.condition,
  assignments: contributor.assignments?.map(assignment => {
    const a = mapAssignmentForApi(assignment);
    return a;
  }),
});

const mapStepForApi = step => ({
  id: step.id,
  name: step.name,
  required: true,
  mandatoryContributors:
    step.mandatoryContributors?.map(mapContributorForApi) ?? [],
  otherContributors: step.otherContributors?.map(mapContributorForApi) ?? [],
  notifyContributors: step.notifyContributors?.map(mapContributorForApi) ?? [],
  completionMessage: step.hasCompletionMessage ? step.completionMessage : null,
  endTime: step.endTime,
});

const mapAssignmentFromApi = assignment => ({
  id: assignment?.id,
  type: 1,
  typeLabel: 'Users',
  entity: {
    id: assignment?.userId,
    firstName: assignment?.user?.firstName,
    lastName: assignment?.user?.lastName,
    email: assignment?.user?.email,
  },
});

const mapContributorFromApi = contributor => ({
  id: contributor.id,
  contributor: {
    type: contributor.type,
    entity: {
      ...(contributor?.entity ?? {}),
      id: contributor.entityId,
    },
  },
  access: contributor.access,
  condition: contributor.condition,
  type: contributor.type,
  // TODO: fix on the api level if possible
  assignments: contributor.assignments?.map(mapAssignmentFromApi) ?? [],
});

const mapStepFromApi = step => ({
  id: step.id ?? uuid(),
  name: step.name,
  mandatoryContributors: step.mandatoryContributors?.map(mapContributorFromApi),
  otherContributors: step.otherContributors?.map(mapContributorFromApi),
  notifyContributors: step.notifyContributors?.map(mapContributorFromApi),
  hasCompletionMessage: Boolean(step.completionMessage),
  completionMessage: step.completionMessage,
  endTime: step.endTime,
});

function WorkflowContextProvider({
  workflow: workflowProp,
  steps: stepsProp,
  defaultStep = {},
  children,
  schema,
  excludeRoleIds,
}) {
  const steps = React.useMemo(() => {
    const unmappedSteps = workflowProp?.steps ?? stepsProp ?? [];
    if (!unmappedSteps) return [];

    const result = unmappedSteps.map(mapStepFromApi);
    if (!schema && !workflowProp?.isFirstStepCreation) {
      result.unshift({
        id: uuid(),
        name: 'Creation',
        mandatoryContributors: [],
        otherContributors: [],
        endTime: new Date().toISOString(),
      });
    }

    return result;
  }, [
    schema,
    stepsProp,
    workflowProp?.isFirstStepCreation,
    workflowProp?.steps,
  ]);

  const [stepsState, setStepsState] = React.useState([]);
  const [originalState, setOriginalState] = React.useState(mapSteps(steps));
  const [activeState, setActiveState] = React.useState(null);
  const activeStepIndex = stepsState?.findIndex(s => s.id === activeState?.id);
  const nextStepState =
    activeStepIndex >= 0 && activeStepIndex < stepsState?.length
      ? stepsState?.[activeStepIndex + 1]
      : null;

  React.useEffect(() => {
    setStepsState(mapSteps(steps));
  }, [steps, setStepsState]);

  React.useEffect(() => {
    if (activeState === null && stepsState.length > 0) {
      setActiveState(stepsState[0]);
    }
  }, [stepsState, activeState, setActiveState]);

  const state = React.useMemo(() => {
    const first = stepsState[0] ?? null;
    const isfirst = first?.id === activeState?.id;
    const signature = stepsState.find(s => s.signature) ?? null;
    return {
      movable: stepsState.slice(1, signature ? -1 : undefined),
      isfirst,
      readOnly: Boolean(
        (schema || workflowProp?.isFirstStepCreation
          ? first?.id === activeState?.id
          : false) || activeState?.endTime
      ),
      islast: first && stepsState[stepsState.length - 1].id === activeState?.id,
      first,
      signature,
      dirty: !isEqual(originalState, stepsState),
      valid: stepsState.every(
        (s, i) =>
          i === 0 ||
          (Boolean(s.name?.trim()) &&
            (s.mandatoryContributors.length > 0 ||
              s.otherContributors.length > 0))
      ),
    };
  }, [
    stepsState,
    activeState?.id,
    activeState?.endTime,
    schema,
    workflowProp?.isFirstStepCreation,
    originalState,
  ]);

  const setCallback = React.useCallback(
    step => {
      setActiveState(step);
    },
    [setActiveState]
  );

  const addCallback = React.useCallback(() => {
    const step = { ...defaultStep, id: uuid(), signature: false };
    setStepsState(
      [state.first, ...state.movable, step, state.signature].filter(i => !!i)
    );
    setActiveState(step);
  }, [
    setActiveState,
    setStepsState,
    defaultStep,
    state.first,
    state.movable,
    state.signature,
  ]);

  const updateCallback = React.useCallback(
    step => {
      const index = stepsState.findIndex(s => s.id === step.id);
      if (index !== -1) {
        const clone = [...stepsState];
        clone[index] = mapStep(step);
        setStepsState(clone);
      }
    },
    [stepsState, setStepsState]
  );

  const removeCallback = React.useCallback(
    step => {
      const index = stepsState.findIndex(s => s.id === step.id);
      if (index !== -1) {
        if (activeState?.id === step.id) {
          setActiveState(stepsState[index - 1]);
        }
        setStepsState(stepsState.filter(s => s.id !== step.id));
      }
    },
    [stepsState, activeState, setStepsState, setActiveState]
  );

  const moveCallback = React.useCallback(
    (oldIndex, newIndex) => {
      setStepsState([...arrayMove(stepsState, oldIndex, newIndex)]);
    },
    [stepsState, setStepsState]
  );

  const undirtifyCallback = React.useCallback(() => {
    setOriginalState(stepsState);
  }, [stepsState]);

  const mapForApiCallback = React.useCallback(
    () => ({ steps: stepsState.map(mapStepForApi), isFirstStepCreation: true }),
    [stepsState]
  );

  const context = React.useMemo(
    () => ({
      ...state,
      excludeRoleIds,
      steps: stepsState,
      active: activeState,
      nextStep: nextStepState,
      methods: {
        set: setCallback,
        add: addCallback,
        update: updateCallback,
        remove: removeCallback,
        move: moveCallback,
        undirtify: undirtifyCallback,
        mapForApi: mapForApiCallback,
      },
    }),
    [
      state,
      excludeRoleIds,
      stepsState,
      activeState,
      nextStepState,
      setCallback,
      addCallback,
      updateCallback,
      removeCallback,
      moveCallback,
      undirtifyCallback,
      mapForApiCallback,
    ]
  );
  return (
    <WorkflowContext.Provider value={context}>
      {children}
    </WorkflowContext.Provider>
  );
}

export default WorkflowContextProvider;
