import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import useFetch from '@hooks/useFetch';
import FilterWrapper from './FilterWrapper';
import { PopoverFiltersProps } from './helpers/types';

function CheckboxPopoverFilters({
  filter,
  isTrash = false,
  onValidate = undefined,
  onRemoveFilter = undefined,
  advancedSearchRequest = undefined,
  setAdvancedSearchRequest = undefined,
  titleSx,
}: PopoverFiltersProps) {
  const {
    id,
    title,
    data = [],
    multiple = false,
    showSearchButton,
    termSheetValueType = undefined,
    urlData = undefined,
    dataMappingFromUrl = undefined,
  } = filter;
  const [localData, setLocalData] = useState<any[]>(data);
  const checkBoxValues = useFetch({
    url:
      urlData && urlData.indexOf('?') > 0
        ? `${urlData}&trash=${isTrash}`
        : `${urlData}?trash=${isTrash}`,
    lazy: urlData === undefined && dataMappingFromUrl === undefined,
    method: 'GET',
    onSuccess: (response: any) => {
      if (dataMappingFromUrl !== undefined) {
        const values = dataMappingFromUrl(response?.data);
        setLocalData(values);
      }
    },
  });
  const [searchRequest, setSearchRequest] = useState(
    advancedSearchRequest ?? {}
  );

  const getCurrentValue = () => {
    if (termSheetValueType) {
      const index = searchRequest?.termSheetQuery?.findIndex(
        (x: any) => x?.elementId === id
      );
      if (index >= 0) {
        return searchRequest?.termSheetQuery[index].items;
      }
      return multiple ? [] : '';
    }
    return searchRequest?.[id] ?? (multiple ? [] : '');
  };
  const setCurrentValue = (value: any, forceUpdate = false) => {
    const tmpValue = cloneDeep(searchRequest);
    if (termSheetValueType) {
      const index = tmpValue?.termSheetQuery?.findIndex(
        (x: any) => x?.elementId === id
      );
      if (index >= 0) {
        tmpValue.termSheetQuery.splice(index, 1);
      }
      if ((multiple && value.length > 0) || (!multiple && value !== '-1')) {
        if (tmpValue.termSheetQuery === undefined) {
          tmpValue.termSheetQuery = [];
        }
        tmpValue.termSheetQuery.push({
          elementId: id,
          items: value,
        });
      }
      if (forceUpdate === true && setAdvancedSearchRequest) {
        setAdvancedSearchRequest(tmpValue);
      } else {
        setSearchRequest(tmpValue);
      }
    } else if (
      (multiple && value.length === 0) ||
      (!multiple && value === '-1')
    ) {
      // eslint-disable-next-line no-param-reassign
      delete searchRequest[id];
      if (forceUpdate === true && setAdvancedSearchRequest) {
        setAdvancedSearchRequest({ ...searchRequest });
      } else {
        setSearchRequest({ ...searchRequest });
      }
    } else if (forceUpdate === true && setAdvancedSearchRequest) {
      setAdvancedSearchRequest({
        ...searchRequest,
        [id]: multiple ? value : [value],
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [id]: multiple ? value : [value],
      });
    }
  };
  const [checked, setChecked] = useState(getCurrentValue());
  const handleCheckboxChange = (event: any) => {
    let tmpChecked = cloneDeep(checked);
    tmpChecked = tmpChecked.includes(event.target.value)
      ? tmpChecked.filter((f: any) => f !== event.target.value)
      : [...tmpChecked, event.target.value];
    setChecked(tmpChecked);
    setCurrentValue(tmpChecked);
  };
  const onClear = (forceUpdate = false) => {
    if (multiple) {
      setCurrentValue([], forceUpdate);
      setChecked([]);
    } else {
      setCurrentValue('-1', forceUpdate);
      setChecked('-1');
    }
  };
  const onDelete = () => {
    onClear(true);
    if (onRemoveFilter) {
      onRemoveFilter(id);
    }
  };

  const onLocalValidate = () => {
    if (setAdvancedSearchRequest) {
      setAdvancedSearchRequest(searchRequest);
    }
    if (onValidate) {
      onValidate();
    }
  };

  const onchange = (event: any) => {
    if (
      setAdvancedSearchRequest === undefined ||
      advancedSearchRequest === undefined
    ) {
      return;
    }
    setCurrentValue(event.target.value);
  };
  return (
    <FilterWrapper
      showSearchButton={showSearchButton}
      onValidate={onLocalValidate}
      onClear={() => onClear()}
      onDelete={onDelete}
      title={title}
      isRemovable={termSheetValueType !== undefined}
      titleSx={titleSx}
    >
      <FormControl fullWidth>
        {multiple && (
          <FormGroup>
            {localData?.map((item, index) => (
              <FormControlLabel
                key={typeof item === 'string' ? item : item.value}
                label={typeof item === 'string' ? item : item.label}
                sx={{ width: '320px' }}
                control={
                  <Checkbox
                    color="primary"
                    size="small"
                    checked={
                      checked.indexOf(
                        // eslint-disable-next-line no-nested-ternary
                        termSheetValueType
                          ? typeof item === 'string'
                            ? item
                            : item.value
                          : typeof item === 'string'
                          ? (index + 1).toString()
                          : item.value
                      ) >= 0
                    }
                    onClick={handleCheckboxChange}
                    value={
                      // eslint-disable-next-line no-nested-ternary
                      termSheetValueType
                        ? typeof item === 'string'
                          ? item
                          : item.value
                        : typeof item === 'string'
                        ? index + 1
                        : item.value
                    }
                  />
                }
              />
            ))}
          </FormGroup>
        )}
        {!multiple && (
          <RadioGroup value={getCurrentValue()} onChange={onchange}>
            {localData?.map((item, index) => (
              <FormControlLabel
                key={typeof item === 'string' ? item : item.value}
                sx={{ width: '320px' }}
                value={
                  // eslint-disable-next-line no-nested-ternary
                  termSheetValueType
                    ? typeof item === 'string'
                      ? item
                      : item.value
                    : typeof item === 'string'
                    ? index + 1
                    : item.value
                }
                control={<Radio />}
                label={typeof item === 'string' ? item : item.label}
              />
            ))}
          </RadioGroup>
        )}
      </FormControl>
      <ErrorMessage
        error={checkBoxValues.error}
        sx={{ mt: 2, width: '320px' }}
      />
    </FilterWrapper>
  );
}

export default CheckboxPopoverFilters;
