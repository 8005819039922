import theme from '@styles/nexity-avenant-travaux';

import GenericApp from '@components/App/GenericApp';
import NexityAvenantTravauxRoutes from './nexity-avenant-travaux-routes';

function NexityAvenantTravauxApp() {
  return (
    <GenericApp theme={theme}>
      <NexityAvenantTravauxRoutes />
    </GenericApp>
  );
}

export default NexityAvenantTravauxApp;
