import { useState, ReactNode, useMemo } from 'react';
import { Field, FastField, FieldProps } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useDebounced from '@hooks/useDebounced';
import useFetch from '@hooks/useFetch';
import {
  ContentNodeResponse,
  TemplateVariablesListResponse,
} from '@utils/api/types';

type MuiTemplateVariableSelectProps = {
  templateId: string;
  name: string;
  value: string | null;
  setValue: (value: string | null) => void;
  label?: ReactNode;
  required?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  size?: 'small' | 'medium';
};

export function TemplateVariableSelect({
  value,
  setValue,
  label,
  required,
  margin,
  templateId,
  size,
}: MuiTemplateVariableSelectProps) {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounced(query, 500);

  const searchFetch = useFetch<TemplateVariablesListResponse>({
    method: 'GET',
    url: `templates/${templateId}/variables`,
    lazy: !templateId,
  });

  const templateVariables = searchFetch.data?.variables;
  const filteredVariables = useMemo(
    () =>
      templateVariables?.filter(
        v =>
          !debouncedQuery ||
          (v?.attrs?.name &&
            v.attrs.name
              .toLocaleLowerCase()
              .includes(debouncedQuery.toLocaleLowerCase()))
      ),
    [templateVariables, debouncedQuery]
  );

  const groups = useMemo(() => {
    const result = new Map();
    templateVariables?.forEach(v => {
      const clause = v.path?.find(n => n.type === 'clause')?.attrs?.name;
      const clauseVariant = v.path?.find(n => n.type === 'clausevariant')?.attrs
        ?.name;
      const group =
        clause && clauseVariant
          ? `${clause} (${clauseVariant})`
          : clause || null;
      result.set(v.attrs.uid, group);
    });
    return result;
  }, [templateVariables]);

  return (
    <Autocomplete<ContentNodeResponse>
      fullWidth
      loading={searchFetch.fetching}
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.attrs.name
      }
      filterOptions={x => x}
      options={filteredVariables ?? []}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={templateVariables?.find(v => v.attrs.uid === value) ?? null}
      noOptionsText="Aucun élément disponible"
      loadingText="Chargement..."
      onChange={(event, newValue) => setValue(newValue?.attrs.uid ?? null)}
      onInputChange={(event, newInputValue) => setQuery(newInputValue)}
      renderInput={params => (
        <TextField
          label={label}
          required={required}
          margin={margin}
          {...params}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} title={option.attrs.uid}>
          <Typography variant="body1">{option.attrs.name}</Typography>
        </li>
      )}
      groupBy={option => groups.get(option.attrs.uid) || ''}
      size={size}
    />
  );
}

function FormikTemplateVariableSelect({
  as = 'field',
  templateId = '',
  ...props
}) {
  if (as === 'field') {
    return (
      <Field {...props}>
        {(p: FieldProps<string | null>) => (
          <TemplateVariableSelect
            templateId={templateId}
            name={p.field.name}
            value={p.field.value}
            setValue={newValue => p.form.setFieldValue(p.field.name, newValue)}
            {...props}
          />
        )}
      </Field>
    );
  }
  return (
    <FastField {...props}>
      {(p: FieldProps<string | null>) => (
        <TemplateVariableSelect
          templateId={templateId}
          name={p.field.name}
          value={p.field.value}
          setValue={newValue => p.form.setFieldValue(p.field.name, newValue)}
          {...props}
        />
      )}
    </FastField>
  );
}

export default FormikTemplateVariableSelect;
