import BreadcrumbsContainer from '@components/Breadcrumbs/BreadcrumbsContainer';
import DialogOutletRoutes from '@components/Dialog/DialogOutletRoutes';
import useFetch from '@hooks/useFetch';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';
import { Route, useNavigate, useParams } from 'react-router-dom';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';
import { Breadcrumb } from '@components/Breadcrumbs/Breadcrumbs';
import { TemplateDetailResponse } from '@utils/api/types';
import Fetching from '@components/Fetching/Fetching';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import FormikProvider from '@components/Formik/FormikProvider';
import { useMemo, useState } from 'react';
import useCustomizedSnackbar from '@hooks/useCustomizedSnackbar';
import tenantPermissions from '@utils/tenant-permissions';
import withPermissions from '@components/withTenantPermissions';
import { DeleteTermSheetSchemaValueDialogWithPermissions } from './DeleteTermSheetSchemaValueDialog';
import { ManageTermSheetSchemaValueWithPermissions } from './ManageTermSheetSchemaValue';
import { TermSheetSidePanelWithPermissions } from './TermSheetSidePanel';
import {
  TermSheetSchemaEditorValues,
  termSheetSchemaValidationSchema,
} from './template-term-sheet-schema-values';

type EditTemplateTermSheetViewProps = {
  breadcrumbs: Breadcrumb[];
};

export default function EditTemplateTermSheetView({
  breadcrumbs,
}: EditTemplateTermSheetViewProps) {
  const { id: templateId } = useParams();
  if (!templateId) {
    throw new Error('templateId not supplied through route params');
  }

  const templateFetch = useFetch<TemplateDetailResponse>({
    url: `templates/${templateId}?includeTermSheetSchema=true`,
  });

  const navigate = useNavigate();
  const navigateToTermSheet = () => navigate('.');
  const { enqueueSnackbar } = useCustomizedSnackbar();

  const termSheetSchema = templateFetch.data?.termSheetSchema ?? null;

  const initialValues = useMemo<TermSheetSchemaEditorValues>(
    () =>
      (termSheetSchema
        ? termSheetSchemaValidationSchema.cast(termSheetSchema)
        : termSheetSchemaValidationSchema.getDefault()) as any,
    [termSheetSchema]
  );

  const [selectedValueIndex, setSelectedValueIndex] = useState<number | null>(
    null
  );

  const updateFetch = useFetch({
    url: `templates/${templateId}/term-sheet-schema`,
    method: 'PUT',
    onSuccess: () => {
      enqueueSnackbar('La fiche contrat a été sauvegardée', {
        variant: 'success',
      });
    },
  });

  const ButtonWithPermission = withPermissions(Button);

  return (
    <Box sx={{ width: '100%' }}>
      <Fetching fetching={templateFetch.fetching}>
        <FormikProvider<TermSheetSchemaEditorValues>
          schema={termSheetSchemaValidationSchema}
          initialValues={initialValues}
          onSubmit={values => {
            updateFetch.doFetch({ body: values });
          }}
        >
          <BreadcrumbsContainer breadcrumbs={breadcrumbs}>
            <ButtonWithPermission
              permissions={[
                tenantPermissions.TermSheetElementsCreate,
                tenantPermissions.TermSheetElementsUpdate,
              ]}
              type="submit"
              disabled={updateFetch.fetching}
            >
              <FormattedMessage id="Verbs.Save" defaultMessage="Sauvegarder" />
            </ButtonWithPermission>
          </BreadcrumbsContainer>
          <ErrorMessage error={templateFetch.error} />
          <Stack direction="row" spacing={2}>
            <ErrorBoundary>
              <TermSheetSidePanelWithPermissions
                permissions={[tenantPermissions.TermSheetValuesList]}
                selectedValueIndex={selectedValueIndex}
                setSelectedValueIndex={setSelectedValueIndex}
              />
            </ErrorBoundary>
            <ErrorBoundary>
              <ManageTermSheetSchemaValueWithPermissions
                permissions={[tenantPermissions.TermSheetValuesList]}
                templateId={templateId}
                selectedValueIndex={selectedValueIndex}
                error={updateFetch.error}
              />
            </ErrorBoundary>
          </Stack>
          <DialogOutletRoutes path="modals/*" onClose={navigateToTermSheet}>
            <Route
              path="delete"
              element={
                <DeleteTermSheetSchemaValueDialogWithPermissions
                  permissions={[tenantPermissions.TermSheetElementsDelete]}
                />
              }
            />
          </DialogOutletRoutes>
        </FormikProvider>
      </Fetching>
    </Box>
  );
}
