import React from 'react';
import { useRecoilValue } from 'recoil';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { localeDateFnsSelector } from '@recoil/locale';

function LocalizationProvider({ children }) {
  const dateFnsLocale = useRecoilValue(localeDateFnsSelector);

  return (
    <MuiLocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dateFnsLocale}
    >
      {children}
    </MuiLocalizationProvider>
  );
}

export default LocalizationProvider;
