import { useLayoutEffect } from 'react';
import {
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { hostIsAuthenticatedSelector } from '@recoil/host-token';
import {
  tenantAuthenticationSelector,
  tenantDecodedTokenSelector,
} from '@recoil/tenant-token';
import decode from 'jwt-decode';

function TokenCapture({ children, chooseNavigation }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenParam = useMatch('/capture') ? searchParams.get('token') : null;
  const returnUrl = searchParams.get('returnUrl');
  const decodedTokenParam = tokenParam ? decode(tokenParam) : null;
  const hostState = useRecoilValue(hostIsAuthenticatedSelector);
  const setHostState = useSetRecoilState(hostIsAuthenticatedSelector);
  const tenantState = useRecoilValue(tenantAuthenticationSelector);
  const setTenantState = useSetRecoilState(tenantAuthenticationSelector);
  const decodedTenantToken = useRecoilValue(tenantDecodedTokenSelector);

  function isSameUser(tok1, tok2) {
    return decode(tok1.value).nameid === decode(tok2.value).nameid;
  }

  useLayoutEffect(() => {
    if (decodedTokenParam) {
      const { actort } = decode(decodedTokenParam.token.value);

      switch (actort) {
        case 'tenant-token': {
          const tenantToken = decodedTokenParam.token;
          const tenantRefreshToken = decodedTokenParam.refreshToken;

          const state = {
            fromSearchParam: true,
            token: tenantToken,
            refreshToken: tenantRefreshToken,
          };

          setTenantState(state);

          if (hostState && !isSameUser(state.token, hostState.token)) {
            setHostState(null);
          }

          break;
        }
        case 'host-token': {
          const state = {
            succeeded: true,
            token: decodedTokenParam.token,
            refreshToken: decodedTokenParam.refreshToken,
            tenants: JSON.parse(decodedTokenParam.tenants.value),
          };
          setHostState(state);

          if (tenantState && !isSameUser(state.token, tenantState.token)) {
            setHostState(null);
          }

          navigate('/signin/authorize', {
            state: location.state,
            replace: true,
          });
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [
    decodedTokenParam,
    hostState,
    setHostState,
    tenantState,
    setTenantState,
    location.state,
    navigate,
  ]);

  useLayoutEffect(() => {
    if (tokenParam && tenantState?.fromSearchParam) {
      const { navigationType, navigationElement } = decodedTenantToken;

      const to =
        returnUrl ||
        chooseNavigation({
          type: navigationType,
          element: navigationElement,
          token: decodedTenantToken,
        });

      navigate(to, {
        state: location.state,
        replace: true,
      });
    }
  }, [
    chooseNavigation,
    location,
    tokenParam,
    tenantState,
    decodedTenantToken,
    navigate,
    returnUrl,
  ]);

  return tokenParam ? null : children;
}

export default TokenCapture;
