export const negociateType = {
  Expertise: 'expertise',
  TrackChanges: 'track-changes',
  HtmlChanges: 'html-changes',
};

export const editorMode = {
  Editor: 'editor',
  Reader: 'reader',
  Negociate: 'negociate',
  Sign: 'sign',
  Signed: 'signed',
};
