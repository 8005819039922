import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import withPermissions from '@components/withTenantPermissions';

function DeleteTermSheetSchemaValueDialog() {
  return (
    <DialogWithClose
      titleLabel={
        <FormattedMessage id="TermSheets.Editor.DeleteValue.Dialog.Title" />
      }
      dialogActions={
        <Button color="error">
          <FormattedMessage id="Verbs.Delete" />
        </Button>
      }
    >
      <Stack gap={2}>
        <Typography>
          <FormattedMessage
            id="TermSheets.Editor.DeleteValue.Dialog.Description.Text"
            defaultMessage="Etes-vous sûr de vouloir supprimer cet élément ?"
          />
        </Typography>
      </Stack>
    </DialogWithClose>
  );
}

export const DeleteTermSheetSchemaValueDialogWithPermissions = withPermissions(
  DeleteTermSheetSchemaValueDialog
);

export default DeleteTermSheetSchemaValueDialog;
