import { defineMessages } from 'react-intl';

export default defineMessages({
  WorkflowStepIdentifierRequired: {
    id: 'Workflow.Step.Fields.Identifier.Required',
    defaultMessage: 'Step identifier is required',
  },
  WorkflowStepNameLabel: {
    id: 'Workflow.Step.Fields.Name.Label',
    defaultMessage: 'Step name',
  },
  WorkflowStepNameDefault: {
    id: 'Workflow.Step.Fields.Name.Default',
    defaultMessage: 'New workflow step',
  },
  WorkflowStepNameRequired: {
    id: 'Workflow.Step.Fields.Name.Required',
    defaultMessage: 'Step name is required',
  },
  WorkflowStepButtonsAdd: {
    id: 'Workflow.Step.Buttons.Add',
    defaultMessage: 'Add a workflow step',
  },
  WorkflowStepMandatoryLabel: {
    id: 'Workflow.Step.Fields.Mandatory.Label',
    defaultMessage: 'Mandatory contributors',
  },
  WorkflowStepMandatoryRequired: {
    id: 'Workflow.Step.Fields.Mandatory.Required',
    defaultMessage:
      'This workflow step requires at least one mandatory contributor',
  },
  WorkflowStepMandatoryButtonsAdd: {
    id: 'Workflow.Step.Buttons.Mandatory.Add',
    defaultMessage: 'Add a mandatory contributor',
  },
  WorkflowStepOthersLabel: {
    id: 'Workflow.Step.Fields.Others.Label',
    defaultMessage: 'Others contributors',
  },
  WorkflowStepOthersButtonsAdd: {
    id: 'Workflow.Step.Buttons.Others.Add',
    defaultMessage: 'Add another contributor',
  },
  WorkflowStepDialogsDeleteTitle: {
    id: 'Workflow.Step.Dialogs.Delete.Title',
    defaultMessage: 'Delete a workflow step',
  },
  WorkflowStepDialogsDeleteMessage: {
    id: 'Workflow.Step.Dialogs.Delete.Message',
    defaultMessage: 'You are about to delete the workflow step <b>{name}</b>',
  },
  WorkflowStepAuthorLabel: {
    id: 'Workflow.Step.Author.Label',
    defaultMessage: 'Document author',
  },
  WorkflowContributorAccessLabel: {
    id: 'Workflow.Contributor.Fields.Access.Label',
    defaultMessage: 'Contributor access',
  },
  WorkflowContributorAccessRequired: {
    id: 'Workflow.Contributor.Fields.Access.Required',
    defaultMessage: 'Contributor access is required',
  },
  WorkflowStepAccessNoneLabel: {
    id: 'Workflow.Contributor.Fields.Access.0.Label',
    defaultMessage: 'None',
  },
  WorkflowStepAccessFormLabel: {
    id: 'Workflow.Contributor.Fields.Access.2.Label',
    defaultMessage: 'Form',
  },
  WorkflowStepAccessFormRevisionLabel: {
    id: 'Workflow.Contributor.Fields.Access.3.Label',
    defaultMessage: 'Form / revision',
  },
  WorkflowStepAccessRevisionLabel: {
    id: 'Workflow.Contributor.Fields.Access.4.Label',
    defaultMessage: 'Revision (comment and modification)',
  },
  WorkflowStepAccessRevisionCommentsLabel: {
    id: 'Workflow.Contributor.Fields.Access.5.Label',
    defaultMessage: 'Revision (comment only)',
  },
  WorkflowStepAccessSignatureLabel: {
    id: 'Workflow.Contributor.Fields.Access.1.Label',
    defaultMessage: 'Signature',
  },
  WorkflowStepConditionButtonsAdd: {
    id: 'Workflow.Contributor.Buttons.Condition.Add',
    defaultMessage: 'Add a condition',
  },
  WorkflowStepConditionButtonsEdit: {
    id: 'Workflow.Contributor.Buttons.Condition.Edit',
    defaultMessage: 'Edit condition',
  },
  WorkflowContributorDialogsDeleteTitle: {
    id: 'Workflow.Contributor.Dialogs.Delete.Title',
    defaultMessage: 'Delete a contributor',
  },
  WorkflowContributorDialogsDeleteMessage: {
    id: 'Workflow.Contributor.Dialogs.Delete.Message',
    defaultMessage: 'You are about to delete the contributor <b>{name}</b>',
  },
  WorkflowContributorEntityRequired: {
    id: 'Workflow.Contributor.Fields.Entity.Required',
    defaultMessage: 'Contributor is required',
  },
  WorkflowSettingsTitle: {
    id: 'Workflow.Settings.Title',
    defaultMessage: 'Workflow settings: <b>{name}</b>',
  },
  WorkflowSettingsSave: {
    id: 'Workflow.Settings.Save.Success',
    defaultMessage: 'Workflow settings has been saved successfully',
  },
  WorkflowContributorsRequired: {
    id: 'Workflow.Step.Fields.Contributors.Required',
    defaultMessage: 'This workflow step requires at least one contributor',
  },
  WorkflowContributorPickerLabel: {
    id: 'Workflow.ContributorPicker.Label',
    defaultMessage: 'Collaborateur',
  },
  WorkflowContributorPickerOpenText: {
    id: 'Workflow.ContributorPicker.Open.Text',
    defaultMessage: 'Open',
  },
  WorkflowContributorPickerCloseText: {
    id: 'Workflow.ContributorPicker.Close.Text',
    defaultMessage: 'Close',
  },
  WorkflowContributorPickerClearText: {
    id: 'Workflow.ContributorPicker.Clear.Text',
    defaultMessage: 'Clear',
  },
  WorkflowContributorPickerLoadingText: {
    id: 'Workflow.ContributorPicker.Loading.Text',
    defaultMessage: 'Loading...',
  },
  WorkflowContributorPickerNoOptionsText: {
    id: 'Workflow.ContributorPicker.NoOptions.Text',
    defaultMessage: 'No options',
  },
  WorkflowStepFieldsNotifyContributorsLabel: {
    id: 'Workflow.Step.Fields.NotifyContributors.Label',
    defaultMessage:
      'People to be notified following the validation of the step',
  },
  WorkflowStepButtonsNotifyContributorsAdd: {
    id: 'Workflow.Step.Buttons.NotifyContributors.Add',
    defaultMessage: 'Add a contributor to notify',
  },
  WorkflowStepFieldsCompletionMessageLabel: {
    id: 'Workflow.Step.Fields.CompletionMessage.Label',
    defaultMessage: 'Customize the notification to send after step validation',
  },
  WorkflowStepFieldsCompletionMessagePlaceholder: {
    id: 'Workflow.Step.Fields.CompletionMessage.Placeholder',
    defaultMessage: 'Rédigez votre message',
  },
  WorkflowContributorButtonsRemoveNextStepContributorWarningText: {
    id: 'Workflow.Contributor.Buttons.Remove.NextStepContributorWarningText',
    defaultMessage:
      'You cannot delete this contributor as they belong to the next step.',
  },
  WorkflowStepDuration: {
    id: 'Workflow.Step.Duration.Message',
    defaultMessage: 'Duration step : <b>{duration}</b> day(s)',
  },
  WorkflowStepContributorBy: {
    id: 'Workflow.Step.Contributor.By',
    defaultMessage: 'by',
  },
  WorkFlowStepEditTitle: {
    id: 'WorkFlow.Step.Edit.Title',
    defaultMessage: 'Edit the workflow for this document',
  },
  WorkflowStepButtonsBackLabel: {
    id: 'Workflow.Step.Buttons.Back.Label',
    defaultMessage: 'Back to the document',
  },
  WorkflowStepCompletedLabel: {
    id: 'Workflow.Step.Completed.Label',
    defaultMessage: 'All steps are completed',
  },
  ReaderStepperEditLabel: {
    id: 'Reader.Stepper.Edit.Label',
    defaultMessage: 'Edit',
  },
});
