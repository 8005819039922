import { TermSheetElementResponse } from '@api/types';
import type { InferType } from 'yup';
import * as yup from 'yup';

export const termSheetSchemaValueValidationSchema = yup.object({
  name: yup.string(),
  icon: yup.string(),
  type: yup.number(),
  element: yup
    .object({
      id: yup.string(),
      name: yup.string(),
      icon: yup.string(),
    })
    .nullable()
    .default(null)
    .required('Generic.Fields.Required'),
  elementId: yup.string(),
  source: yup.number().required('Generic.Fields.Required'),
  variables: yup.array(
    yup
      .object({
        id: yup.string(),
        name: yup.string(),
        icon: yup.string(),
      })
      .nullable()
  ),
});

export const termSheetSchemaValidationSchema = yup.object({
  values: yup.array(termSheetSchemaValueValidationSchema),
});

export type TermSheetSchemaEditorValues = InferType<
  typeof termSheetSchemaValidationSchema
>;

export type TermSheetSchemaEditorValue = {
  name: string | null;
  icon: string | null;
  type: number | null;
  element: TermSheetElementResponse | null;
  elementId: string | null;
  source: number | null;
  variableIds: (string | null)[] | null;
};
