import { defineMessages } from 'react-intl';

export default defineMessages({
  HeaderMenuDashboard: {
    id: 'Header.Menu.Dashboard',
    defaultMessage: 'Dashboard',
  },
  HeaderMenuAdministration: {
    id: 'Header.Menu.Administration',
    defaultMessage: 'Administration',
  },
  HeaderMenuParameters: {
    id: 'Header.Menu.Parameters',
    defaultMessage: 'Parameters',
  },
  HeaderMenuStatistics: {
    id: 'Header.Menu.Statistics',
    defaultMessage: 'Statistics',
  },
  HeaderMenuHelp: {
    id: 'Header.Menu.Help',
    defaultMessage: 'Help',
  },
  HeaderMenuAdministrationTeams: {
    id: 'Header.Menu.Administration.Teams',
    defaultMessage: 'Project team',
  },
  HeaderMenuAdministrationDepartments: {
    id: 'Header.Menu.Administration.Departments',
    defaultMessage: 'Departments and experts',
  },
  HeaderMenuAdministrationPackages: {
    id: 'Header.Menu.Administration.Packages',
    defaultMessage: 'Contracts package',
  },
  HeaderMenuAdministrationCampains: {
    id: 'Header.Menu.Administration.Campains',
    defaultMessage: 'Campain settings',
  },
  HeaderMenuAdministrationSuppliers: {
    id: 'Header.Menu.Administration.Suppliers',
    defaultMessage: 'Locked folders',
  },
  HeaderMenuAdministrationReleases: {
    id: 'Header.Menu.Administration.Releases',
    defaultMessage: 'Help center and release notes',
  },
  HeaderMenuAdministrationStatistics: {
    id: 'Header.Menu.Administration.Statistics',
    defaultMessage: 'Statistics',
  },
  HeaderMenuDrive: {
    id: 'Header.Menu.Drive',
    defaultMessage: 'My folders',
  },
  HeaderMenuCalendar: {
    id: 'Header.Menu.Calendar',
    defaultMessage: 'Calendar',
  },
});
