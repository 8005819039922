import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import withPermissions from '@components/withTenantPermissions';
import FormikTextField from '@components/Formik/FormikTextField';
import FormikProvider from '@components/Formik/FormikProvider';
import useFetch from '@hooks/useFetch';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import Fetching from '@components/Fetching/Fetching';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';

function DuplicateTemplateModalContent({ onClose, fetching, error }) {
  return (
    <Box sx={{ width: '100%', p: 4 }}>
      <Fetching fetching={fetching}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography sx={{ fontWeight: 700 }}>
            <FormattedMessage
              id="Administration.DuplicateTemplateModal.Label"
              defaultMessage="Dupliquer ce template"
            />
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <ErrorMessage error={error} sx={{ my: 2 }} />
        <FormikTextField
          required
          name="name"
          label="Nom"
          color="secondary"
          margin="dense"
          disabled={fetching}
        />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
          sx={{ mt: 3 }}
        >
          <Button onClick={onClose} variant="outlined">
            <FormattedMessage
              id="Administration.DuplicateTemplateModal.Buttons.Cancel.Label"
              defaultMessage="Annuler"
            />
          </Button>
          <Button type="submit" disabled={fetching}>
            <FormattedMessage
              id="Administration.DuplicateTemplateModal.Buttons.Create.Label"
              defaultMessage="Dupliquer le template"
            />
          </Button>
        </Stack>
      </Fetching>
    </Box>
  );
}

const schema = Yup.object().shape({ name: Yup.string().required() });
const initialValues = { name: '' };

function DuplicateTemplateModal({ onClose, fetching }) {
  const { id } = useParams();
  const {
    fetching: duplicating,
    doFetch: doPost,
    error,
  } = useFetch({
    method: 'POST',
    onSuccess: () => {
      onClose();
    },
  });
  const onSubmit = async values => {
    doPost({
      url: `templates/${id}/duplicate`,
      body: { id, name: values.name },
    });
  };

  return (
    <FormikProvider
      schema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      <DuplicateTemplateModalContent
        onClose={onClose}
        fetching={fetching || duplicating}
        error={error}
      />
    </FormikProvider>
  );
}

export const DuplicateTemplateModalWithPermissions = withPermissions(
  DuplicateTemplateModal
);

export default DuplicateTemplateModal;

DuplicateTemplateModal.defaultProps = {
  closeOnSuccess: true,
};
