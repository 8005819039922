import React, { useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import StatusChip from '@components/Chips/StatusChip';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Fetching from '@components/Fetching/Fetching';
import MenuButton from '@components/MenuButton/MenuButton';
import FolderIcon from '@mui/icons-material/Folder';
import { templateItemTypes } from '@components/Formik/templates-constants';

const icons = {
  [templateItemTypes.template]: InsertDriveFileIcon,
  [templateItemTypes.folder]: FolderIcon,
};

function TemplateOptionsButton({
  id,
  trash,
  parentId,
  onOpenMenu,
  onCloseMenu,
  useLocalTemplateEditorByDefault,
}) {
  const location = useLocation();
  const backUrl = `${location.pathname}${location.search}${location.hash}`;
  const encodedBackUrl = encodeURIComponent(backUrl);
  const options = useMemo(
    () =>
      !trash
        ? [
            {
              label: 'Modifier le template',
              component: Link,
              to: useLocalTemplateEditorByDefault
                ? `embedded/${id}?backUrl=${encodedBackUrl}`
                : `editor/${id}?backUrl=${encodedBackUrl}`,
            },
            {
              label: 'Modifier le workflow',
              component: Link,
              to: `workflow/${id}?parentId=${parentId || ''}`,
            },
            {
              label: "Modifier l'autocompletion",
              component: Link,
              to: `completion/${id}?parentId=${parentId || ''}`,
            },
            {
              label: 'Modifier la fiche contrat',
              component: Link,
              to: `term-sheet/${id}?parentId=${parentId || ''}`,
            },
            { type: 'divider' },
            {
              id: 'Verbs.Rename',
              component: Link,
              to: `modals/rename/${id}?parentId=${parentId || ''}`,
            },
            {
              label: 'Dupliquer',
              component: Link,
              to: `modals/duplicate-template/${id}?parentId=${parentId || ''}`,
            },
            {
              label: 'Déplacer',
              component: Link,
              to: `modals/move/${id}?parentId=${parentId || ''}`,
            },
            { type: 'divider' },
            {
              id: 'Verbs.Delete',
              component: Link,
              to: `modals/delete/${id}?parentId=${parentId || ''}`,
              sx: { color: 'red' },
            },
          ]
        : [
            {
              id: 'Verbs.Restore',
              component: Link,
              to: `modals/restore/${id}`,
            },
          ],
    [id, trash, parentId, encodedBackUrl, useLocalTemplateEditorByDefault]
  );

  return (
    <MenuButton
      options={options}
      onOpenMenu={onOpenMenu}
      onCloseMenu={onCloseMenu}
    >
      <FormattedMessage
        id="TemplateComponents.TemplateOptions.Buttons.Edit.Label"
        defaultMessage="éditer"
      />
    </MenuButton>
  );
}

function TemplateFolderOptionsButton({
  id,
  trash,
  parentId,
  onOpenMenu,
  onCloseMenu,
}) {
  const options = useMemo(
    () =>
      !trash
        ? [
            {
              id: 'Verbs.Consult',
              component: Link,
              to: `.?parentId=${id}`,
            },
            { type: 'divider' },
            {
              id: 'Verbs.Rename',
              component: Link,
              to: `modals/folders/rename/${id}?parentId=${parentId || ''}`,
            },
            {
              label: 'Déplacer',
              component: Link,
              to: `modals/folders/move/${id}?parentId=${parentId || ''}`,
            },
            { type: 'divider' },
            {
              id: 'Verbs.Delete',
              component: Link,
              to: `modals/folders/delete/${id}?parentId=${parentId || ''}`,
              sx: { color: 'red' },
            },
          ]
        : [
            {
              id: 'Verbs.Restore',
              component: Link,
              to: `modals/folders/restore/${id}`,
            },
          ],
    [id, trash, parentId]
  );

  return (
    <MenuButton
      options={options}
      onOpenMenu={onOpenMenu}
      onCloseMenu={onCloseMenu}
    >
      <FormattedMessage
        id="TemplateComponents.TemplateOptions.Buttons.Edit.Label"
        defaultMessage="éditer"
      />
    </MenuButton>
  );
}

export default function TemplatesList({
  fetching,
  items,
  trash = false,
  parentId,
  useLocalTemplateEditorByDefault,
}) {
  const navigate = useNavigate();

  return (
    <Fetching fetching={fetching}>
      {items.map(item => {
        const Icon = icons[item.type] ?? icons[templateItemTypes.template];
        return (
          <Grid
            key={item.id}
            item
            container
            md={12}
            sx={{
              cursor: item.type === templateItemTypes.folder ? 'pointer' : '',
              alignItems: 'center',
              px: 2,
              transition: 'all 300ms',
              '&:hover': { backgroundColor: 'primary.light' },
            }}
            onClick={e => {
              if (e.detail === 2) {
                if (item.type === templateItemTypes.folder) {
                  navigate(`.?parentId=${item.id}`);
                } else {
                  navigate(`editor/${item.id}?parentId=${parentId || ''}`);
                }
              }
            }}
          >
            <Grid item md={6} sx={{ my: 1 }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Icon />
                <Typography sx={{ fontSize: 13, color: '#666666' }}>
                  {item.name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={3} sx={{ my: 1 }}>
              {typeof item.isPublished === 'boolean' && (
                <StatusChip status={item.isPublished ? 6 : 7} />
              )}
            </Grid>
            <Grid item md={3} sx={{ my: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormattedDate
                  textComponent={Typography}
                  value={item.lastModificationTime}
                  year="numeric"
                  day="numeric"
                  month="long"
                  weekday="narrow"
                />
                {item.type === templateItemTypes.folder ? (
                  <TemplateFolderOptionsButton
                    id={item.id}
                    trash={trash}
                    parentId={parentId}
                  />
                ) : (
                  <TemplateOptionsButton
                    id={item.id}
                    trash={trash}
                    parentId={parentId}
                    useLocalTemplateEditorByDefault={
                      useLocalTemplateEditorByDefault
                    }
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        );
      })}
    </Fetching>
  );
}
