import React from 'react';
import Box from '@mui/material/Box';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';

import WorkflowStepList from './WorkflowSteps/WorkflowStepList';
import WorkflowStepEditor from './WorkflowSteps/WorkflowStepEditor';

function WorkflowEditor({
  schema,
  variablesData,
  error,
  sx = {},
  backUrl,
  hideNotifyContributors,
}) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'minmax(350px, 0.3fr) 1fr',
        gridTemplateRows: 'min-content 1fr',
        columnGap: 2,
        ...sx,
      }}
    >
      <WorkflowStepList schema={schema} backUrl={backUrl} />
      <ErrorBoundary>
        <WorkflowStepEditor
          schema={schema}
          variablesData={variablesData}
          error={error}
          hideNotifyContributors={hideNotifyContributors}
        />
      </ErrorBoundary>
    </Box>
  );
}

export default WorkflowEditor;
