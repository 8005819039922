import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import ContractWorkflowStepCard from './ContractWorkflowStepCard';
import TemplateWorkflowStepCard from './TemplateWorkflowStepCard';

const WorkflowStepListItem = React.forwardRef(
  (
    {
      selected,
      hideDragIndicator = false,
      onClick = () => {},
      step,
      isFirst,
      schema,
      ...props
    },
    ref
  ) => {
    const WorkflowStepCard = schema
      ? TemplateWorkflowStepCard
      : ContractWorkflowStepCard;
    return (
      <ListItemButton
        ref={ref}
        disableGutters
        dense
        sx={{ px: 1 }}
        disableRipple
        {...props}
      >
        {!hideDragIndicator && (
          <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }} data-movable-handle>
            <DragIndicatorIcon />
          </ListItemIcon>
        )}
        <ListItemText
          onClick={onClick}
          disableTypography
          primary={
            <WorkflowStepCard {...step} selected={selected} isFirst={isFirst} />
          }
        />
      </ListItemButton>
    );
  }
);

export default WorkflowStepListItem;
