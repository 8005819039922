import { getDateTimezoneIndependantFromTimestamp } from '@utils/date-helpers';
import { IntlShape } from 'react-intl';

export const emptyValue = { label: 'Aucune valeur', value: '-1' };

export const getTextLabel = (
  label: string,
  termSheetType: string | undefined,
  intl: IntlShape
) => {
  if (label === emptyValue.label || termSheetType === undefined) {
    return label;
  }
  switch (termSheetType) {
    case 'Timestamp': {
      const convertedDate = getDateTimezoneIndependantFromTimestamp(
        parseInt(label, 10)
      );
      return `${intl.formatDate(convertedDate ?? undefined)} ${intl.formatTime(
        convertedDate ?? undefined
      )}`;
    }
    case 'DateOnly': {
      const convertedDate = getDateTimezoneIndependantFromTimestamp(
        parseInt(label, 10)
      );
      return intl.formatDate(convertedDate ?? undefined);
    }
    case 'TimeOnly': {
      const convertedDate = getDateTimezoneIndependantFromTimestamp(
        parseInt(label, 10)
      );
      return intl.formatTime(convertedDate ?? undefined);
    }
    case 'Number': {
      return label.toLocaleString();
    }
    case 'Boolean': {
      return label.toLocaleString();
    }
    default: {
      return label;
    }
  }
};
