import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FastField } from 'formik';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';

import ClearIcon from '@mui/icons-material/Clear';

import { valueFromPath } from '@utils/json-path';

function MuiFormikSelect({
  children,
  field,
  form: { touched, errors, setFieldValue },
  label,
  required = false,
  FormControlProps = {},
  InputLabelProps = {},
  FormHelperTextProps = {},
  disabled = false,
  margin = 'normal',
  multiple = false,
  isClearable = false,
  ...props
}) {
  const { name } = field;
  const error = name.includes('.') ? valueFromPath(errors, name) : errors[name];
  const touch = name.includes('.')
    ? valueFromPath(touched, name)
    : touched[name];
  const hasError = Boolean(touch) && Boolean(error);
  return (
    <FormControl
      fullWidth
      margin={margin}
      {...FormControlProps}
      required={required}
      error={hasError}
    >
      <InputLabel {...InputLabelProps} id={`label-${name}`}>
        {label}
      </InputLabel>
      <Select
        {...field}
        value={field?.value ?? (multiple ? [] : '')}
        defaultValue={props.defaultValue || ''}
        {...props}
        multiple={multiple}
        disabled={disabled}
        label={label}
        labelId={`label-${name}`}
        error={hasError}
        endAdornment={
          isClearable && !multiple && (field?.value ?? '') !== '' ? (
            <IconButton
              size="small"
              sx={{ mr: 1 }}
              onClick={() => setFieldValue(name, '')}
            >
              <ClearIcon sx={{ fontSize: '16px !important' }} />
            </IconButton>
          ) : null
        }
      >
        {children}
      </Select>
      <FormHelperText {...FormHelperTextProps} error={hasError}>
        {hasError && (
          <FormattedMessage
            id={error}
            values={{ label: label ?? '', value: field?.value ?? '' }}
          />
        )}
      </FormHelperText>
    </FormControl>
  );
}

function FormikSelect({ as = 'field', ...props }) {
  if (as === 'fastfield') {
    return <FastField component={MuiFormikSelect} {...props} />;
  }
  return <Field component={MuiFormikSelect} {...props} />;
}

export default FormikSelect;
