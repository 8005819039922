import { defineMessages } from 'react-intl';

export default defineMessages({
  ContractContentSaveSuccess: {
    id: 'Contract.Content.Save.Success',
    defaultMessage: 'Document content has been saved successfully',
  },
  ContractWorkflowValidateSuccess: {
    id: 'Contract.Workflow.Validate.Success',
    defaultMessage: 'Your contribution has been validated successfully',
  },
  ContractWorkflowInvalidatedSuccess: {
    id: 'Contract.Workflow.Invalidate.Success',
    defaultMessage: 'Your contribution has been invalidated successfully',
  },
  ContractWorkflowDialogValidateTitle: {
    id: 'Contract.Workflow.Dialog.Validate.Title',
    defaultMessage: 'Validate your contribution',
  },
  ContractWorkflowDialogValidateParagraph: {
    id: 'Contract.Workflow.Dialog.Validate.Paragraph',
    defaultMessage:
      'You are about to validate your contribution to the current workflow step <b>{name}</b>.',
  },
  ContractWorkflowDialogValidateExplanation: {
    id: 'Contract.Workflow.Dialog.Validate.Explanation',
    defaultMessage:
      'If everyone has validated their contribution for the current step, the next workflow step will start.',
  },
  ContractWorkflowDialogInvalidateTitle: {
    id: 'Contract.Workflow.Dialog.Invalidate.Title',
    defaultMessage: 'Invalidate your contribution',
  },
  ContractWorkflowDialogInvalidateParagraph: {
    id: 'Contract.Workflow.Dialog.Invalidate.Paragraph',
    defaultMessage:
      'You are about to invalidate your contribution to the current workflow step <b>{name}</b>.',
  },
  ContractDialogsSignatureSendTitle: {
    id: 'Contract.Dialogs.Signature.Send.Title',
    defaultMessage: 'Send for signature',
  },
  ContractDialogsSignatureSendParagraph: {
    id: 'Contract.Dialogs.Signature.Send.Paragraph',
    defaultMessage:
      'You are about to send the contract <b>{name}</b> for signature.',
  },
  ContractSignatureSendButton: {
    id: 'Contract.Signature.Send.Button',
    defaultMessage: 'Send for signature',
  },
  ContractDialogsSignatureCancelTitle: {
    id: 'Contract.Dialogs.Signature.Cancel.Title',
    defaultMessage: 'Cancel signing process',
  },
  ContractDialogsSignatureCancelParagraph: {
    id: 'Contract.Dialogs.Signature.Cancel.Paragraph',
    defaultMessage:
      'You are about to cancel the signing process for the contract <b>{name}</b>.',
  },
  ContractSignatureCancelButton: {
    id: 'Contract.Signature.Cancel.Button',
    defaultMessage: 'Cancel signing process',
  },
  ContractSignatureSendSuccess: {
    id: 'Contract.Signature.Send.Success',
    defaultMessage: 'The contract was sent for signature.',
  },
  ContractSignatureCancelSuccess: {
    id: 'Contract.Signature.Cancel.Success',
    defaultMessage: 'The signing process was cancelled.',
  },
  ContractContentCallExpertSuccess: {
    id: 'Contract.Content.CallExpert.Success',
    defaultMessage: 'The expert has been called.',
  },
  ContractContentCallExpertError: {
    id: 'Contract.Content.CallExpert.Error',
    defaultMessage: 'The expert could not be called.',
  },
  ContractWorkflowButtonsComplete: {
    id: 'Contract.Workflow.Buttons.Complete',
    defaultMessage: 'Complete the workflow step',
  },
  ContractWorkflowCompleteSuccess: {
    id: 'Contract.Workflow.Complete.Success',
    defaultMessage: 'The workflow step was completed',
  },
  ContractWorkflowDialogCompleteTitle: {
    id: 'Contract.Workflow.Dialog.Complete.Title',
    defaultMessage: 'Complete the workflow step',
  },
  ContractWorkflowDialogCompleteParagraph: {
    id: 'Contract.Workflow.Dialog.Complete.Paragraph',
    defaultMessage:
      'You are about to complete the workflow step <b>{name}</b>.',
  },
  ContractWorkflowDialogCompleteExplanation: {
    id: 'Contract.Workflow.Dialog.Complete.Explanation',
    defaultMessage:
      'The next workflow step will begin immediatly after completing the current step.',
  },
  MultiSignatureModalDescriptionText: {
    id: 'MultiSignatureModal.Description.Text',
    defaultMessage:
      'Send all or a part of the validated documents to signature.',
  },
  MultiSignatureModalSectionsDocumentsTitle: {
    id: 'MultiSignatureModal.Sections.Documents.Title',
    defaultMessage: 'Documents',
  },
  MultiSignatureModalSectionsSignatureTitle: {
    id: 'MultiSignatureModal.Sections.Signature.Title',
    defaultMessage: 'Signatories',
  },
  MultiSignatureModalSectionSignatureButtonAddNewSignatureForm: {
    id: 'MultiSignatureModal.Section.Signature.Button.AddNewSignatureForm',
    defaultMessage: 'Add a new signatory',
  },
  MultiSignatureModalButtonsSubmit: {
    id: 'MultiSignatureModal.Buttons.Submit',
    defaultMessage: 'Continue',
  },
  MultiSignatureModalButtonsCancel: {
    id: 'MultiSignatureModal.Buttons.Cancel',
    defaultMessage: 'Cancel',
  },
  MultiSignatureModalTitle: {
    id: 'MultiSignatureModal.Title',
    defaultMessage: 'Signature',
  },
  MultiSignatureModalDescriptionHeader: {
    id: 'MultiSignatureModal.Description.Header',
    defaultMessage: 'Signature',
  },
  ContractDialogsDeleteTitle: {
    id: 'Contract.Dialogs.Delete.Title',
    defaultMessage: 'Delete a contract',
  },
  ContractDialogsDeleteMessage: {
    id: 'Contract.Dialogs.Delete.Message',
    defaultMessage: 'You are about to delete the contract <b>{name}</b>',
  },
  ContractWorkflowButtonsReject: {
    id: 'Contract.Workflow.Buttons.Reject',
    defaultMessage: 'Reject the document',
  },
  ContractWorkflowRejectSuccess: {
    id: 'Contract.Workflow.Reject.Success',
    defaultMessage: 'The document was successfully rejected',
  },
  ContractWorkflowDialogRejectTitle: {
    id: 'Contract.Workflow.Dialog.Reject.Title',
    defaultMessage: 'Reject the document',
  },
  ContractWorkflowDialogRejectExplanation: {
    id: 'Contract.Workflow.Dialog.Reject.Explanation',
    defaultMessage:
      'Rejecting the document will return it to the first step of the workflow for a new approval cycle. <b>This action is irreversible.</b>',
  },
  ContractWorkflowDialogRejectReasonLabel: {
    id: 'Contract.Workflow.Dialog.Reject.Reason.Label',
    defaultMessage: 'Reason for rejection',
  },
  ContractWorkflowDialogRejectReasonDescription: {
    id: 'Contract.Workflow.Dialog.Reject.Reason.Description',
    defaultMessage:
      'Add details of why you rejected the document so that the recipient can make relevant changes.',
  },
  ContractWorkflowDialogRejectReasonPlaceholder: {
    id: 'Contract.Workflow.Dialog.Reject.Reason.Placeholder',
    defaultMessage: 'Reason for rejection',
  },
  ContractWorkflowDialogRejectConfirmationQuestion: {
    id: 'Contract.Workflow.Dialog.Reject.Confirmation.Question',
    defaultMessage: 'Confirm rejection of the document?',
  },
  ContractWorkflowDialogRejectConfirmationPeople: {
    id: 'Contract.Workflow.Dialog.Reject.Confirmation.People',
    defaultMessage:
      'The following people will be notified of the reason for your rejection by email and the author of the document will be invited to start a new approval process taking your comments into account.',
  },
});
