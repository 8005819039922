import {
  useMemo,
  useState,
  useCallback,
  lazy,
  ChangeEvent,
  ComponentType,
  useRef,
} from 'react';
import BreadcrumbsContainer from '@components/Breadcrumbs/BreadcrumbsContainer';
import SearchWithFilters from '@components/Search/SearchWithFilters';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';
import MenuButton from '@components/MenuButton/MenuButton';
import FolderIcon from '@mui/icons-material/Folder';
import {
  useNavigate,
  Route,
  Link,
  useSearchParams,
  Routes,
  useResolvedPath,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useFetch from '@hooks/useFetch';
import useDebounced from '@hooks/useDebounced';
import Fetching from '@components/Fetching/Fetching';
import DialogOutletRoutes from '@components/Dialog/DialogOutletRoutes';
import NavigationModal from '@components/Modals/NavigationModal';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { sortModes } from '@views/constants';
import { negociateType } from '@editor/plugins/types';
import { Breadcrumb } from '@components/Breadcrumbs/Breadcrumbs';
import tenantPermissions from '@utils/tenant-permissions';
import withPermissions from '@components/withTenantPermissions';
import { MoveTemplateModalWithPermissions } from './components/MoveTemplateModal';
import { MoveTemplateFolderModalWithPermissions } from './components/MoveTemplateFolderModal';
import { DriveTableWithPermissions } from './components/TemplatesTable';
import { DeleteTemplateModalWithPermissions } from './components/DeleteTemplateModal';
import { NewBlankTemplateModalWithPermissions } from './components/NewBlankTemplateModal';
import { DuplicateTemplateModalWithPermissions } from './components/DuplicateTemplateModal';
import { CreateTemplateFolderModalWithPermissions } from './components/CreateTemplateFolderModal';
import { RenameTemplateFolderModalWithPermissions } from './components/RenameTemplateFolderModal';
import { DeleteTemplateFolderModalWithPermissions } from './components/DeleteTemplateFolderModal';
import { RestoreTemplateModalWithPermissions } from './components/RestoreTemplateModal';
import { RestoreTemplateFolderModalWithPermissions } from './components/RestoreTemplateFolderModal';
import { RenameTemplateModalWithPermissions } from './components/RenameTemplateModal';
import EditTemplateTermSheetView from './TermSheetSchema/EditTemplateTermSheetSchemaView';

const EditorView = lazy(() => import('@views/Tiptap/Editor/EditorView'));
const EditorEmbeddedView = lazy(
  () => import('@views/Tiptap/Editor/EditorEmbeddedView')
);
const TesterView = lazy(() => import('@views/Tiptap/Reader/TesterView'));
const DefaultTemplateCompletionView = lazy(
  () => import('@views/Administration/Templates/TemplateCompletionView')
);
const DefaultTemplateWorkflowView = lazy(
  () => import('@views/Administration/Templates/TemplateWorkflowView')
);

const getTemplateOptions = (parentId: string) => [
  {
    id: 'TemplatesView.Buttons.AddTemplate.Label',
    icon: <FileOpenIcon fontSize="large" />,
    component: Link,
    to: `modals/add-template?parentId=${parentId || ''}`,
  },
  {
    id: 'TemplatesView.Buttons.AddFolder.Label',
    icon: <FolderIcon fontSize="large" />,
    component: Link,
    to: `modals/add-folder?parentId=${parentId || ''}`,
  },
];

const getAddTemplateOptions = (parentId: string) => [
  {
    id: 'Template.Create.Blank.Button.Label',
    component: Link,
    to: `blank?parentId=${parentId || ''}`,
  },
  { type: 'divider' },
  {
    id: 'Template.Create.ImportWord.Button.Label',
    disabled: true,
    component: Link,
    to: `word?parentId=${parentId || ''}`,
  },
  { type: 'divider' },
  {
    id: 'Template.Create.ImportZip.Button.Label',
    disabled: true,
    component: Link,
    to: `zip?parentId=${parentId || ''}`,
  },
];

type TemplateSearchItem = {
  id: string;
  name: string;
  creationTime: string;
  lastModificationTime: string;
  type: 'template' | 'folder';
  isPublished: boolean;
};

type TemplatePathItem = {
  id: string;
  name: string;
};

type TemplatesDefaultViewProps = {
  breadcrumbs: Breadcrumb[];
  trash?: boolean;
  useLocalTemplateEditorByDefault?: boolean;
};

const ButtonWithPermission = withPermissions(Button);
const MenuButtonWithPermission = withPermissions(MenuButton);

function TemplatesDefaultView({
  breadcrumbs: breadcrumbsProp,
  trash = false,
  useLocalTemplateEditorByDefault = false,
}: TemplatesDefaultViewProps) {
  const ref = useRef<{ path: TemplatePathItem[] | null }>({ path: null });
  const [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounced(searchValue, 500);
  const [searchedList, setSearchedList] = useState<TemplateSearchItem[]>([]);
  const parentId = searchParams.get('parentId') || '';

  const {
    data,
    fetching,
    doFetch: refresh,
  } = useFetch({
    method: 'GET',
    url: `/templates/drive?parentId=${parentId || ''}&trash=${Boolean(trash)}`,
    onSuccess: (response: any) => {
      setSearchedList(response?.data?.items ?? []);
    },
  });

  const path = (data?.path as TemplatePathItem[] | null) ?? ref.current.path;
  if (path) {
    ref.current.path = path;
  }

  useFetch({
    url:
      `/templates/search?` +
      `query=${encodeURIComponent(debouncedSearchValue)}` +
      `&parentId=${parentId || ''}`,
    lazy: !debouncedSearchValue,
    method: 'GET',
    onSuccess: (response: any) => {
      setSearchedList(response?.data?.items ?? []);
    },
  });

  const [page, setPage] = useState(1);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (e.target.value === '') {
      setSearchedList(data?.items ?? []);
    }
    setPage(1);
  };

  const navigate = useNavigate();
  const navigateToTemplates = () => navigate(`.?parentId=${parentId || ''}`);
  const handleSuccess = () => {
    refresh();
    navigateToTemplates();
  };

  const [sortMode, setSortMode] = useState(sortModes.default);

  const sortedItems = useMemo(
    () =>
      sortMode === sortModes.default
        ? searchedList
        : [...searchedList].sort((a, b) => {
            switch (sortMode) {
              case sortModes.nameAtoZ:
                return a.name
                  ?.toLowerCase()
                  ?.localeCompare(b.name?.toLowerCase());
              case sortModes.nameZtoA:
                return b.name
                  ?.toLowerCase()
                  ?.localeCompare(a.name?.toLowerCase());
              case sortModes.statusAsc:
                return (
                  Number(a.isPublished ?? false) -
                  Number(b.isPublished ?? false)
                );
              case sortModes.statusDesc:
                return (
                  Number(b.isPublished ?? false) -
                  Number(a.isPublished ?? false)
                );
              case sortModes.dateModifiedNewestFirst:
                return (
                  new Date(a.lastModificationTime).getTime() -
                  new Date(b.lastModificationTime).getTime()
                );
              case sortModes.dateModifiedOldestFirst:
                return (
                  new Date(b.lastModificationTime).getTime() -
                  new Date(a.lastModificationTime).getTime()
                );
              default:
                return a.name
                  ?.toLowerCase()
                  ?.localeCompare(b.name?.toLowerCase());
            }
          }),
    [searchedList, sortMode]
  );

  const { doFetch: doPostBlankTemplate, fetching: postingBlankTemplate } =
    useFetch({
      method: 'POST',
      url: '/templates',
      onSuccess: () => {
        refresh();
        navigateToTemplates();
      },
    });

  const handleCreateBlankTemplate = useCallback(
    values => {
      doPostBlankTemplate({ body: { ...values, folderId: parentId } });
    },
    [doPostBlankTemplate, parentId]
  );

  const { doFetch: doPostTemplateFolder, fetching: postingTemplateFolder } =
    useFetch({
      method: 'POST',
      url: '/templates/folders',
      onSuccess: () => {
        refresh();
        navigateToTemplates();
      },
    });

  const handleCreateTemplateFolder = useCallback(
    values => {
      doPostTemplateFolder({ body: { ...values, parentId } });
    },
    [doPostTemplateFolder, parentId]
  );

  const { doFetch: doDelete, fetching: deleting } = useFetch({
    method: 'DELETE',
    onSuccess: () => {
      refresh();
      navigateToTemplates();
    },
  });

  const handleDeleteTemplate = useCallback(
    id => () => {
      doDelete({ url: `/templates/${id}` });
    },
    [doDelete]
  );

  const breadcrumbs = useMemo<Breadcrumb[]>(() => {
    if (trash) {
      return [
        ...breadcrumbsProp,
        {
          to: `trash`,
          message: 'Dashboard.Recycle.Bin.Label',
        },
      ];
    }

    if (!path?.length) return breadcrumbsProp;
    const result = [...breadcrumbsProp];
    path.forEach(folder =>
      result.push({
        key: `folder-${folder.id}`,
        to: `.?parentId=${folder.id}`,
        label: folder.name,
      })
    );
    return result;
  }, [breadcrumbsProp, path, trash]);

  const templateOptions = useMemo(
    () => getTemplateOptions(parentId),
    [parentId]
  );

  const addTemplateOptions = useMemo(
    () => getAddTemplateOptions(parentId),
    [parentId]
  );

  const handleTrashClick = () => {
    navigate('trash');
    setSearchValue('');
  };

  const handleBackTrashClick = () => {
    navigate('..');
    setSearchValue('');
  };

  return (
    <>
      <Stack direction="column" sx={{ width: '100%' }}>
        <Grid container>
          <Grid item md={12}>
            <BreadcrumbsContainer breadcrumbs={breadcrumbs}>
              {!trash && (
                <MenuButtonWithPermission
                  permissions={[
                    tenantPermissions.TemplatesCreate,
                    tenantPermissions.TemplateFoldersCreate,
                  ]}
                  mode="all"
                  variant="contained"
                  options={templateOptions}
                >
                  <FormattedMessage
                    id="TemplatesView.Buttons.New.Label"
                    defaultMessage="NOUVEAU"
                  />
                </MenuButtonWithPermission>
              )}
            </BreadcrumbsContainer>
            <Fetching fetching={fetching} sx={{ width: '100%' }}>
              <Grid sx={{ p: 4 }} item xs={12}>
                <Box sx={{ px: 16 }}>
                  <SearchWithFilters value={searchValue} onChange={onChange} />
                </Box>
                <DriveTableWithPermissions
                  permissions={[
                    tenantPermissions.TemplateFoldersList,
                    tenantPermissions.TemplatesList,
                  ]}
                  items={sortedItems}
                  page={page}
                  setPage={setPage}
                  sortMode={sortMode}
                  setSortMode={setSortMode}
                  trash={trash}
                  parentId={parentId}
                  useLocalTemplateEditorByDefault={
                    useLocalTemplateEditorByDefault
                  }
                />
                {!trash ? (
                  <ButtonWithPermission
                    permissions={[
                      tenantPermissions.TemplateFoldersList,
                      tenantPermissions.TemplatesList,
                    ]}
                    startIcon={<DeleteOutlineTwoToneIcon />}
                    variant="text"
                    color="error"
                    onClick={handleTrashClick}
                  >
                    Corbeille
                  </ButtonWithPermission>
                ) : (
                  <ButtonWithPermission
                    permissions={[
                      tenantPermissions.TemplateFoldersList,
                      tenantPermissions.TemplatesList,
                    ]}
                    startIcon={<ArrowBackIcon />}
                    variant="text"
                    onClick={handleBackTrashClick}
                  >
                    Retour
                  </ButtonWithPermission>
                )}
              </Grid>
            </Fetching>
          </Grid>
        </Grid>
      </Stack>
      <DialogOutletRoutes path="modals/*" onClose={navigateToTemplates}>
        {!trash ? (
          <>
            <Route
              path="add-template"
              element={
                <NavigationModal
                  title={<FormattedMessage id="TemplatesView.Popup.Title" />}
                  buttons={addTemplateOptions}
                />
              }
            />

            <Route
              path="add-template/blank"
              element={
                <NewBlankTemplateModalWithPermissions
                  permissions={[
                    tenantPermissions.TemplateFoldersCreate,
                    tenantPermissions.TemplatesCreate,
                  ]}
                  onSubmit={handleCreateBlankTemplate}
                  disabled={postingBlankTemplate}
                  folderId={undefined}
                  templates={undefined}
                  handleSubmit={undefined}
                />
              }
            />

            <Route
              path="add-folder"
              element={
                <CreateTemplateFolderModalWithPermissions
                  permissions={[tenantPermissions.TemplateFoldersCreate]}
                  onSubmit={handleCreateTemplateFolder}
                  disabled={postingTemplateFolder}
                />
              }
            />

            <Route
              path="rename/:id"
              element={
                <RenameTemplateModalWithPermissions
                  permissions={[tenantPermissions.TemplatesUpdate]}
                  onSuccess={handleSuccess}
                />
              }
            />

            <Route
              path="move/:id"
              element={
                <MoveTemplateModalWithPermissions
                  permissions={[tenantPermissions.TemplatesUpdate]}
                  onSuccess={handleSuccess}
                  basePath={path}
                />
              }
            />

            <Route
              path="delete/:id"
              element={
                <DeleteTemplateModalWithPermissions
                  permissions={[tenantPermissions.TemplatesDelete]}
                  handleSubmit={handleDeleteTemplate}
                  disabled={deleting}
                />
              }
            />
            <Route
              path="duplicate-template/:id"
              element={
                <DuplicateTemplateModalWithPermissions
                  permissions={[tenantPermissions.TemplatesCreate]}
                  onClose={handleSuccess}
                  fetching={fetching}
                />
              }
            />
          </>
        ) : (
          <Route
            path="restore/:id"
            element={
              <RestoreTemplateModalWithPermissions
                permissions={[tenantPermissions.TemplatesUpdate]}
                onSuccess={handleSuccess}
              />
            }
          />
        )}
        <Route path="folders/*">
          {!trash ? (
            <>
              <Route
                path="rename/:id"
                element={
                  <RenameTemplateFolderModalWithPermissions
                    permissions={[tenantPermissions.TemplateFoldersUpdate]}
                    onSuccess={handleSuccess}
                  />
                }
              />
              <Route
                path="move/:id"
                element={
                  <MoveTemplateFolderModalWithPermissions
                    permissions={[tenantPermissions.TemplateFoldersUpdate]}
                    onSuccess={handleSuccess}
                    basePath={path}
                  />
                }
              />
              <Route
                path="delete/:id"
                element={
                  <DeleteTemplateFolderModalWithPermissions
                    permissions={[tenantPermissions.TemplateFoldersDelete]}
                    onSuccess={handleSuccess}
                  />
                }
              />
            </>
          ) : (
            <Route
              path="restore/:id"
              element={
                <RestoreTemplateFolderModalWithPermissions
                  permissions={[tenantPermissions.TemplateFoldersUpdate]}
                  onSuccess={handleSuccess}
                />
              }
            />
          )}
        </Route>
      </DialogOutletRoutes>
    </>
  );
}

function TemplatesView(props: {
  breadcrumbs?: Breadcrumb[];
  useLocalTemplateEditorByDefault?: boolean;
  TemplateCompletionView?: ComponentType<{ backUrl: string }>;
  TemplateWorkflowView?: ComponentType<{ backUrl: string; fullWidth: boolean }>;
}) {
  const url = useResolvedPath('.').pathname;
  const genericBreadcrumbs = useMemo<Breadcrumb[]>(
    () => [
      { to: '/admin', message: 'Admin.Administration' },
      {
        to: url,
        message: 'Admin.Manage.Templates',
      },
    ],
    [url]
  );

  const {
    useLocalTemplateEditorByDefault,
    TemplateCompletionView = DefaultTemplateCompletionView,
    TemplateWorkflowView = DefaultTemplateWorkflowView,
  } = props;

  return (
    <Routes>
      <Route path="*">
        <Route
          path="*"
          element={
            <TemplatesDefaultView
              {...{ breadcrumbs: genericBreadcrumbs, ...props }}
            />
          }
        />
        <Route
          path="trash/*"
          element={
            <TemplatesDefaultView
              {...{ breadcrumbs: genericBreadcrumbs, ...props }}
              trash
            />
          }
        />
        <Route
          path="editor/:id/*"
          element={
            useLocalTemplateEditorByDefault ? (
              <EditorView negociateType={negociateType.TrackChanges} />
            ) : (
              <EditorEmbeddedView backUrl="/admin/templates" />
            )
          }
        />
        {useLocalTemplateEditorByDefault && (
          <Route
            path="embedded/:id/*"
            element={<EditorEmbeddedView backUrl="/admin/templates" />}
          />
        )}
        <Route path="editor/:id/test/*" element={<TesterView />} />
        <Route
          path="workflow/:id/*"
          element={<TemplateWorkflowView fullWidth backUrl={url} />}
        />
        <Route
          path="completion/:id/*"
          element={<TemplateCompletionView backUrl={url} />}
        />
        <Route
          path="term-sheet/:id/*"
          element={
            <EditTemplateTermSheetView breadcrumbs={genericBreadcrumbs} />
          }
        />
      </Route>
    </Routes>
  );
}

export default TemplatesView;
