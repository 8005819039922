import React, { Component, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import merge from 'lodash.merge';
import {
  Query,
  Builder,
  Utils as QbUtils,
  BasicConfig,
} from 'react-awesome-query-builder';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import ScmMuiDateWidget from '@components/Pickers/ScmMuiDateWidget';

import FormikSelect from '@components/Formik/FormikSelect';
import FormikContributorPicker from '@components/Formik/FormikContributorPicker';
import { CustomConfirmDialog } from '@editor/dialogs/CustomConfirmDialog';
import WorkflowContributorDeleteDialog from './WorkflowContributorDeleteDialog';
import { useWorkflowContext } from '../WorkflowContextProvider';

const InitialConfig = { ...BasicConfig };

const operators = {
  ...InitialConfig.operators,
};

const types = {
  ...InitialConfig.types,
  // examples of overriding
  boolean: merge(InitialConfig.types.boolean, {
    widgets: {
      boolean: {
        widgetProps: {
          hideOperator: true,
          // operatorInlineLabel: "is",
        },
      },
    },
  }),
  text: merge(InitialConfig.types.text, {
    operators: [
      'equal',
      'not_equal',
      'like',
      'not_like',
      'is_empty',
      'is_not_empty',
      'is_null',
      'is_not_null',
    ],
  }),
};

const widgets = {
  ...InitialConfig.widgets,
  // examples of  overriding
  slider: {
    ...InitialConfig.widgets.slider,
    customProps: {
      width: '300px',
    },
  },
  rangeslider: {
    ...InitialConfig.widgets.rangeslider,
    customProps: {
      width: '300px',
    },
  },
  date: {
    ...InitialConfig.widgets.date,
    dateFormat: 'DD.MM.YYYY',
    valueFormat: 'YYYY-MM-DD',
    jsType: 'number',
    factory: props => <ScmMuiDateWidget {...props} />,
    jsonLogic: {
      function(val) {
        const localDate = new Date(val);
        // return utc epoch date
        return Date.UTC(
          localDate.getFullYear(),
          localDate.getMonth(),
          localDate.getDate()
        );
      },
    },
  },
  time: {
    ...InitialConfig.widgets.time,
    timeFormat: 'HH:mm',
    valueFormat: 'HH:mm:ss',
  },
  datetime: {
    ...InitialConfig.widgets.datetime,
    timeFormat: 'HH:mm',
    dateFormat: 'DD.MM.YYYY',
    valueFormat: 'YYYY-MM-DD HH:mm:ss',
  },
  treeselect: {
    ...InitialConfig.widgets.treeselect,
    customProps: {
      showSearch: true,
    },
  },
};

const localeSettings = {
  valueLabel: 'Value',
  valuePlaceholder: 'Value',
  fieldLabel: 'Field',
  operatorLabel: 'Operator',
  fieldPlaceholder: 'Select field',
  operatorPlaceholder: 'Select operator',
  deleteLabel: null,
  addGroupLabel: 'Add group',
  addRuleLabel: 'Add rule',
  addSubRuleLabel: 'Add sub rule',
  delGroupLabel: null,
  notLabel: 'Not',
  valueSourcesPopupTitle: 'Select value source',
  removeRuleConfirmOptions: {
    title: 'Are you sure delete this rule?',
    okText: 'Yes',
    okType: 'danger',
  },
  removeGroupConfirmOptions: {
    title: 'Are you sure delete this group?',
    okText: 'Yes',
    okType: 'danger',
  },
};

const settings = {
  ...InitialConfig.settings,
  ...localeSettings,
  maxNesting: 3,
  canLeaveEmptyGroup: false,
};

const editorCondtionsConfig = {
  ...InitialConfig,
  operators,
  types,
  widgets,
  settings,
};

export class ContributorConditionEditor extends Component {
  constructor(props) {
    super(props);
    const { fields } = this.props;
    const config = {
      ...editorCondtionsConfig,
      fields,
    };
    this.state = {
      tree: QbUtils.checkTree(
        QbUtils.loadFromJsonLogic(props.conditionState.value ?? null, config),
        config
      ),
      config,
    };
  }

  static renderBuilder = props => (
    <div className="query-builder-container" style={{ padding: '10px' }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  onChange = (immutableTree, config) => {
    const { conditionState } = this.props;
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    this.setState({ tree: immutableTree, config });
    const jsonTree = QbUtils.jsonLogicFormat(immutableTree, config);

    if (jsonTree.logic) {
      conditionState.update(jsonTree.logic);
    } else {
      conditionState.update(null);
    }
  };

  render() {
    const { tree, config } = this.state;
    return (
      <div>
        <Query
          {...config}
          value={tree}
          onChange={this.onChange}
          renderBuilder={ContributorConditionEditor.renderBuilder}
        />
      </div>
    );
  }
}

export const DeleteWorkflowContributorButton = React.forwardRef(
  ({ onClick, disabled }, ref) => (
    <IconButton ref={ref} disabled={disabled} onClick={onClick}>
      <DeleteOutlineIcon color={disabled ? 'disabled' : 'error'} />
    </IconButton>
  )
);

const useConditionFields = variablesData =>
  useMemo(() => {
    const fields = {};
    if (!variablesData?.variables) return fields;

    variablesData?.variables?.forEach(variable => {
      if (!variable?.attrs) return;
      let { type } = variable.attrs;
      if (type === 'calculated') {
        type = 'number';
      } else if (type === 'linked') {
        return;
      }

      fields[variable.attrs.uid] = {
        label: variable.attrs.name,
        type,
      };
    });

    return fields;
  }, [variablesData]);

function WorkflowContributorEditor({
  name,
  index,
  contributor: contributorProp = null,
  showSignature = false,
  readOnly = false,
  onRemove = () => {},
  variablesData,
  hideAccess = false,
  hideCondition = false,
  hideRemove = false,
  usersOnly = false,
  schema = false,
}) {
  const [openPopup, setOpenPopup] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const valuePath = `${name}.${index}.condition`;
  const { values, setFieldValue } = useFormikContext();
  const { excludeRoleIds } = useWorkflowContext();
  const setConditionValue = condition => {
    setFieldValue(valuePath, JSON.stringify(condition));
  };

  const fields = useConditionFields(variablesData);

  const contributor = contributorProp ?? values?.[name]?.[index];
  const conditionValue = contributor?.condition
    ? JSON.parse(contributor.condition)
    : null;

  return (
    <ListItem component={Paper} variant="outlined">
      <Stack direction="row" width={1} columnGap={4}>
        <Box flexGrow={1}>
          <FormikContributorPicker
            disabled={readOnly}
            required
            name={`${name}.${index}.contributor`}
            emptyUser={schema}
            usersOnly={usersOnly}
            excludeRoleIds={excludeRoleIds}
          />
          {!schema && contributor?.contributor?.type === 2 ? (
            <FormikContributorPicker
              disabled={readOnly}
              PickerProps={{ disableClearable: true }}
              name={`${name}.${index}.assignments`}
              usersOnly
              multiple
              customLabel="Users"
              filterRoleIds={
                contributor.contributor.entity?.id
                  ? [contributor.contributor.entity.id]
                  : []
              }
              excludeRoleIds={excludeRoleIds}
            />
          ) : null}
        </Box>
        <Divider orientation="vertical" flexItem />
        {!hideAccess && (
          <>
            <FormikSelect
              disabled={readOnly}
              FormControlProps={{ sx: { width: 350 } }}
              name={`${name}.${index}.access`}
              required
              label={
                <FormattedMessage id="Workflow.Contributor.Fields.Access.Label" />
              }
            >
              {(readOnly || contributor?.access === 0) && (
                <MenuItem value={0}>
                  <FormattedMessage id="Workflow.Contributor.Fields.Access.0.Label" />
                </MenuItem>
              )}
              <MenuItem value={2}>
                <FormattedMessage id="Workflow.Contributor.Fields.Access.2.Label" />
              </MenuItem>
              <MenuItem value={3}>
                <FormattedMessage id="Workflow.Contributor.Fields.Access.3.Label" />
              </MenuItem>
              <MenuItem value={4}>
                <FormattedMessage id="Workflow.Contributor.Fields.Access.4.Label" />
              </MenuItem>
              <MenuItem value={5}>
                <FormattedMessage id="Workflow.Contributor.Fields.Access.5.Label" />
              </MenuItem>
              {(showSignature || contributor?.access === 1) && (
                <MenuItem value={1}>
                  <FormattedMessage id="Workflow.Contributor.Fields.Access.1.Label" />
                </MenuItem>
              )}
            </FormikSelect>
            <Divider orientation="vertical" flexItem />
          </>
        )}
        <Stack direction="row" alignItems="center" columnGap={2}>
          {!hideCondition && (
            <>
              <Button
                variant="text"
                color="primary"
                fullWidth
                startIcon={conditionValue ? <EditIcon /> : <AddCircleIcon />}
                sx={{ height: 'fit-content' }}
                disabled={readOnly}
                size="small"
                onClick={() => {
                  setOpenPopup(true);
                }}
              >
                <FormattedMessage
                  id={
                    conditionValue
                      ? 'Workflow.Contributor.Buttons.Condition.Edit'
                      : 'Workflow.Contributor.Buttons.Condition.Add'
                  }
                />
              </Button>
              <CustomConfirmDialog
                fullScreen
                openState={{ value: openPopup, update: setOpenPopup }}
                title={
                  <FormattedMessage id="Workflow.Contributor.Buttons.Condition.Edit" />
                }
                confirmText={<FormattedMessage id="Verbs.Validate" />}
                onSubmit={() => {}}
                content={
                  <ContributorConditionEditor
                    conditionState={{
                      value: conditionValue,
                      update: setConditionValue,
                    }}
                    fields={fields}
                  />
                }
              />
            </>
          )}
          {!hideRemove && (
            <DeleteWorkflowContributorButton
              disabled={readOnly}
              onClick={() => {
                if (contributor?.contributor) {
                  setDeleteDialog(true);
                } else {
                  onRemove();
                }
              }}
            />
          )}
        </Stack>
      </Stack>
      {deleteDialog && (
        <WorkflowContributorDeleteDialog
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          onConfirm={() => {
            setDeleteDialog(false);
            onRemove();
          }}
          contributor={contributor?.contributor}
        />
      )}
    </ListItem>
  );
}

export default WorkflowContributorEditor;
