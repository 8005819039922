import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

function MuiCheckbox({
  field,
  form: { touched, errors, setFieldValue },
  disabled,
  ...props
}) {
  const { name } = field;
  const error = Boolean(touched[name]) && Boolean(errors[name]);
  return (
    <FormControl error={error} size="small">
      <FormControlLabel
        value={field.value}
        label={props?.label}
        control={
          <Checkbox
            name={name}
            checked={field.value ?? false}
            disabled={disabled}
            onChange={evt => {
              if (evt.target.checked) {
                setFieldValue(name, true);
              } else {
                setFieldValue(name, false);
              }
            }}
            size="small"
          />
        }
      />
      {error && (
        <FormHelperText>
          <FormattedMessage
            id={errors[field.name]}
            values={{ label: props?.label ?? '', value: field?.value ?? '' }}
          />
        </FormHelperText>
      )}
    </FormControl>
  );
}

function FormikCheckbox(props) {
  return <Field component={MuiCheckbox} {...props} />;
}

export default FormikCheckbox;
