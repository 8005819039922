import { List } from 'react-movable';
import MuiList from '@mui/material/List';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { FieldArray, useField } from 'formik';
import tenantPermissions from '@utils/tenant-permissions';
import withPermissions from '@components/withTenantPermissions';
import { EditTermSheetSchemaValueItemWithPermissions } from './EditTermSheetSchemaValueItem';
import { TermSheetSchemaEditorValue } from './template-term-sheet-schema-values';

function TermSheetSidePanel({
  selectedValueIndex,
  setSelectedValueIndex,
}: {
  selectedValueIndex: number | null;
  setSelectedValueIndex: (index: number | null) => void;
}) {
  const [{ value: values }] = useField<
    TermSheetSchemaEditorValue[] | undefined
  >('values');

  const ButtonWithPermission = withPermissions(Button);

  return (
    <Box
      sx={{
        p: 2,
        bgcolor: 'grey.100',
        borderRight: '1px solid',
        borderColor: 'grey.400',
      }}
    >
      <Stack
        sx={{ overflow: 'auto' }}
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <FieldArray name="values">
          {({ push, move }) => (
            <>
              {!!values && (
                <List
                  values={values}
                  lockVertically
                  onChange={({ oldIndex, newIndex }) =>
                    move(oldIndex, newIndex)
                  }
                  renderList={({ children, props }) => (
                    <MuiList
                      sx={{
                        minHeight: '74vh',
                        maxHeight: '74vh',
                        overflowY: 'auto',
                      }}
                      component="div"
                      disablePadding
                      {...props}
                    >
                      {children}
                    </MuiList>
                  )}
                  renderItem={({ value, props, index }) => (
                    <EditTermSheetSchemaValueItemWithPermissions
                      permissions={[tenantPermissions.TermSheetValuesUpdate]}
                      {...props}
                      value={value}
                      onSelect={() => setSelectedValueIndex(index ?? null)}
                      selected={index === selectedValueIndex}
                    />
                  )}
                />
              )}
              <ButtonWithPermission
                permissions={[tenantPermissions.TermSheetValuesCreate]}
                variant="outlined"
                onClick={() => push({})}
              >
                Ajouter un élément
              </ButtonWithPermission>
            </>
          )}
        </FieldArray>
      </Stack>
    </Box>
  );
}

export const TermSheetSidePanelWithPermissions =
  withPermissions(TermSheetSidePanel);

export default TermSheetSidePanel;
