import React from 'react';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormikProvider from '@components/Formik/FormikProvider';
import FormikTextField from '@components/Formik/FormikTextField';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import { FormattedMessage } from 'react-intl';
import withPermissions from '@components/withTenantPermissions';

const schema = Yup.object().shape({
  name: Yup.string().required('Generic.Fields.Required'),
});

function CreateTemplateFolderModal({ onSubmit, disabled }) {
  return (
    <FormikProvider
      schema={schema}
      initialValues={{ name: '' }}
      onSubmit={onSubmit}
      validateOnBlur={false}
      enableReinitialize
    >
      <DialogWithClose
        titleLabel={<FormattedMessage id="Templates.Create.Popup.Title" />}
        dialogActions={
          <Button type="submit" disabled={disabled}>
            <FormattedMessage id="Template.Folder.Create.Button.Label" />
          </Button>
        }
      >
        <Stack gap={2}>
          <FormikTextField
            required
            name="name"
            label={<FormattedMessage id="Template.Folder.Fields.Name.Label" />}
            color="secondary"
            margin="dense"
            disabled={disabled}
          />
        </Stack>
      </DialogWithClose>
    </FormikProvider>
  );
}

export const CreateTemplateFolderModalWithPermissions = withPermissions(
  CreateTemplateFolderModal
);

export default CreateTemplateFolderModal;
