export type TemplateDetailResponse = {
  id: string;
  name: string;
  folderId: string;
  isPublished: boolean;
  // folder: TemplateFolderResponse | null;
  creatorUserId: string;
  creationTime: string;
  // creatorUser: UserResponse | null;
  lastModifierUserId: string;
  lastModificationTime: string;
  // lastModifierUser: UserResponse | null;
  // content: object | null;
  // workflowSchema: WorkflowSchemaResponse | null;
  termSheetSchema: TermSheetSchemaResponse | null;
  // completionSchema: CompletionSchemaResponse | null;
  // termSheet: TermSheetResponse | null;
};

export type TermSheetSchemaResponse = {
  creationTime: string;
  creatorUserId: string;
  lastModificationTime: string;
  lastModifierUserId: string;
  items: TermSheetSchemaValueResponse[];
};

export type TermSheetSchemaValueResponse = {
  name: string | null;
  icon: string | null;
  elementId: string | null;
  source: number;
  type: number;
  variableIds: string[] | null;
};

export type TermSheetElementsListResponse = {
  termSheetElements: TermSheetElementResponse[];
  total: number;
};

export type TermSheetElementResponse = {
  id: string;
  icon: string;
  isProtected: boolean;
  name: string;
  valueType: string;
};

export enum TermSheetValueType {
  Text = 1,
  Number,
  Boolean,
  Identifier,
  Timestamp,
  CustomObject,
  DateOnly,
  TimeOnly,
}

export enum TermSheetValueSource {
  Free = 1,
  Contract,
}

export const termSheetValueSourceOptions = [
  {
    value: TermSheetValueSource.Contract,
    title: 'Data from the contract (automatic recovery)',
    messageDescriptorId: 'TermSheet.DataSource.Contract.Label',
  },
  {
    value: TermSheetValueSource.Free,
    title: 'Free Data',
    messageDescriptorId: 'TermSheet.DataSource.Free.Label',
  },
] as const;

export const termSheetValueTypeOptions = [
  {
    value: TermSheetValueType.Text,
    title: 'Text',
    messageDescriptorId: 'TermSheet.DataType.Text.Label',
  },
  {
    value: TermSheetValueType.Number,
    title: 'Number',
    messageDescriptorId: 'TermSheet.DataType.Number.Label',
  },
  {
    value: TermSheetValueType.Boolean,
    title: 'Boolean',
    messageDescriptorId: 'TermSheet.DataType.Boolean.Label',
  },
  {
    value: TermSheetValueType.Timestamp,
    title: 'Timestamp',
    messageDescriptorId: 'TermSheet.DataType.Timestamp.Label',
  },
  {
    value: TermSheetValueType.DateOnly,
    title: 'DateOnly',
    messageDescriptorId: 'TermSheet.DataType.DateOnly.Label',
  },
  {
    value: TermSheetValueType.TimeOnly,
    title: 'TimeOnly',
    messageDescriptorId: 'TermSheet.DataType.TimeOnly.Label',
  },
] as const;

export type TemplateVariablesListResponse = {
  variables: ContentNodeResponse[];
  clauses: ContentNodeResponse[];
  clauseVariants: ContentNodeResponse[];
};

export type ContentNodeResponse = {
  type: string;
  attrs: {
    uid: string;
    name: string;
  };
  path?: ContentNodeResponse[];
};

export type NotificationsListResponse = {
  notifications: NotificationResponse[];
  total: number;
};

export type NotificationResponse = {
  id: string;
  type: number;
  data: object;
  seen: boolean;
  publicationDate: string;
};

export type ContractNoteResponse = {
  id: string;
  roleId: string | null;
  title: string;
  contentType: string;
  content: string;
  isPublished: boolean;
  notificationType: number | null;
  creationTime: string;
  creatorUser: UserResponse;
};

export type ContractNotesListResponse = {
  notes: ContractNoteResponse[];
};

export type UserResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  externalId: string;
};
