import { defineMessages } from 'react-intl';

export default defineMessages({
  NexityFoldersAcquirerContactDetails: {
    id: 'Nexity.Folders.AcquirerContactDetails',
    defaultMessage: 'Acquirer contact details',
  },
  NexityFoldersFormsToCompleteLabel: {
    id: 'Nexity.Folders.FormsToComplete.Label',
    defaultMessage: 'Forms to complete',
  },
  NexityFoldersVouchers: {
    id: 'Nexity.Folders.Vouchers',
    defaultMessage: 'Vouchers',
  },
  NexityFoldersFormsToCompleteCivilState: {
    id: 'Nexity.Folders.FormsToComplete.CivilState',
    defaultMessage: 'Civil state',
  },
  NexityFoldersFormsToCompleteFamilySituation: {
    id: 'Nexity.Folders.FormsToComplete.FamilySituation',
    defaultMessage: 'Family situation',
  },
  NexityFoldersFormsToCompleteFinancingInsurance: {
    id: 'Nexity.Folders.FormsToComplete.Financing',
    defaultMessage: 'Financing',
  },
  NexityFoldersFormsToCompleteProject: {
    id: 'Nexity.Folders.FormsToComplete.Project',
    defaultMessage: 'Project',
  },
  NexityFoldersFormsToCompleteStatusToComplete: {
    id: 'Nexity.Folders.FormsToComplete.Status.ToComplete',
    defaultMessage: 'To complete',
  },
  NexityFoldersFormsToCompleteStatusToValidate: {
    id: 'Nexity.Folders.FormsToComplete.Status.ToValidate',
    defaultMessage: 'To be validated',
  },
  NexityFoldersFormsToCompleteStatusToRectify: {
    id: 'Nexity.Folders.FormsToComplete.Status.ToRectify',
    defaultMessage: 'To rectify',
  },
  NexityFoldersFormsToCompleteStatusValidated: {
    id: 'Nexity.Folders.FormsToComplete.Status.Validated',
    defaultMessage: 'Validated',
  },
});
