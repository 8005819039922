import { useOutletContext } from 'react-router-dom';
import MoveForm, { useMoveForm } from '@components/Modals/MoveForm';
import DialogTitleWithClose from '@components/Dialog/DialogTitleWithClose';
import { DialogContent } from '@mui/material';
import DialogActionsWithClose from '@components/Dialog/DialogActionsWithClose';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import Stack from '@mui/material/Stack';
import useFetch from '@hooks/useFetch';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';

function MoveModal({
  url,
  title,
  getDriveUrl,
  folderIdKeyName = 'folderId',
  onSuccess,
  basePath,
}) {
  const { close } = useOutletContext();
  const moveForm = useMoveForm({ getDriveUrl, basePath });
  const { folderId: targetFolderId } = moveForm;

  const moveFetch = useFetch({
    method: 'POST',
    url,
    onSuccess: () => {
      onSuccess();
      close();
    },
  });

  const handleSubmit = () => {
    moveFetch.doFetch({ body: { [folderIdKeyName]: targetFolderId } });
  };

  return (
    <>
      <DialogTitleWithClose onClose={close}>
        <Typography sx={{ fontWeight: 700 }}>{title}</Typography>
      </DialogTitleWithClose>
      <DialogContent>
        <ErrorMessage error={moveFetch.error} sx={{ mb: 2 }} />
        <Stack justifyContent="center" alignItems="center">
          <Typography color="GrayText" sx={{ fontSize: '14px' }}>
            <FormattedMessage
              id="MoveModal.Description.Text"
              defaultMessage="Sélectionnez un nouvel emplacement"
            />
          </Typography>
        </Stack>
        <MoveForm moveForm={moveForm} />
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={moveFetch.fetching}
        >
          <FormattedMessage
            id="MoveModal.Buttons.Confirm.Label"
            defaultMessage="Déplacer ici"
          />
        </Button>
      </DialogActionsWithClose>
    </>
  );
}

export default MoveModal;
