import admin from './admin';
import authentication from './authentication';
import campaigns from './campaigns';
import completion from './completion';
import components from './components';
import contracts from './contracts';
import dashboard from './dashboard';
import departments from './departments';
import document from './document';
import drive from './drive';
import editor from './editor';
import errors from './errors';
import expertises from './expertises';
import generic from './generic';
import header from './header';
import helpcenter from './help-center';
import homepage from './homepage';
import integrations from './integrations';
import nexity from './nexity';
import packages from './contractual-pack';
import pickers from './pickers';
import referentials from './referentials';
import suppliers from './suppliers';
import teams from './teams';
import templates from './templates';
import tenants from './tenants';
import termSheets from './term-sheets';
import userAccounts from './user-accounts';
import verbs from './verbs';
import workflows from './workflows';

const descriptors = {
  admin,
  authentication,
  campaigns,
  completion,
  components,
  contracts,
  dashboard,
  departments,
  document,
  drive,
  editor,
  errors,
  expertises,
  generic,
  header,
  helpcenter,
  homepage,
  integrations,
  nexity,
  packages,
  pickers,
  referentials,
  suppliers,
  teams,
  templates,
  tenants,
  termSheets,
  userAccounts,
  verbs,
  workflows,
};

export default descriptors;
