import React from 'react';
import HostAuthenticatedRoute from '@components/ProtectedRoutes/HostAuthenticatedRoute';
import messages from '@i18n/keys';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AuthorizeForm from '@views/SigninView/AuthorizeForm';
import { FormattedMessage } from 'react-intl';
import { Navigate, Route, Routes, useResolvedPath } from 'react-router-dom';
import artchipel from '../../assets/artchipel.jpg';
import logoBig from '../../assets/logo-big.png';
import SigninForm from './SigninForm';

const BackgroundImage = styled(Box)`
  background-image: url(${artchipel});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100%;
  min-width: 100%;
`;

const LogoImage = styled('img')`
  width: 130px;
  aspect-ratio: 1;
  align-self: center;
  margin-top: -110px;
`;

export function SigninLayout({ children, hideWelcome }) {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '120px 1fr 120px',
      }}
    >
      <BackgroundImage sx={{ gridColumn: 1, gridRow: 2 }} />
      <Stack
        sx={{
          gridRow: 2,
          gridColumn: 1,
          bgcolor: 'background.paper',
          width: '500px',
          p: 4,
          alignSelf: 'center',
          justifySelf: 'center',
        }}
      >
        <LogoImage src={logoBig} alt="logo" />
        {!hideWelcome && (
          <Typography>
            <FormattedMessage id={messages.homepage.welcome.message} />
          </Typography>
        )}
        {children}
      </Stack>
    </Box>
  );
}

function SigninView({ firstConnectionUrl }) {
  const signinUrl = useResolvedPath('.').pathname;
  return (
    <SigninLayout>
      <Routes>
        <Route
          index
          element={<SigninForm firstConnectionUrl={firstConnectionUrl} />}
        />
        <Route path="authorize/*" element={<HostAuthenticatedRoute />}>
          <Route
            index
            element={
              <AuthorizeForm
                backTo={signinUrl}
                tenantClient="nexityavenanttravaux"
              />
            }
          />
          <Route
            path="*"
            element={<Navigate to="/signin/authorize" replace />}
          />
        </Route>
        <Route path="*" element={<Navigate to="/signin" replace />} />
      </Routes>
    </SigninLayout>
  );
}

export default SigninView;
