export const sortModes = {
  default: 'default',
  nameAtoZ: 'name-a-to-z',
  nameZtoA: 'name-z-to-a',
  ownerNameAtoZ: 'owner-name-a-to-z',
  ownerNameZtoA: 'owner-name-z-to-a',
  statusAsc: 'status-asc',
  statusDesc: 'status-desc',
  dateModifiedNewestFirst: 'date-modified-newest-first',
  dateModifiedOldestFirst: 'date-modified-oldest-first',
  apiNameAsc: 'name_asc',
  apiNameDesc: 'name_desc',
  apiOwnerNameAsc: 'owner-name_asc',
  apiOwnerNameDesc: 'owner-name_desc',
  apiStatusAsc: 'status_asc',
  apiStatusDesc: 'status_desc',
  apiDateModifiedAsc: 'date-modified_asc',
  apiDateModifiedDesc: 'date-modified_desc',
} as const;

export const filterDocumentStatut = [
  'En négociation',
  'Terminé',
  'Signature',
  'Signé',
  'Rejeté',
];

export const roleTypeEnum = {
  Role: 1,
  Group: 2,
};

export const systemRolesEnum = {
  Internal: 'Internal',
  External: 'External',
  Administrator: 'Administrators',
};
