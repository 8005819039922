import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import withPermissions from '@components/withTenantPermissions';
import tenantPermissions from '@utils/tenant-permissions';
import { EditTermSheetSchemaValueFormWithPermissions } from './EditTermSheetSchemaValueForm';

function ManageTermSheetSchemaValue({
  templateId,
  selectedValueIndex,
  error,
}: {
  templateId: string;
  selectedValueIndex: number | null;
  error?: unknown;
}) {
  return (
    <Stack sx={{ mt: 2 }}>
      <ErrorMessage error={error} sx={{ mt: 2 }} />
      <Alert severity="info" sx={{ my: 2 }}>
        <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
          <FormattedMessage id="TermSheet.Schema.Editor.Description" />
        </Typography>
      </Alert>
      <EditTermSheetSchemaValueFormWithPermissions
        permissions={[tenantPermissions.TermSheetValuesDetail]}
        templateId={templateId}
        selectedValueIndex={selectedValueIndex}
      />
    </Stack>
  );
}

export const ManageTermSheetSchemaValueWithPermissions = withPermissions(
  ManageTermSheetSchemaValue
);

export default ManageTermSheetSchemaValue;
