import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { FormattedMessage } from 'react-intl';
import withPermissions from '@components/withTenantPermissions';
import BorderedIcon from '@views/MangeData/components/BorderedIcon';
import FormikSelect from '@components/Formik/FormikSelect';
import { useField } from 'formik';
import FormikTermSheetElementSelect from '@components/Formik/FormikTermSheetElementSelect';
import {
  TermSheetValueSource,
  termSheetValueSourceOptions,
  termSheetValueTypeOptions,
} from '@utils/api/types';
import { arrayRemove } from 'react-movable';
import FormikTemplateVariableSelect from '@components/Formik/FormikTemplateVariableSelect';
import { TermSheetSchemaEditorValue } from './template-term-sheet-schema-values';

function EditTermSheetSchemaValueForm({
  templateId,
  selectedValueIndex,
}: {
  templateId: string;
  selectedValueIndex: number | null;
}) {
  const fieldName = `values.${selectedValueIndex}`;
  const [{ value: values }, , { setValue: setValues }] =
    useField<TermSheetSchemaEditorValue[]>('values');
  const [{ value }, , { setValue }] =
    useField<TermSheetSchemaEditorValue>(fieldName);

  const variableIds = value?.variableIds;
  const setVariableIds = (newVariableIds: (string | null)[]) => {
    setValue({ ...value, variableIds: newVariableIds });
  };

  return (
    <Box>
      {!!value && (
        <>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{
                width: '100%',
                border: '1px solid',
                borderColor: 'grey.200',
                borderRadius: '6px',
                p: '16px',
                my: 2,
              }}
            >
              <Typography
                variant="h3"
                sx={{ width: '260px', color: '#3D3935', fontSize: '13px' }}
              >
                <FormattedMessage id="TermSheet.SchemaValue.Fields.ElementTitle.Label" />
              </Typography>
              <FormikTermSheetElementSelect
                name={`${fieldName}.element`}
                onChange={(event, newElement) =>
                  setValue({
                    ...value,
                    element: newElement,
                    elementId: newElement?.id ?? null,
                    name: newElement?.name ?? null,
                    icon: newElement?.icon ?? null,
                  })
                }
              />
            </Stack>
            <Button
              onClick={() =>
                values?.length &&
                selectedValueIndex !== undefined &&
                selectedValueIndex !== null &&
                setValues(arrayRemove(values, selectedValueIndex))
              }
              startIcon={<DeleteIcon />}
              variant="text"
              color="error"
              sx={{ width: '250px' }}
            >
              <FormattedMessage id="TermSheet.SchemaValue.Button.Delete.Label" />
            </Button>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap={3}
            sx={{
              width: '100%',
              border: '1px solid',
              borderColor: 'grey.200',
              borderRadius: '6px',
              p: '16px',
              my: 2,
            }}
          >
            <Typography
              variant="h3"
              sx={{ width: '260px', color: '#3D3935', fontSize: '13px' }}
            >
              <FormattedMessage id="TermSheet.SchemaValue.Fields.Icon.Label" />
            </Typography>
            <BorderedIcon
              name={`${fieldName}.icon`}
              disabled={Boolean(value?.element)}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={3}
            sx={{
              width: '100%',
              border: '1px solid',
              borderColor: 'grey.200',
              borderRadius: '6px',
              p: '16px',
              my: 2,
            }}
          >
            <Typography
              variant="h3"
              sx={{ width: '260px', color: '#3D3935', fontSize: '13px' }}
            >
              <FormattedMessage id="TermSheet.SchemaValue.Fields.Source.Label" />
            </Typography>
            <FormikSelect
              sx={{ maxWidth: 1 / 2 }}
              name={`${fieldName}.source`}
              defaultValue="none"
            >
              {termSheetValueSourceOptions.map(source => (
                <MenuItem key={source.value} value={source.value}>
                  <FormattedMessage id={source.messageDescriptorId} />
                </MenuItem>
              ))}
            </FormikSelect>
          </Stack>
          {value.source === TermSheetValueSource.Contract && (
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              sx={{
                width: '100%',
                border: '1px solid',
                borderColor: 'grey.200',
                borderRadius: '6px',
                p: '16px',
                my: 2,
              }}
            >
              <Typography
                variant="h3"
                sx={{ width: '260px', color: '#3D3935', fontSize: '13px' }}
              >
                <FormattedMessage id="TermSheet.SchemaValue.Fields.DataToBeCollected.Label" />
              </Typography>
              <Stack sx={{ width: '100%' }} spacing={2}>
                {variableIds?.map((_, index) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    gap={1}
                  >
                    <FormikTemplateVariableSelect
                      templateId={templateId}
                      name={`${fieldName}.variableIds.${index}`}
                      sx={{ maxWidth: 1 / 3 }}
                      size="small"
                    />
                    <IconButton>
                      <DeleteIcon sx={{ color: 'danger.main' }} color="error" />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
              <Button
                sx={{ minWidth: 'max-content' }}
                startIcon={<AddIcon />}
                variant="text"
                color="inherit"
                onClick={() =>
                  setVariableIds(variableIds ? [...variableIds, null] : [null])
                }
              >
                <FormattedMessage id="TermSheet.SchemaValue.Button.AddDataToBeCollected" />
              </Button>
            </Stack>
          )}
          {value.source === TermSheetValueSource.Free && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{
                width: '100%',
                border: '1px solid',
                borderColor: 'grey.200',
                borderRadius: '6px',
                p: '16px',
                my: 2,
              }}
            >
              <Typography
                variant="h3"
                sx={{ width: '260px', color: '#3D3935', fontSize: '13px' }}
              >
                <FormattedMessage id="TermSheet.SchemaValue.Fields.Type.Label" />
              </Typography>
              <FormikSelect sx={{ maxWidth: 1 / 3 }} name={`${fieldName}.type`}>
                {termSheetValueTypeOptions.map(type => (
                  <MenuItem key={type.value} value={type.value}>
                    <FormattedMessage id={type.messageDescriptorId} />
                  </MenuItem>
                ))}
              </FormikSelect>
            </Stack>
          )}
        </>
      )}
    </Box>
  );
}

export const EditTermSheetSchemaValueFormWithPermissions = withPermissions(
  EditTermSheetSchemaValueForm
);

export default EditTermSheetSchemaValueForm;
