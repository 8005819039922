import React, { ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '@mui/material/Alert';

type ErrorMessageProps = {
  error: any;
  mapper?: (error: any) => any;
} & ComponentProps<typeof Alert>;

function ErrorMessage({
  error,
  mapper = x => x,
  sx = null,
  severity = 'error',
  children = null,
}: ErrorMessageProps) {
  if (typeof error === 'boolean') {
    return null;
  }

  if (typeof error === 'string') {
    return (
      <Alert severity={severity} sx={sx}>
        <FormattedMessage id={`Errors.${error}`} />
        {children}
      </Alert>
    );
  }

  if (error instanceof Error) {
    const id =
      error.message === 'Failed to fetch'
        ? `Errors.FailedToFetch`
        : `Errors.${error.message}`;
    return (
      <Alert severity={severity} sx={sx}>
        <FormattedMessage id={id} />
        {children}
      </Alert>
    );
  }

  if (error instanceof Response) {
    const data = 'data' in error ? (error.data as any) : null;
    const errors = data?.Errors ?? data?.errors;
    if (errors) {
      return (
        <Alert severity={severity} sx={sx}>
          {Object.values(errors)
            .flat()
            .map(mapper)
            .map(key => (
              <FormattedMessage
                tagName="div"
                key={key}
                id={`Errors.${typeof key === 'object' ? key.message : key}`}
              />
            ))}
          {children}
        </Alert>
      );
    }

    const message = data?.Message ?? data?.message;
    if (message) {
      return (
        <Alert severity={severity} sx={sx}>
          {message}
          {children}
        </Alert>
      );
    }

    const title = data?.Title ?? data?.title;
    if (title) {
      return (
        <Alert severity={severity} sx={sx}>
          {title}
          {children}
        </Alert>
      );
    }

    const { status } = error;
    if (status === 403) {
      return (
        <Alert severity={severity} sx={sx}>
          <FormattedMessage id="Errors.Forbidden" />
          {children}
        </Alert>
      );
    }

    return (
      <Alert severity={severity} sx={sx}>
        <FormattedMessage id="Errors.FailedToFetch" />
        {children}
      </Alert>
    );
  }

  return null;
}

export default ErrorMessage;
