import { defineMessages } from 'react-intl';

export default defineMessages({
  DriveViewTrashLabel: {
    id: 'DriveView.Trash.Label',
    defaultMessage: 'Trash',
  },
  DriveViewButtonsNewLabel: {
    id: 'DriveView.Buttons.New.Label',
    defaultMessage: 'New',
  },
  DriveViewColumnsNameText: {
    id: 'DriveView.Columns.Name.Text',
    defaultMessage: 'Name',
  },
  DriveViewColumnsStatusText: {
    id: 'DriveView.Columns.Status.Text',
    defaultMessage: 'Status',
  },
  DriveViewColumnsOwnerText: {
    id: 'DriveView.Columns.Owner.Text',
    defaultMessage: 'Owner name',
  },
  DriveViewColumnsLastModificationText: {
    id: 'DriveView.Columns.LastModification.Text',
    defaultMessage: 'Last modification',
  },
  DriveViewDialogsCreateFolderFieldsNameLabel: {
    id: 'DriveView.Dialogs.CreateFolder.Fields.Name.Label',
    defaultMessage: 'Name',
  },
  DriveViewDialogsCreateFolderFieldsIsFrozenLabel: {
    id: 'DriveView.Dialogs.CreateFolder.Fields.IsFrozen.Label',
    defaultMessage: 'This folder (name and location) is frozen',
  },
  DriveViewDialogsCreateFolderFieldsIsContentFrozenLabel: {
    id: 'DriveView.Dialogs.CreateFolder.Fields.IsContentFrozen.Label',
    defaultMessage: 'The content of this folder is frozen',
  },
  DriveViewDialogsCreateFolderFieldsIsConfidentialLabel: {
    id: 'DriveView.Dialogs.CreateFolder.Fields.IsConfidential.Label',
    defaultMessage: 'This folder is confidential',
  },
  DriveViewDialogsCreateFolderFieldsIsConfidentialTooltip: {
    id: 'DriveView.Dialogs.CreateFolder.Fields.IsConfidential.Tooltip',
    defaultMessage:
      'A confidential folder is only visible to the users to whom it has been shared.',
  },
  DriveViewButtonsAddDocumentLabel: {
    id: 'DriveView.Buttons.AddDocument.Label',
    defaultMessage: 'Document',
  },
  DriveViewButtonsDuplicateLabel: {
    id: 'DriveView.Buttons.Duplicate.Label',
    defaultMessage: 'Create a copy',
  },
  DriveViewButtonsAddFolderLabel: {
    id: 'DriveView.Buttons.AddFolder.Label',
    defaultMessage: 'Folder',
  },
  DriveViewAddDocumentButtonsBlankLabel: {
    id: 'DriveView.AddDocument.Buttons.Blank.Label',
    defaultMessage: 'From a template',
  },
  DriveViewAddDocumentButtonsImportLabel: {
    id: 'DriveView.AddDocument.Buttons.Import.Label',
    defaultMessage: 'Import a document',
  },
  DriveViewAddFolderButtonsNewLabel: {
    id: 'DriveView.AddFolder.Buttons.New.Label',
    defaultMessage: 'New folder',
  },
  DriveViewAddFolderButtonsImportLabel: {
    id: 'DriveView.AddFolder.Buttons.Import.Label',
    defaultMessage: 'Import a folder',
  },
  DriveViewDialogsNewFolderTitle: {
    id: 'DriveView.Dialogs.NewFolder.Title',
    defaultMessage: 'New folder',
  },
  DriveViewDialogsImportFolderTitle: {
    id: 'DriveView.Dialogs.ImportFolder.Title',
    defaultMessage: 'Import a folder',
  },
  DriveViewDialogsNewTermSheetElementTitle: {
    id: 'DriveView.Dialogs.NewTermSheetElement.Title',
    defaultMessage: 'Add an element in the term sheet',
  },
  DriveViewDialogsEditTermSheetElementTitle: {
    id: 'DriveView.Dialogs.EditTermSheetElement.Title',
    defaultMessage: 'Update an element in the term sheet',
  },
  DriveViewDialogsImportFolderFieldsFileLabel: {
    id: 'DriveView.Dialogs.ImportFolder.Fields.File.Label',
    defaultMessage: 'Folder to import',
  },
  DriveViewDialogsDeleteDocumentTitle: {
    id: 'DriveView.Dialogs.DeleteDocument.Title',
    defaultMessage: 'Delete a document',
  },
  DriveViewDialogsDeleteDocumentWarning: {
    id: 'DriveView.Dialogs.DeleteDocument.Warning',
    defaultMessage: 'Attention',
  },
  DriveViewDialogsDeleteDocumentExplanation: {
    id: 'DriveView.Dialogs.DeleteDocument.Explanation',
    defaultMessage:
      'Are you sure you want to delete this document? It will be placed in the trash.',
  },
  DriveViewDialogsDeleteDocumentFolderTitle: {
    id: 'DriveView.Dialogs.DeleteDocumentFolder.Title',
    defaultMessage: 'Delete a folder',
  },
  DriveViewDialogsDeleteDocumentFolderWarning: {
    id: 'DriveView.Dialogs.DeleteDocumentFolder.Warning',
    defaultMessage: 'Attention',
  },
  DriveViewDialogsDeleteDocumentFolderExplanation: {
    id: 'DriveView.Dialogs.DeleteDocumentFolder.Explanation',
    defaultMessage:
      'Are you sure you want to delete this folder? It will be placed in the trash.',
  },
  DriveviewTermSheetButtonsAddLabel: {
    id: 'Driveview.TermSheet.Buttons.Add.Label',
    defaultMessage: 'Add an element',
  },
  DriveviewTermSheetButtonsExportLabel: {
    id: 'Driveview.TermSheet.Buttons.Export.Label',
    defaultMessage: 'Export',
  },
  DriveViewDialogsDeleteTermSheetElementTitle: {
    id: 'Driveview.Dialogs.DeleteTermSheetElement.Title',
    defaultMessage: 'Delete an element from the term sheet',
  },
  DriveViewDeleteTermSheetElementButtonsDeleteLabel: {
    id: 'DriveView.DeleteTermSheetElement.Buttons.Delete.Label',
    defaultMessage: 'Delete',
  },
  DriveViewDeleteTermSheetElementDescriptionText: {
    id: 'DriveView.DeleteTermSheetElement.Description.Text',
    defaultMessage: 'Are you sure you want to delete this term sheet element ?',
  },
  DriveViewSearchExportResultsTooltip: {
    id: 'DriveView.Search.ExportResults.Tooltip',
    defaultMessage: 'Export results to csv',
  },
  DriveViewSearchExportResultsError: {
    id: 'DriveView.Search.ExportResults.Error',
    defaultMessage: 'An error occured while exporting results to a csv file.',
  },
});
