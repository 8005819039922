import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import DialogTitleWithClose from './DialogTitleWithClose';

function ConfirmDialog({
  open,
  onClose,
  titleComponent = null,
  actionComponent = null,
  closeLabel = <FormattedMessage id="Verbs.Cancel" />,
  children,
  ...rest
}) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" {...rest}>
      {titleComponent && (
        <DialogTitleWithClose onClose={onClose}>
          {titleComponent}
        </DialogTitleWithClose>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="error">
          {closeLabel}
        </Button>
        {actionComponent}
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  titleComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actionComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  closeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

ConfirmDialog.defaultProps = {
  titleComponent: null,
  actionComponent: null,
  closeLabel: <FormattedMessage id="Verbs.Cancel" />,
};

export default ConfirmDialog;
