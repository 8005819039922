import { defineMessages } from 'react-intl';

export default defineMessages({
  actions: {
    id: 'Referentials.Actions',
    defaultMessage: 'Actions',
  },
  addElement: {
    id: 'Referentials.AddElement',
    defaultMessage: 'Add an element',
  },
  createReferential: {
    id: 'Referentials.CreateReferential',
    defaultMessage: 'Create a new referential',
  },
  dataKey: {
    id: 'Referentials.DataKey',
    defaultMessage: 'Key',
  },
  dataValue: {
    id: 'Referentials.DataValue',
    defaultMessage: 'Value',
  },
  deleteReferential: {
    id: 'Referentials.DeleteReferential',
    defaultMessage: 'Delete this referential',
  },
  deleteElement: {
    id: 'Referentials.DeleteElement',
    defaultMessage: 'Delete this element',
  },
  elementCount: {
    id: 'Referentials.ElementCount',
    defaultMessage: 'Element number',
  },
  file: {
    id: 'Referentials.File',
    defaultMessage: 'File',
  },
  name: {
    id: 'Referentials.Name',
    defaultMessage: 'Name',
  },
  noElementFound: {
    id: 'Referentials.NoElementFound',
    defaultMessage: 'No element found.',
  },
  noReferentialFound: {
    id: 'Referentials.NoReferentialFound',
    defaultMessage: 'No referential was found.',
  },
  seeReferential: {
    id: 'Referentials.SeeReferential',
    defaultMessage: 'Referential detail',
  },
  updateReferential: {
    id: 'Referentials.UpdateReferential',
    defaultMessage: 'Update referential',
  },
  updateElement: {
    id: 'Referentials.UpdateElement',
    defaultMessage: 'Update element',
  },
  ReferentialsViewTableHeadersNameLabel: {
    id: 'ReferentialsView.TableHeaders.Name.Label',
    defaultMessage: 'Name',
  },
  ReferentialsViewTableHeadersCountLabel: {
    id: 'ReferentialsView.TableHeaders.Count.Label',
    defaultMessage: 'Count',
  },
  ReferentialsViewTableHeadersProviderLabel: {
    id: 'ReferentialsView.TableHeaders.Provider.Label',
    defaultMessage: 'Provider',
  },
  ReferentialsViewTableHeadersActionsLabel: {
    id: 'ReferentialsView.TableHeaders.Actions.Label',
    defaultMessage: 'Actions',
  },
  ReferentialsViewFieldsName: {
    id: 'ReferentialsView.Fields.Name',
    defaultMessage: 'Name of your referential',
  },
  ReferentialsViewFieldsKeyFieldName: {
    id: 'ReferentialsView.Fields.KeyFieldName',
    defaultMessage: 'Referential column name to display',
  },
  ReferentialsViewFieldsDocument: {
    id: 'ReferentialsView.Fields.Document',
    defaultMessage: 'Referential document to import',
  },
});
