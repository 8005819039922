import React, { PropsWithChildren, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';

import Breadcrumbs, { Breadcrumb } from './Breadcrumbs';

type BreadcrumbsLocationState = {
  breadcrumbs?: Breadcrumb[] | null;
  renderChildren?(): ReactElement;
};

function BreadcrumbsContainer({
  breadcrumbs: breadcrumbsProp = null,
  children = null,
}: PropsWithChildren<{ breadcrumbs?: Breadcrumb[] | null }>) {
  const location = useLocation();
  const locationState = location.state as BreadcrumbsLocationState | null;
  const breadcrumbs = breadcrumbsProp ?? locationState?.breadcrumbs ?? [];
  const show = Boolean(breadcrumbs?.length || locationState?.renderChildren);
  return show ? (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{
        bgcolor: 'grey.100',
        borderBottom: '1px solid',
        borderColor: 'grey.400',
        py: 2,
        px: 4,
        minHeight: 57,
      }}
    >
      <Grid item>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid item>
        {locationState?.renderChildren
          ? locationState?.renderChildren()
          : children}
      </Grid>
    </Grid>
  ) : (
    <Box />
  );
}

export default BreadcrumbsContainer;
