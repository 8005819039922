import { Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import useFetch from '@hooks/useFetch';
import withPermissions from '@components/withTenantPermissions';
import DangerModalTitleLabel from './DangerModalTitleLabel';

function DeleteTemplateFolderModal({ onSuccess }) {
  const { id } = useParams();

  const deleteFetch = useFetch({
    method: 'DELETE',
    url: `templates/folders/${id}`,
    onSuccess,
  });

  const handleSubmit = () => {
    deleteFetch.doFetch();
  };

  return (
    <DialogWithClose
      titleLabel={
        <DangerModalTitleLabel>SUPPRIMER CE DOSSIER</DangerModalTitleLabel>
      }
      dialogActions={
        <Button onClick={handleSubmit} disabled={deleteFetch.fetching}>
          <FormattedMessage id="Verbs.Delete" />
        </Button>
      }
    >
      <Box sx={{ textAlign: 'center' }}>
        <Typography sx={{ mx: 4, mt: 2, mb: 3, fontWeight: 500 }} variant="h4">
          Attention
        </Typography>
        <Typography sx={{ fontWeight: 500 }}>
          Vous êtes sur le point de supprimer un dossier. Etes-vous sûr de
          vouloir continuer ?
        </Typography>
      </Box>
    </DialogWithClose>
  );
}

export const DeleteTemplateFolderModalWithPermissions = withPermissions(
  DeleteTemplateFolderModal
);

export default DeleteTemplateFolderModal;
