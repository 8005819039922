import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import useFetch from '@hooks/useFetch';
import { Typography } from '@mui/material';
import { useWorkflowContext } from '@components/WorkflowGeneric/WorkflowContextProvider';
import NavigationBlocker from '@components/Alerts/NavigationBlocker';

function ContractWorkflowHeader({ id: contractId, backUrl }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const {
    dirty,
    valid,
    methods: { undirtify, mapForApi },
  } = useWorkflowContext();
  const { enqueueSnackbar } = useSnackbar();
  const { doFetch: doPut } = useFetch({
    url: `/contracts/${contractId}/workflow`,
    method: 'PUT',
    cachePolicy: 'network-only',
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: 'Workflow.Settings.Save.Success' }), {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      undirtify();
    },
  });
  const onSaveCallback = React.useCallback(() => {
    doPut({ body: mapForApi() });
  }, [mapForApi, doPut]);
  return (
    <AppBar position="relative" elevation={0} color="transparent">
      <Toolbar variant="dense" disableGutters sx={{ mr: 2, px: 4 }}>
        <Stack
          sx={{ width: '100%' }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={9}
          >
            <NavigationBlocker
              message="Voulez-vous vraiment quitter cette page sans enregistrer les modifications ?"
              unsavedData={dirty}
            />
            <Button
              disableElevation
              disableFocusRipple
              sx={{ minWidth: '300px', alignSelf: 'flex-start' }}
              onClick={() =>
                navigate(location?.state?.backUrl ?? backUrl ?? '..')
              }
              variant="text"
              startIcon={<ChevronLeft />}
              color="primary"
            >
              <FormattedMessage id="Workflow.Step.Buttons.Back.Label" />
            </Button>
            <Typography sx={{ fontWeight: 700 }}>
              <FormattedMessage id="WorkFlow.Step.Edit.Title" />
            </Typography>
          </Stack>
          <Button
            variant="outlined"
            disabled={!dirty || !valid}
            onClick={onSaveCallback}
          >
            <FormattedMessage id="Verbs.Save" />
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default ContractWorkflowHeader;
