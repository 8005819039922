import { compute } from '@utils/username-helpers';

export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

export const label = ({ type, entity }, formatMessage) => {
  if (type === 1 && entity?.id === GUID_EMPTY) {
    return formatMessage({ id: 'Workflow.Step.Author.Label' });
  }
  if (type === 1) {
    return (
      compute(
        entity?.email,
        entity?.firstName,
        entity?.lastName,
        entity?.externalId
      ).value || entity?.id
    );
  }
  if (type === 2) {
    return entity?.name || entity?.id;
  }
  return '';
};
