export const getDateTimezoneIndependantFromTimestamp = timestamp => {
  if (timestamp === undefined || timestamp === null) return null;
  const d = new Date(timestamp);
  return new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
};

export const getTimestampFromDateTimezoneIndependant = timestamp => {
  if (timestamp === undefined || timestamp === null) return null;
  return timestamp.valueOf() - timestamp.getTimezoneOffset() * 60 * 1000;
};

export const getTimeFromDateTimezoneIndependant = timestamp => {
  const d = getTimestampFromDateTimezoneIndependant(timestamp);
  const date = new Date(parseInt(d, 10));
  return (
    date.getUTCHours() * 3600000 +
    date.getUTCMinutes() * 60000 +
    date.getUTCSeconds() * 1000
  );
};
