import { Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import useFetch from '@hooks/useFetch';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import withPermissions from '@components/withTenantPermissions';

function RestoreTemplateFolderModal({ onSuccess }) {
  const { id } = useParams();

  const restoreFetch = useFetch({
    method: 'POST',
    url: `/templates/folders/${id}/restore`,
    onSuccess,
  });

  const handleSubmit = () => {
    restoreFetch.doFetch();
  };

  return (
    <DialogWithClose
      titleLabel="RESTAURER CE DOSSIER"
      dialogActions={
        <Button onClick={handleSubmit} disabled={restoreFetch.fetching}>
          <FormattedMessage id="Verbs.Restore" />
        </Button>
      }
    >
      <Box sx={{ textAlign: 'center' }}>
        <ErrorMessage error={restoreFetch.error} sx={{ mb: 2 }} />
        <Typography sx={{ fontWeight: 500 }}>
          Vous êtes sur le point de restaurer un dossier. Etes-vous sûr de
          vouloir continuer ?
        </Typography>
      </Box>
    </DialogWithClose>
  );
}

export const RestoreTemplateFolderModalWithPermissions = withPermissions(
  RestoreTemplateFolderModal
);

export default RestoreTemplateFolderModal;
