import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FourKIcon from '@mui/icons-material/FourK';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import HistoryIcon from '@mui/icons-material/History';
import PanToolIcon from '@mui/icons-material/PanTool';

const STATUSES = [
  {
    color: 'chipSuccess',
    text: 'Recu',
    icon: CheckCircleIcon,
  },
  {
    color: 'chipWarning',
    text: 'En Attente',
    icon: AccessTimeFilledIcon,
  },
  {
    color: 'chipError',
    text: 'Completion',
    icon: AccessTimeFilledIcon,
  },
  {
    color: 'chipWarning',
    text: 'Negociation',
    icon: CheckCircleIcon,
  },
  {
    color: 'chipError',
    text: 'MANQUANT',
    icon: AccessTimeFilledIcon,
  },
  {
    color: 'chipSuccess',
    text: 'Publié',
    icon: CheckCircleIcon,
  },
  {
    color: 'chipDefault',
    text: 'Brouillon',
    icon: FourKIcon,
  },
  {
    color: 'chipPending',
    text: 'En attente de signature',
    icon: CheckCircleIcon,
  },
  {
    color: 'chipSuccess',
    text: 'Signé',
    icon: CheckCircleIcon,
  },
  {
    color: 'chipSuccess',
    text: 'Validated',
    icon: CheckCircleIcon,
  },
  {
    color: 'chipPending',
    text: 'Current step',
    icon: HistoryIcon,
  },
  {
    color: 'chipError',
    text: 'Rejeté',
    icon: PanToolIcon,
  },
  {
    color: 'chipSuccess',
    text: 'Terminé',
    icon: CheckCircleIcon,
  },
];

// eslint-disable-next-line arrow-body-style
const StyledChip = styled(Chip)(({ theme, color }) => {
  return {
    color: theme.palette[color].contrastText,
    borderColor: theme.palette[color].main,
    padding: '3px',
    height: 'auto',
    borderRadius: '13px',
    fontSize: '9px',
    textTransform: 'uppercase',
    fontWeight: 700,
    marginTop: 0,
  };
});

export default function StatusChip({ status, ...rest }) {
  const chipStatus = STATUSES[status - 1];
  if (!chipStatus) return null;
  return (
    <StyledChip
      color={chipStatus.color}
      icon={<chipStatus.icon sx={{ fontSize: '13px' }} />}
      label={chipStatus.text}
      {...rest}
    />
  );
}

StatusChip.propTypes = {
  status: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13])
    .isRequired,
};
