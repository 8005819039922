import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import artchipel from '@shells/nexity-avenant-travaux/assets/artchipel.jpg';
import logo from '@shells/nexity-avenant-travaux/assets/logo-big.png';

const UnsplashImage = styled(Box)`
  background-image: url(${artchipel});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100%;
  min-width: 100%;
`;

function FirstConnectionView({ backgroundImage }) {
  const [searchParams] = useSearchParams();
  const redirectToParams = searchParams.get('redirectTo') || '';
  const navigate = useNavigate();
  const fontSize = '13px';

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100vh' }} gap={1}>
      <UnsplashImage
        background={
          backgroundImage ?? 'https://source.unsplash.com/featured/?law'
        }
      />
      <Paper
        sx={{
          width: '635px',
          borderRadius: '4px',
          position: 'absolute',
          top: '45%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '133px',
            height: '133px',
            zIndex: 2,
          }}
        >
          <img
            src={logo}
            alt=""
            style={{
              width: '100%',
              height: '100%',
              object: 'contain',
              borderRadius: '50%',
            }}
          />
        </Box>
        <Box
          sx={{
            p: 4,
            fontSize: '13px',
          }}
          gap={1}
        >
          <Stack sx={{ pt: '30px' }}>
            <Typography sx={{ fontWeight: 700, fontSize: '16px', my: 1 }}>
              <FormattedMessage
                id="FirstConnection.View.Title"
                defaultMessage="Bienvenue !"
              />
            </Typography>
            <Box sx={{ ml: 0.5 }}>
              <Typography sx={{ fontSize, mb: 1 }}>
                <FormattedMessage
                  id="FirstConnection.View.FreeFields"
                  defaultMessage="Notre solution propose des zones de champs libres pour vous exprimer et rendre compte de votre activité."
                />
              </Typography>
              <Typography sx={{ fontSize }}>
                <FormattedMessage
                  id="FirstConnection.View.Privacy"
                  defaultMessage="En application de la règlementation relative à la protection des
              données personnelles et de la vie privée,"
                />
              </Typography>
              <Typography sx={{ fontSize }}>
                <FormattedMessage
                  id="FirstConnection.View.Invite"
                  defaultMessage="nous vous invitons :"
                />
              </Typography>
              <ListItem sx={{ display: 'list-item', fontSize, p: 0, pl: 1 }}>
                <FormattedMessage
                  id="FirstConnection.View.SensitiveData"
                  defaultMessage=" à ne pas y inscrire de données sensibles
            (origine raciale, santé, etc.)"
                />
              </ListItem>
              <ListItem sx={{ display: 'list-item', fontSize, p: 0, pl: 1 }}>
                <FormattedMessage
                  id="FirstConnection.View.Comments"
                  defaultMessage="à intégrer des commentaires objectifs"
                />
              </ListItem>

              <Typography sx={{ fontSize, my: 1 }}>
                <FormattedMessage
                  id="FirstConnection.View.End"
                  defaultMessage="Bonne navigation !"
                />
              </Typography>
            </Box>

            <Stack alignItems="center" justifyContent="center" sx={{ my: 2 }}>
              <Button
                sx={{
                  fontSize,
                  textTransform: 'none',
                  minWidth: '255px',
                }}
                onClick={() =>
                  navigate(
                    `/signin/authorize${
                      redirectToParams !== ''
                        ? `?redirectTo=${redirectToParams}`
                        : ''
                    }`
                  )
                }
              >
                <FormattedMessage
                  id="FirstConnection.View.Buttons.Access"
                  defaultMessage="Accéder à la plateforme"
                />
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
}

export default FirstConnectionView;
