import { Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import withPermissions from '@components/withTenantPermissions';
import DangerModalTitleLabel from './DangerModalTitleLabel';

function DeleteTemplateModal({ handleSubmit, disabled }) {
  const { id } = useParams();

  const onDelete = handleSubmit(id);

  return (
    <DialogWithClose
      titleLabel={
        <DangerModalTitleLabel>SUPPRIMER CE TEMPLATE</DangerModalTitleLabel>
      }
      dialogActions={
        <Button onClick={onDelete} disabled={disabled}>
          <FormattedMessage id="Verbs.Delete" />
        </Button>
      }
    >
      <Box sx={{ textAlign: 'center' }}>
        <Typography sx={{ mx: 4, mt: 2, mb: 3, fontWeight: 500 }} variant="h4">
          Attention
        </Typography>
        <Typography sx={{ fontWeight: 500 }}>
          Vous êtes sur le point de supprimer un template. Etes-vous sûr de
          vouloir continuer ?
        </Typography>
      </Box>
    </DialogWithClose>
  );
}

export const DeleteTemplateModalWithPermissions =
  withPermissions(DeleteTemplateModal);

export default DeleteTemplateModal;
