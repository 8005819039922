import { defineMessages } from 'react-intl';

export default defineMessages({
  ViewerErrorDefaultMessage: {
    id: 'Components.Viewer.Error.DefaultMessage',
    defaultMessage: 'An error occured when viewing document.',
  },
  ComponentsSearchPlaceholderLabel: {
    id: 'Components.Search.Placeholder.Label',
    defaultMessage: 'Search',
  },
});
