import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import FormikProvider from '@components/Formik/FormikProvider';
import FormikTextField from '@components/Formik/FormikTextField';
import { Box, Typography } from '@mui/material';
import useFetch from '@hooks/useFetch';

export const defaultSchema = Yup.object().shape({
  newPassword: Yup.string()
    .ensure()
    .trim()
    .required('Signin.Fields.Password.Required'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Les mots de passe doivent être identiques'
  ),
});

function ResetPasswordForm({ signinUrl }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('passwordResetToken');
  const email = searchParams.get('email');

  const defaultValues = {
    email,
    newPassword: '',
    confirmPassword: '',
  };

  const { doFetch, error, fetching } = useFetch({
    url: 'authentication/password/reset',
    method: 'POST',
    onSuccess: () => {
      navigate('/authentication/reset-password-confirmation');
    },
  });

  const onSubmitCallback = async form => {
    doFetch({ body: { ...form, email, token } });
  };

  return (
    <Box>
      <Typography sx={{ textAlign: 'center' }}>
        <FormattedMessage
          id="Signin.Reset.Password.Fields.Description.Message"
          defaultMessage="Merci de saisir le mot de passe que vous souhaitez utiliser."
        />
      </Typography>
      <FormikProvider
        schema={defaultSchema}
        initialValues={defaultValues}
        onSubmit={onSubmitCallback}
      >
        <ErrorMessage error={error} sx={{ mt: 1, mb: 2 }} />
        <FormikTextField
          name="email"
          disabled
          label={
            <FormattedMessage
              id="Signin.Fields.Email.Label"
              defaultMessage="Email"
            />
          }
        />
        <FormikTextField
          autofocus
          type="password"
          name="newPassword"
          required
          autoComplete="current-password"
          label={
            <FormattedMessage
              id="Signin.Fields.Password.Label"
              defaultMessage="Password"
            />
          }
        />
        <FormikTextField
          type="password"
          name="confirmPassword"
          required
          autoComplete="current-password"
          label={
            <FormattedMessage
              id="Signin.Fields.PasswordConfirmation.Label"
              defaultMessage="Confirmation du mot de passe"
            />
          }
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          <FormattedMessage
            id="Signin.Button.InitializePassword"
            defaultMessage="Réinitialiser mon mot de passe"
          />
        </Button>
        <Typography
          sx={{
            fontSize: '10px',
            textAlign: 'center',
            mb: 1,
            mt: 2,
            color: 'red',
          }}
        >
          <FormattedMessage
            id="Signin.Password.Requirements.Validate.Message"
            defaultMessage="Votre mot de passe doit contenir 12 caractères minimum dont 1 chiffre,
          1 lettre majuscule, 1 lettre minuscule et 1 caractère spécial."
          />
        </Typography>
        {Boolean(signinUrl) && (
          <Button
            fullWidth
            variant="text"
            sx={{ mt: 2 }}
            component={Link}
            to={signinUrl}
            disabled={fetching}
          >
            Se connecter
          </Button>
        )}
      </FormikProvider>
    </Box>
  );
}

export default ResetPasswordForm;
