import React, { useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import useFetch from '@hooks/useFetch';
import Fetching from '@components/Fetching/Fetching';

export const useMoveForm = ({ getDriveUrl, basePath }) => {
  const [path, setPath] = useState(basePath ?? []);
  const folderId = path.length ? path[path.length - 1].id : null;
  const url = useMemo(() => getDriveUrl({ folderId }), [getDriveUrl, folderId]);
  const childrenFetch = useFetch({ url });

  const children = childrenFetch.data?.items;

  return { path, setPath, folderId, childrenFetch, children };
};

export default function MoveForm({ moveForm }) {
  const { path, setPath, childrenFetch, children } = moveForm;

  const handleChildClick = child => {
    if (path.some(b => b.id === child.id)) return;
    setPath([...path, child]);
  };

  const handleBreadcrumbRootClick = () => {
    setPath([]);
  };

  const handleBreadcrumbClick = breadcrumb => {
    const index = path.findIndex(b => b === breadcrumb);
    if (index === -1) return;
    setPath(path.slice(0, index + 1));
  };

  return (
    <Stack
      sx={{
        width: '100%',
        my: 2,
      }}
    >
      <Breadcrumbs separator={<ChevronRightIcon />}>
        <Box>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link component="button" onClick={() => handleBreadcrumbRootClick()}>
            <em>Racine</em>
          </Link>
        </Box>
        {path?.map(b => (
          <Box key={b.id}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link component="button" onClick={() => handleBreadcrumbClick(b)}>
              {b.name}
            </Link>
          </Box>
        ))}
      </Breadcrumbs>
      <List>
        <Fetching fetching={childrenFetch.fetching}>
          {children?.length ? (
            children?.map(child => {
              const isFolder = child.type === 'folder';
              const ListItemComponent = isFolder ? ListItemButton : ListItem;
              return (
                <ListItemComponent
                  key={child.id}
                  onClick={isFolder ? () => handleChildClick(child) : null}
                  sx={isFolder ? null : { opacity: 0.5 }}
                >
                  <ListItemIcon>
                    {isFolder ? <FolderIcon /> : <InsertDriveFileIcon />}
                  </ListItemIcon>
                  <ListItemText primary={child.name} />
                  {isFolder ? <ChevronRightIcon /> : null}
                </ListItemComponent>
              );
            })
          ) : (
            <Box p={4} textAlign="center">
              <em>Ce dossier est vide</em>
            </Box>
          )}
        </Fetching>
      </List>
    </Stack>
  );
}
