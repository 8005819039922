import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function DangerModalTitleLabel({ children }) {
  return (
    <Stack
      sx={{ borderBottom: '1px solid #4B8270' }}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      pb={1}
    >
      <Typography sx={{ fontWeight: 700 }} color="error" variant="h6">
        {children}
      </Typography>
    </Stack>
  );
}

export default DangerModalTitleLabel;
