import React from 'react';
import Stepper, { stepperClasses } from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import CachedIcon from '@mui/icons-material/Cached';
import HistoryIcon from '@mui/icons-material/History';
import { styled } from '@mui/material/styles';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';
import ContributorChip from '@components/Chips/ContributorChip';
import { bold } from '@utils/chunks';

const getStatus = endTime => {
  if (endTime != null) return 'completed';
  return 'inprogress';
};

const getActiveStepFunction = workflow => {
  if (!workflow?.steps) return null;
  const completedStepsLength = workflow.steps.filter(
    step => step.endTime !== null
  ).length;
  return completedStepsLength + (workflow.isFirstStepCreation ? 0 : 1);
};

const colors = {
  completed: {
    iconBackground: '#91D754',
    iconColor: '#ffffff',
    textColor: '#91d754',
    textBackground: '#f2faea',
    connectorBorderColor: '#91D754',
  },
  inprogress: {
    iconBackground: '#e2f3ff',
    iconColor: '#0D99FF',
    textColor: '#8dcfff',
    textBackground: '#e2f3ff',
    connectorBorderColor: '#9e9e9e',
  },
  todo: {
    iconBackground: '#E8E8E8',
    iconColor: '#9e9e9e',
    textColor: '#9e9e9e',
    textBackground: 'transparent',
    connectorBorderColor: '#9e9e9e',
  },
};

const StyledStepper = styled(Stepper)(() => ({
  [`&.${stepperClasses.vertical} .MuiStepContent-root`]: {
    borderLeft: `solid 1px ${colors.todo.connectorBorderColor}`,
  },
}));

const StyledConnector = styled(StepConnector, {
  shouldForwardProp: prop => prop !== 'activeStep',
})(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: colors.todo.connectorBorderColor,
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: colors.todo.connectorBorderColor,
  },
}));

const StyleLabel = styled(StepLabel)(({ status, open }) => ({
  [`& .MuiStepLabel-labelContainer`]: {
    backgroundColor: colors[status].textBackground,
  },
  [`& .MuiStepLabel-iconContainer`]: !open && {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  [`& .MuiStepLabel-label`]: {
    color: colors[status].textColor,
    borderRadius: '4px',
    padding: '4px 6px',
    fontWeight: 'normal',
    fontSize: '13px',
  },
  [`& .MuiStepLabel-label.Mui-completed`]: {
    color: colors[status].textColor,
  },
  [`& .MuiStepLabel-label.Mui-active`]: {
    color: colors[status].textColor,
  },
}));

const StyledStepIconRoot = styled('div', {
  shouldForwardProp: prop => prop !== 'backgroundColor',
})(({ backgroundColor }) => ({
  width: '24px',
  height: '24px',
  borderRadius: '100%',
  backgroundColor,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const icons = {
  completed: () => (
    <StyledStepIconRoot backgroundColor={colors.completed.iconBackground}>
      <CheckIcon sx={{ color: colors.completed.iconColor, fontSize: '12px' }} />
    </StyledStepIconRoot>
  ),
  inprogress: () => (
    <StyledStepIconRoot backgroundColor={colors.inprogress.iconBackground}>
      <CachedIcon
        sx={{ color: colors.inprogress.iconColor, fontSize: '12px' }}
      />
    </StyledStepIconRoot>
  ),
  todo: () => (
    <StyledStepIconRoot backgroundColor={colors.todo.iconBackground}>
      <HistoryIcon sx={{ color: colors.todo.iconColor, fontSize: '12px' }} />
    </StyledStepIconRoot>
  ),
};

function WorkflowStepContent({ step, isCreation, isOpen }) {
  const status = isCreation ? 'completed' : getStatus(step.endTime);
  const stepName = isCreation ? 'Creation' : step.name;
  const contributors = [
    ...(step?.mandatoryContributors ?? []),
    ...(step?.otherContributors ?? []),
  ];
  return (
    <>
      <StyleLabel
        StepIconComponent={icons[status]}
        status={status}
        open={isOpen}
      >
        {isOpen ? stepName : null}
      </StyleLabel>
      {isOpen ? (
        <StepContent>
          <Box>
            {contributors ? (
              <Box>
                {contributors.map(contributor => (
                  <Stack
                    direction="row"
                    gap={2}
                    alignItems="center"
                    sx={{ alignItems: 'center', my: 2 }}
                    key={contributor.id}
                  >
                    <Typography>
                      <FormattedMessage id="Workflow.Step.Contributor.By" />
                    </Typography>
                    {contributor.type === 2 &&
                    contributor.assignments?.length ? (
                      contributor.assignments.map(assignment => (
                        <ContributorChip
                          key={assignment.userId}
                          sx={{ bgcolor: 'grey.200' }}
                          type={1}
                          entityId={assignment.userId}
                          entity={assignment.user}
                          authorLabel={
                            <FormattedMessage id="Workflow.Step.Author.Label" />
                          }
                          disabled={contributor.disabled}
                        />
                      ))
                    ) : (
                      <ContributorChip
                        sx={{ bgcolor: 'grey.200' }}
                        type={contributor.type}
                        entityId={contributor.entityId}
                        entity={contributor.entity}
                        authorLabel={
                          <FormattedMessage id="Workflow.Step.Author.Label" />
                        }
                        disabled={contributor.disabled}
                      />
                    )}
                    {step.validations.length !== 0 && (
                      <>
                        {step.validations.map(
                          singleValidation =>
                            contributor.entityId ===
                              singleValidation.validatorUserId && (
                              <CheckIcon
                                sx={{
                                  color: '#91d754',
                                  fontSize: '18px',
                                }}
                              />
                            )
                        )}
                      </>
                    )}
                  </Stack>
                ))}
              </Box>
            ) : null}

            <Box>
              <Stack>
                {step?.displayDuration ? (
                  <Typography variant="body">
                    <FormattedMessage
                      id="Workflow.Step.Duration.Message"
                      values={{ duration: step.displayDuration, b: bold }}
                    />
                  </Typography>
                ) : null}
              </Stack>
            </Box>
          </Box>
        </StepContent>
      ) : null}
    </>
  );
}

export default function WorkflowStepper({ workflow, isOpen }) {
  const activeStep = getActiveStepFunction(workflow);

  if (!workflow) return null;

  return (
    <Box sx={{ maxWidth: 400, padding: '16px' }}>
      <StyledStepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<StyledConnector />}
      >
        {!workflow.isFirstStepCreation && (
          <Step active>
            <WorkflowStepContent isCreation isOpen={isOpen} />
          </Step>
        )}
        {workflow.steps.map((step, stepIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Step key={stepIndex} active>
            <WorkflowStepContent step={step} isOpen={isOpen} />
          </Step>
        ))}
      </StyledStepper>
      {activeStep === workflow.steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>
            <FormattedMessage id="Workflow.Step.Completed.Label" />
          </Typography>
        </Paper>
      )}
    </Box>
  );
}
