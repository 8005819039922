import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormikProvider from '@components/Formik/FormikProvider';
import FormikTextField from '@components/Formik/FormikTextField';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import { FormattedMessage } from 'react-intl';
import withPermissions from '@components/withTenantPermissions';
import useFetch from '@hooks/useFetch';
import Fetching from '@components/Fetching/Fetching';

const schema = Yup.object().shape({
  name: Yup.string().trim().required('Generic.Fields.Required'),
});

function RenameTemplateModal({ onSuccess }) {
  const { id } = useParams();

  const detailFetch = useFetch({
    method: 'GET',
    url: `templates/${id}`,
  });

  const updateFetch = useFetch({
    method: 'PUT',
    url: `templates/${id}`,
    onSuccess,
  });

  const template = detailFetch.data;

  const initialValues = useMemo(
    () => ({ name: template?.name || '' }),
    [template]
  );

  const handleSubmit = values => {
    updateFetch.doFetch({ body: { ...values, folderId: template?.folderId } });
  };

  return (
    <FormikProvider
      schema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <DialogWithClose
        titleLabel="Renommer le template"
        dialogActions={
          <Button
            type="submit"
            disabled={detailFetch.fetching || updateFetch.fetching}
          >
            <FormattedMessage id="Verbs.Rename" />
          </Button>
        }
      >
        <Fetching fetching={detailFetch.fetching}>
          <Stack gap={2}>
            <FormikTextField
              required
              name="name"
              label={<FormattedMessage id="Template.Fields.Name.Label" />}
              color="secondary"
              margin="dense"
              disabled={updateFetch.fetching}
            />
          </Stack>
        </Fetching>
      </DialogWithClose>
    </FormikProvider>
  );
}

export const RenameTemplateModalWithPermissions =
  withPermissions(RenameTemplateModal);

export default RenameTemplateModal;
