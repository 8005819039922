import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { FormattedMessage, useIntl } from 'react-intl';
import { label } from '@utils/contributor-helpers';
import WorkflowContributorEditor, {
  DeleteWorkflowContributorButton,
} from './WorkflowContributorEditor';
import { contributorUsers } from '../WorkflowContextProvider';

const WorkflowContributorEditorMemoed = React.memo(WorkflowContributorEditor);

function WorkflowContributorList({
  name,
  primary,
  action,
  readOnly = false,
  showSignature = false,
  defaultContributor = {
    contributor: null,
    access: '',
    condition: '',
    assignments: [],
  },
  variablesData,
  hideAccess,
  hideCondition,
  usersOnly,
  schema,
  readOnlyContributors,
  children,
}) {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext();
  const contributors = React.useMemo(() => values[name], [values, name]);
  const allContributorUsers =
    readOnlyContributors?.flatMap(c => contributorUsers(c)) ?? [];

  return (
    <FieldArray name={name}>
      {({ remove, push }) => (
        <List component="div" disablePadding>
          <ListItem component="div" ContainerComponent="div">
            <ListItemText primary={primary} />
            <ListItemSecondaryAction sx={{ right: 0 }}>
              {!readOnly && (
                <Button
                  variant="text"
                  color="primary"
                  fullWidth
                  startIcon={<AddCircleIcon />}
                  onClick={() => push(defaultContributor)}
                >
                  {action}
                </Button>
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <Box sx={{ m: 2, mb: 0 }}>
            <List component={Stack} rowGap={1} disablePadding>
              {allContributorUsers?.length > 0 &&
                allContributorUsers.map((user, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem component={Paper} variant="outlined" key={index}>
                    <Stack
                      direction="row"
                      width={1}
                      columnGap={4}
                      alignItems="center"
                    >
                      <Box flexGrow={1}>
                        <TextField
                          margin="dense"
                          value={label(user, formatMessage)}
                          label={
                            <FormattedMessage id="Workflow.ContributorPicker.Label" />
                          }
                          fullWidth
                          disabled
                        />
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Tooltip
                        title={formatMessage({
                          id: 'Workflow.Contributor.Buttons.Remove.NextStepContributorWarningText',
                        })}
                      >
                        <Box>
                          <DeleteWorkflowContributorButton disabled />
                        </Box>
                      </Tooltip>
                    </Stack>
                  </ListItem>
                ))}
              {contributors?.length > 0 &&
                contributors.map((_, index) => (
                  <WorkflowContributorEditorMemoed
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    name={name}
                    index={index}
                    readOnly={readOnly}
                    showSignature={showSignature}
                    onRemove={() => remove(index)}
                    variablesData={variablesData}
                    hideAccess={hideAccess}
                    hideCondition={hideCondition}
                    usersOnly={usersOnly}
                    schema={schema}
                  />
                ))}
            </List>
            {children}
          </Box>
        </List>
      )}
    </FieldArray>
  );
}

export default WorkflowContributorList;
