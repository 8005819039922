import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import MoveModal from '@components/Modals/MoveModal';
import withPermissions from '@components/withTenantPermissions';

export function getTemplateDriveUrl({ folderId }) {
  return `templates/drive?parentId=${
    folderId ? encodeURIComponent(folderId) : ''
  }`;
}

function MoveTemplateModal({ onSuccess, basePath }) {
  const { id: templateId } = useParams();
  const url = `templates/${templateId}/move`;
  return (
    <MoveModal
      title={
        <FormattedMessage
          id="MoveTemplateModal.Label"
          defaultMessage="Déplacer template"
        />
      }
      url={url}
      getDriveUrl={getTemplateDriveUrl}
      basePath={basePath}
      onSuccess={onSuccess}
    />
  );
}

export const MoveTemplateModalWithPermissions =
  withPermissions(MoveTemplateModal);

export default MoveTemplateModal;
