/* eslint-disable react/no-array-index-key */
import React, { MouseEventHandler, ReactNode } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormattedMessage } from 'react-intl';
import Stack from '@mui/material/Stack';
import IconButton from '@components/Buttons/IconButtons/IconButton';
import { Typography } from '@mui/material';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 14,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export type MenuButtonOption =
  | { type: 'divider' }
  | {
      type?: 'item';
      id?: string;
      label?: string;
      icon?: ReactNode;
      defaultMessage?: string;
      onClick?(option: MenuButtonOption): void;
      disabled?: boolean;
    };

export type MenuButtonProps = React.PropsWithChildren<{
  options: MenuButtonOption[];
  variant?: 'text' | 'outlined' | 'contained';
  iconButton?: boolean;
  className?: string;
  onOpenMenu?: MouseEventHandler<HTMLButtonElement>;
  onCloseMenu?: MouseEventHandler<HTMLElement>;
}>;

export default function MenuButton({
  children,
  options,
  variant,
  iconButton,
  className,
  onOpenMenu,
  onCloseMenu,
}: MenuButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    if (onOpenMenu) {
      onOpenMenu(event);
    }

    setAnchorEl(event.currentTarget);
  };
  const handleClose: MouseEventHandler<HTMLElement> = event => {
    event.stopPropagation();
    if (onCloseMenu) {
      onCloseMenu(event);
    }

    setAnchorEl(null);
  };

  return (
    <>
      {iconButton ? (
        <IconButton onClick={handleClick}>{children}</IconButton>
      ) : (
        <Button
          className={className}
          variant={variant || 'outlined'}
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {children}
        </Button>
      )}

      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option, index) =>
          option.type === 'divider' ? (
            <Divider key={`divider-${index}`} sx={{ my: 0.5 }} />
          ) : (
            <MenuItem
              disabled={option.disabled}
              key={index}
              {...option}
              onClick={event => {
                if (option.onClick) {
                  option.onClick(option);
                }
                handleClose(event);
              }}
              disableRipple
            >
              <Stack direction="row" alignItems="center" gap={0.2}>
                {option.icon}
                <Typography sx={{ fontSize: '11px' }}>
                  {!option.id ? (
                    option.defaultMessage ?? option.label
                  ) : (
                    <FormattedMessage
                      id={option.id}
                      defaultMessage={option.defaultMessage ?? option.label}
                    />
                  )}
                </Typography>
              </Stack>
            </MenuItem>
          )
        )}
      </StyledMenu>
    </>
  );
}
