import React, { Suspense, lazy } from 'react';
import {
  Route,
  Routes,
  Navigate,
  resolvePath,
  useResolvedPath,
} from 'react-router-dom';

import Loader from '@components/Loader/Loader';
import TenantAuthenticatedRoute from '@components/ProtectedRoutes/TenantAuthenticatedRoute';

import NotFoundView from '@views/NotFoundView/NotFoundView';

import NexityAvenantTravauxContractView from './views/Contracts/NexityAvenantTravauxContractView';
import FirstConnectionView from './views/SignIn/FirstConnectionView';
import SigninView from './views/SignIn/SigninView';
import ForgotPasswordView from './views/ForgotPassword/ForgotPasswordView';
import ForgotPasswordConfirmation from './views/ForgotPassword/ForgotPasswordConfirmation';
import ResetPassword from './views/ResetPassword/ResetPassword';
import ResetConfirmation from './views/ResetPassword/ResetConfirmation';

const AuthenticatedLayout = lazy(() =>
  import('@shells/nexity-avenant-travaux/nexity-avenant-travaux-layout')
);

const DashboardView = lazy(() => import('@views/Dashboard/DashboardView'));

const DriveView = lazy(() => import('@views/Drive/DriveView'));
const CalendarView = lazy(() => import('@views/Calendar/CalendarView'));
const StatisticsView = lazy(() => import('@views/Statistics/StatisticsView'));
const AdminView = lazy(() => import('@views/Admin/AdminView'));
const NexityAvenantTravauxTemplatesView = lazy(() =>
  import('./views/Administration/Templates/NexityAvenantTravauxTemplatesView')
);
const ManageDataView = lazy(() => import('@views/MangeData/ManageDataView'));
const UserAccountsView = lazy(() =>
  import('@views/Administration/UserAccounts/UserAccountsView')
);

const ExternalAccountsView = lazy(() =>
  import('@views/Administration/ExternalAccounts/ExternalAccountsView')
);
const ReferentialsView = lazy(() =>
  import('@views/Referentials/ReferentialsView')
);
const ProspectView = lazy(() => import('@views/Referentials/ProspectView'));

const IntegrationsView = lazy(() =>
  import('@views/Integrations/IntegrationsView')
);
const IntegrationDetailsView = lazy(() =>
  import('@views/Integrations/IntegrationDetailsView')
);

const forgotPasswordConfirmationPath =
  'authentication/forgot-password-confirmation';

const signinPath = 'signin';
const firstConnectionPath = 'authentication/first-connection';

const TenantRedirecTo = lazy(() => import('@views/Tenant/TenantRedirect'));

function NexityAvenantTravauxRoutes() {
  const basePath = useResolvedPath('.').pathname;
  const signinUrl = resolvePath(signinPath, basePath);
  const firstConnectionUrl = resolvePath(firstConnectionPath, basePath);
  return (
    <Suspense fallback={<Loader sx={{ height: '100vh' }} />}>
      <Routes>
        <Route
          path={`${signinPath}/*`}
          element={<SigninView firstConnectionUrl={firstConnectionUrl} />}
        />
        <Route path={firstConnectionPath} element={<FirstConnectionView />} />
        <Route
          path="authentication/forgot-password"
          element={
            <ForgotPasswordView
              successUrl={resolvePath(forgotPasswordConfirmationPath, basePath)}
            />
          }
        />
        <Route
          path={forgotPasswordConfirmationPath}
          element={<ForgotPasswordConfirmation signInUrl={signinUrl} />}
        />
        <Route
          path="authentication/reset-password"
          element={<ResetPassword signinUrl={signinUrl} />}
        />
        <Route
          path="authentication/reset-password-confirmation"
          element={<ResetConfirmation signinUrl={signinUrl} />}
        />
        <Route path="*" element={<TenantAuthenticatedRoute />}>
          <Route path="*" element={<AuthenticatedLayout />}>
            <Route index element={<Navigate to="/dashboard" replace />} />
            <Route path="dashboard/*">
              <Route
                path="*"
                element={<DashboardView tenantClient="nexityavenanttravaux" />}
              />
            </Route>
            <Route path="drive/*" element={<DriveView />} />
            <Route
              path="contract/:contractId/*"
              element={<NexityAvenantTravauxContractView />}
            />
            <Route path="calendar/*">
              <Route path="*" element={<CalendarView />} />
            </Route>
            <Route path="statistics" element={<StatisticsView />} />
            <Route path="admin/*">
              <Route index element={<AdminView />} />
              <Route
                path="templates/*"
                element={<NexityAvenantTravauxTemplatesView />}
              />
              <Route path="manage-data/*" element={<ManageDataView />} />
              <Route path="users/*" element={<UserAccountsView />} />
              <Route path="external/*" element={<ExternalAccountsView />} />
              <Route path="referentials/*">
                <Route path="*" element={<ReferentialsView />} />
                <Route path="prospect/:id/*" element={<ProspectView />} />
              </Route>
              <Route path="integrations/*">
                <Route index element={<IntegrationsView />} />
                <Route
                  path=":integration"
                  element={<IntegrationDetailsView />}
                />
              </Route>
              <Route path="*" element={<NotFoundView />} />
            </Route>
            <Route path="tenant/*">
              <Route path=":tenantId/*" element={<TenantRedirecTo />} />
            </Route>
            <Route path="*" element={<NotFoundView />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}

export default NexityAvenantTravauxRoutes;
