import React from 'react';
import MuiPagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

export default function Pagination({ total, limit, page, setPage, apiMode }) {
  const onPageChange = (_, p) => setPage(p);

  return (
    Boolean(total) && (
      <MuiPagination
        sx={{ mt: 4 }}
        color="primary"
        count={apiMode ? total : Math.ceil(total / limit)}
        page={page}
        onChange={onPageChange}
        showFirstButton
        showLastButton
        renderItem={item => (
          <PaginationItem
            components={{
              first: KeyboardDoubleArrowLeftIcon,
              last: KeyboardDoubleArrowRightIcon,
            }}
            {...item}
          />
        )}
      />
    )
  );
}
