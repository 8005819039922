import DialogWithClose from '@components/Dialog/DialogWithClose';
import useFetch from '@hooks/useFetch';
import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import DateRangeIcon from '@mui/icons-material/DateRange';
import RuleIcon from '@mui/icons-material/Rule';
import NumbersIcon from '@mui/icons-material/Numbers';

import { useEffect, useState } from 'react';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import {
  TermSheetElementResponse,
  TermSheetElementsListResponse,
} from '@api/types';
import Fetching from '@components/Fetching/Fetching';
import { useOutletContext } from 'react-router-dom';
import StyledSearch from '@components/Search/StyledSearch';

interface TermSheetFilterModalProps {
  onSelectedFilter?: (item: TermSheetElementResponse) => void;
  selectedFilters?: string[];
}

const FilterTypeTermSheet = [
  {
    type: 'Text',
    label: 'Texte',
    icon: <FormatSizeIcon />,
  },
  {
    type: 'Number',
    label: 'Numérique',
    icon: <NumbersIcon />,
  },
  {
    type: 'Boolean',
    label: 'Vrai/Faux',
    icon: <RuleIcon />,
  },
  {
    type: 'Timestamp',
    label: 'Timestamp',
    icon: <DateRangeIcon />,
  },
];

function TermSheetFilterModal({
  onSelectedFilter,
  selectedFilters,
}: TermSheetFilterModalProps) {
  const { close } = useOutletContext<{ close: any }>();
  const [termSheets, setTermSheets] = useState<TermSheetElementResponse[]>([]);
  const [filteredTermSheets, setFilteredTermSheets] = useState<
    TermSheetElementResponse[]
  >([]);
  const [searchValue, setSearchValue] = useState('');

  const termSheetFetch = useFetch<TermSheetElementsListResponse>({
    url: `term-sheets/elements`,
    method: 'GET',
    onSuccess: (response: any) => {
      const result = response?.data?.termSheetElements.filter(
        (item: any) =>
          FilterTypeTermSheet.map(x => x.type).includes(item.valueType) &&
          !selectedFilters?.includes(item.id)
      );
      setTermSheets(result);
      setFilteredTermSheets(result);
    },
  });

  useEffect(() => {
    const filtered = termSheets.filter(x =>
      x.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredTermSheets(filtered);
  }, [searchValue, termSheets]);

  const handleClick = (item: TermSheetElementResponse) => {
    if (onSelectedFilter !== undefined) {
      onSelectedFilter(item);
    }
    close();
  };

  return (
    <DialogWithClose titleLabel="Ajouter des filtres de recherche">
      <Stack direction="column" gap={2}>
        <StyledSearch
          disabled={filteredTermSheets.length === 0}
          value={searchValue}
          setValue={setSearchValue}
        />
        <Fetching fetching={termSheetFetch.fetching}>
          <Stack direction="row" gap={1} flexWrap="wrap">
            <ErrorMessage error={termSheetFetch.error} sx={{ mb: 2 }} />
            {filteredTermSheets?.map((termSheet: any) => (
              <Button
                key={termSheet.id}
                size="small"
                color="secondary"
                disableElevation
                variant="outlined"
                startIcon={
                  FilterTypeTermSheet.find(x => x.type === termSheet.valueType)
                    ?.icon
                }
                sx={{ borderRadius: 1 }}
                onClick={() => handleClick(termSheet)}
              >
                {termSheet.name}
              </Button>
            ))}
            {!termSheetFetch.error && filteredTermSheets.length === 0 && (
              <Typography variant="caption">Aucun filtre disponible</Typography>
            )}
          </Stack>
        </Fetching>
      </Stack>
    </DialogWithClose>
  );
}

export default TermSheetFilterModal;
