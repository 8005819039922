import { defineMessages } from 'react-intl';

export default defineMessages({
  ErrorOccured: {
    id: 'Errors.ErrorOccured',
    defaultMessage: 'An error occured, please contact an administrator.',
  },
  ErrorEditorOccured: {
    id: 'Errors.EditorOccured',
    defaultMessage: 'An error occured, please refresh the page.',
  },
  NetworkErrorOccured: {
    id: 'Errors.NetworkErrorOccured',
    defaultMessage: 'A network error occured, please contact an administrator.',
  },
  FailedToFetch: {
    id: 'Errors.FailedToFetch',
    defaultMessage: 'An fetch error occured, please contact an administrator.',
  },
  UserLockedOut: {
    id: 'Errors.UserLockedOut',
    defaultMessage: 'User is locked out, please contact an administrator.',
  },
  UserNotFound: {
    id: 'Errors.UserNotFound',
    defaultMessage: 'User was not found, please contact an administrator.',
  },
  UserHasNoPassword: {
    id: 'Errors.UserHasNoPassword',
    defaultMessage:
      'User does not have password, please contact an administrator.',
  },
  UserHasNoTenants: {
    id: 'Errors.UserHasNoTenants',
    defaultMessage: 'User does not have role, please contact an administrator.',
  },
  InvalidEmailPassword: {
    id: 'Errors.InvalidEmailPassword',
    defaultMessage: 'User or password is invalid, please try again.',
  },
  DepartmentAlreadyExists: {
    id: 'Errors.DepartmentAlreadyExists',
    defaultMessage: 'A department with the same name already exists.',
  },
  DepartmentNotFound: {
    id: 'Errors.DepartmentNotFound',
    defaultMessage:
      'The department was not found, please contact an administrator.',
  },
  DepartmentRootNotFound: {
    id: 'Errors.DepartmentRootNotFound',
    defaultMessage:
      'The root department was not found, please contact an administrator.',
  },
  ExpertAlreadyExists: {
    id: 'Errors.ExpertAlreadyExists',
    defaultMessage: 'Expert is already associated with this expertise.',
  },
  ExpertiseAlreadyExists: {
    id: 'Errors.ExpertiseAlreadyExists',
    defaultMessage: 'An expertise with the same name already exists.',
  },
  ExpertiseNotFound: {
    id: 'Errors.ExpertiseNotFound',
    defaultMessage:
      'The expertise was not found, please contact an administrator.',
  },
  DepartmentNotEmpty: {
    id: 'Errors.DepartmentNotEmpty',
    defaultMessage: 'The department is not empty.',
  },
  ProjectManagerAlreadyExists: {
    id: 'Errors.ProjectManagerAlreadyExists',
    defaultMessage: 'Project manager is already associated with this team.',
  },
  ProjectManagerIsLeader: {
    id: 'Errors.ProjectManagerIsLeader',
    defaultMessage: 'Project manager is leader of this team.',
  },
  TeamAlreadyExists: {
    id: 'Errors.TeamAlreadyExists',
    defaultMessage: 'This leader already exists.',
  },
  TenantAlreadyExists: {
    id: 'Errors.TenantAlreadyExists',
    defaultMessage: 'A business unit with the same name already exists.',
  },
  TenantAdministratorAlreadyExists: {
    id: 'Errors.TenantAdministratorAlreadyExists',
    defaultMessage: 'The user is already an administrator.',
  },
  ContractualPackSupportingDocumentAlreadyExists: {
    id: 'Errors.ContractualPackSupportingDocumentAlreadyExists',
    defaultMessage: 'A supporting document with the same name already exists.',
  },
  ContractualPackSupportingDocumentNotFound: {
    id: 'Errors.ContractualPackSupportingDocumentNotFound',
    defaultMessage:
      'The supporting document was not found, please contact an administrator.',
  },
  ContractualPackSignedDocumentNotFound: {
    id: 'Errors.ContractualPackSignedDocumentNotFound',
    defaultMessage:
      'The signed document was not found, please contact an administrator.',
  },
  ContractualPackNegociatedTemplateNotFound: {
    id: 'Errors.ContractualPackNegociatedTemplateNotFound',
    defaultMessage:
      'The document to be negociated was not found, please contact an administrator.',
  },
  CampaignAlreadyExists: {
    id: 'Errors.CampaignAlreadyExists',
    defaultMessage: 'A campaign with this year already exists.',
  },
  SuppliersReferentialNotFound: {
    id: 'Errors.SuppliersReferentialNotFound',
    defaultMessage:
      'Information about the system could not be retrieved, please contact an administrator.',
  },
  DocumentTemplateIdIsRequired: {
    id: 'Errors.DocumentTemplateIdIsRequired',
    defaultMessage: 'You must choose a template.',
  },
  SupportingAlreadyExists: {
    id: 'Errors.SupportingAlreadyExists',
    defaultMessage: 'A supporting document with the same name already exists.',
  },
  SupportingDocumentAlreadyExists: {
    id: 'Errors.SupportingDocumentAlreadyExists',
    defaultMessage: 'The supporting document was already uploaded.',
  },
  SupportingNotFound: {
    id: 'Errors.SupportingNotFound',
    defaultMessage: 'The supporting document was not found.',
  },
  SupportingElementNotFound: {
    id: 'Errors.SupportingElementNotFound',
    defaultMessage:
      'The supporting documents were not found, please contact an administrator.',
  },
  SupportingHasDocument: {
    id: 'Errors.SupportingHasDocument',
    defaultMessage: 'A supporting document was uploaded.',
  },
  SupportingDocumentNotFound: {
    id: 'Errors.SupportingDocumentNotFound',
    defaultMessage: 'No supporting document was uploaded.',
  },
  FolderNotFound: {
    id: 'Errors.FolderNotFound',
    defaultMessage: 'The folder was not found.',
  },
  SignatureProviderInfoNotFound: {
    id: 'Errors.SignatureProviderInfoNotFound',
    defaultMessage:
      'Credentials for signature were not found, please contact an administrator.',
  },
  ContractSignatureSendFailed: {
    id: 'Errors.ContractSignatureSendFailed',
    defaultMessage:
      'Sending the contract for signature failed, please contact an administrator.',
  },
  ContractSignatureCancelFailed: {
    id: 'Errors.ContractSignatureCancelFailed',
    defaultMessage:
      'Cancelling the signing process failed, please contact an administrator.',
  },
  WorkflowStepContributorNotFound: {
    id: 'Errors.WorkflowStepContributorNotFound',
    defaultMessage: 'You are not part of the workflow.',
  },
  ContractHasNoPdfContent: {
    id: 'Errors.ContractHasNoPdfContent',
    defaultMessage: 'The PDF for the contract was not found.',
  },
  WorkflowContributorsNotValidated: {
    id: 'Errors.WorkflowContributorsNotValidated',
    defaultMessage:
      'Some mandatory contributors did not validate their contribution to the current workflow step.',
  },
  MultiSignatureModalSubmitErrorsDocumentsRequired: {
    id: 'Errors.MultiSignatureModal.SubmitErrors.Documents.Required',
    defaultMessage: 'At least one document is required.',
  },
  ErrorsMultiSignatureModalSubmitErrorsDocumentsReasonPending: {
    id: 'Errors.MultiSignatureModal.SubmitErrors.Documents.Reason.Pending',
    defaultMessage: 'Document is not completed',
  },
  ErrorsMultiSignatureModalSubmitErrorsDocumentsReasonSigning: {
    id: 'Errors.MultiSignatureModal.SubmitErrors.Documents.Reason.Signing',
    defaultMessage: 'Document is already sent for signature',
  },
  ErrorsMultiSignatureModalSubmitErrorsDocumentsReasonFinished: {
    id: 'Errors.MultiSignatureModal.SubmitErrors.Documents.Reason.Finished',
    defaultMessage: 'Document is already signed',
  },
  ErrorsMultiSignatureModalSubmitErrorsDocumentsReasonUnknown: {
    id: 'Errors.MultiSignatureModal.SubmitErrors.Documents.Reason.Unknown',
    defaultMessage: 'Document has unknown status',
  },
  ErrorsMultiSignatureModalSubmitErrorsUsersReasons: {
    id: 'Errors.MultiSignatureModal.SubmitErrors.Users.Reasons',
    defaultMessage: 'User is missing informations ({missingFields}).',
  },
  ErrorsMultiSignatureModalSubmitErrorsUsersReasonsEmail: {
    id: 'Errors.MultiSignatureModal.SubmitErrors.Users.Reasons.Email',
    defaultMessage: 'Email',
  },
  ErrorsMultiSignatureModalSubmitErrorsUsersReasonsPhoneNumber: {
    id: 'Errors.MultiSignatureModal.SubmitErrors.Users.Reasons.PhoneNumber',
    defaultMessage: 'Phone number',
  },
  ErrorsMultiSignatureModalSubmitErrorsUsersReasonsMissingPhoneNumberCountryCode:
    {
      id: 'Errors.MultiSignatureModal.SubmitErrors.Users.Reasons.PhoneNumberCountryCode',
      defaultMessage: "Phone number's country code",
    },
  ErrorsMultiSignatureModalSubmitErrorsUsersReasonsFullPhoneNumber: {
    id: 'Errors.MultiSignatureModal.SubmitErrors.Users.Reasons.FullPhoneNumber',
    defaultMessage: 'Full phone number',
  },
  ErrorsMultiSignatureModalSubmitErrorsUsersReasonsCountryCode: {
    id: 'Errors.MultiSignatureModal.SubmitErrors.Users.Reasons.CountryCode',
    defaultMessage: 'Country code',
  },
  ErrorsMultiSignatureModalSubmitErrorsUsersReasonsName: {
    id: 'Errors.MultiSignatureModal.SubmitErrors.Users.Reasons.Name',
    defaultMessage: 'Name',
  },
  MultiSignatureModalSubmitErrorsSignaturesRequired: {
    id: 'Errors.MultiSignatureModal.SubmitErrors.Signatures.Required',
    defaultMessage: 'At least one signature is required.',
  },
  BlockDocumentsAreRequired: {
    id: 'Errors.BlockDocumentsAreRequired',
    defaultMessage: 'A block must have documents.',
  },
  WorkflowStepIsNotSign: {
    id: 'Errors.WorkflowStepIsNotSign',
    defaultMessage: 'Contract is not ready to be signed.',
  },
  AllContactsMustHavePhoneNumber: {
    id: 'Errors.AllContactsMustHavePhoneNumber',
    defaultMessage: 'All signers must have a phone number.',
  },
  PackDoesNotDefineSigners: {
    id: 'Errors.PackDoesNotDefineSigners',
    defaultMessage: 'The contractual pack does not define any signers.',
  },
  UserIsNotAuthorized: {
    id: 'Errors.UserIsNotAuthorized',
    defaultMessage: 'You do not have access to this page.',
  },
  EmailConfirmationInvalidToken: {
    id: 'Errors.EmailConfirmationInvalidToken',
    defaultMessage:
      'The email confirmation token is invalid, try reconfirming your email adress.',
  },
  EmailConfirmationFailed: {
    id: 'Errors.EmailConfirmationFailed',
    defaultMessage:
      'The confirmation of your email failed, please contact an administrator.',
  },
  ResetPasswordInvalidToken: {
    id: 'Errors.ResetPasswordInvalidToken',
    defaultMessage: 'The token for resetting your password is invalid.',
  },
  ResetPasswordPasswordTooShort: {
    id: 'Errors.ResetPasswordPasswordTooShort',
    defaultMessage: 'The new password is too short.',
  },
  ResetPasswordPasswordRequiresNonAlphanumeric: {
    id: 'Errors.ResetPasswordPasswordRequiresNonAlphanumeric',
    defaultMessage: 'The new password must have a non alphanumeric character',
  },
  ResetPasswordPasswordRequiresDigit: {
    id: 'Errors.ResetPasswordPasswordRequiresDigit',
    defaultMessage: 'The new password must have a digit.',
  },
  ResetPasswordPasswordRequiresUpper: {
    id: 'Errors.ResetPasswordPasswordRequiresUpper',
    defaultMessage: 'The new password must have an uppercase character.',
  },
  ResetPasswordPasswordRequiresLower: {
    id: 'Errors.ResetPasswordPasswordRequiresLower',
    defaultMessage: 'The new password must have an lowercase character.',
  },
  ResetPasswordFailed: {
    id: 'Errors.ResetPasswordFailed',
    defaultMessage:
      'The reset of the password failed, please contact an administrator.',
  },
  UserHasNoPhoneNumberForMfa: {
    id: 'Errors.UserHasNoPhoneNumberForMfa',
    defaultMessage:
      'You have no phone number for multi-factor authentication, please contact an administrator.',
  },
  FolderFormsNotValidated: {
    id: 'Errors.FolderFormsNotValidated',
    defaultMessage: 'You must valide all forms first.',
  },
  EmailIsInvalid: {
    id: 'Errors.EmailIsInvalid',
    defaultMessage: 'The email adress is invalid.',
  },
  TokenIsInvalid: {
    id: 'Errors.TokenIsInvalid',
    defaultMessage: 'The authentication code is invalid.',
  },
  InPersonSigningHostMustBeValidDocuSignUser: {
    id: 'Errors.InPersonSigningHostMustBeValidDocuSignUser',
    defaultMessage:
      'You do not have a valid and active DocuSign account to sign in person.',
  },
  CreateTemplateCommandTemplateAlreadyExists: {
    id: 'Errors.CreateTemplateCommand.Template.AlreadyExists',
    defaultMessage: 'Another template with that name already exists.',
  },
  DocumentFolderParentIsDeleted: {
    id: 'Errors.DocumentFolderParentIsDeleted',
    defaultMessage:
      'The parent folder is also deleted. Try restoring the parent folder first.',
  },
  TemplateFolderParentIsDeleted: {
    id: 'Errors.TemplateFolderParentIsDeleted',
    defaultMessage:
      'The parent folder is also deleted. Try restoring the parent folder first.',
  },
  AffiliateValidatorsSignersNotFound: {
    id: 'Errors.AffiliateValidatorsSignersNotFound',
    defaultMessage: 'The affiliate validators signers were not found.',
  },
  SignersNotFoundFromRole: {
    id: 'Errors.SignersNotFoundFromRole',
    defaultMessage: 'The signers were not found using their role(s).',
  },
  ParentFolderCannotBeSameFolder: {
    id: 'Errors.ParentFolderCannotBeSameFolder',
    defaultMessage: 'The new parent folder cannot be the same folder.',
  },
  ParentFolderIsInFolder: {
    id: 'Errors.ParentFolderIsInFolder',
    defaultMessage: 'The new parent folder is inside the folder.',
  },
  FileExtensionInvalid: {
    id: 'Errors.FileExtensionInvalid',
    defaultMessage: 'The file has the wrong extension.',
  },
  ContractHasCommentsAndNeedsConfirmation: {
    id: 'Errors.ContractHasCommentsAndNeedsConfirmation',
    defaultMessage:
      'Modifications and/or comments are still present in the document. Do you still want to go to the next step?',
  },
  ContractHasCommentsAndNextWorkflowStepIsSign: {
    id: 'Errors.ContractHasCommentsAndNextWorkflowStepIsSign',
    defaultMessage:
      'Modifications and/or comments are still present in the document. You cannot proceed to the signing step.',
  },
  ContractProviderFailed: {
    id: 'Errors.ContractProviderFailed',
    defaultMessage: 'An unknown error occured when opening the content.',
  },
  ContractualizationContractsNotCompleted: {
    id: 'Errors.ContractualizationContractsNotCompleted',
    defaultMessage: 'All contractualization contracts form must be completed.',
  },
  ContractFormNotCompleted: {
    id: 'Errors.ContractFormNotCompleted',
    defaultMessage: 'Contract form must be completed.',
  },
  SharedAccessRequestAlreadyAccepted: {
    id: 'Errors.SharedAccessRequestAlreadyAccepted',
    defaultMessage: 'This share request was already accepted.',
  },
  SharedAccessRequestAlreadyRejected: {
    id: 'Errors.SharedAccessRequestAlreadyRejected',
    defaultMessage: 'This share request was already rejected.',
  },
  DocumentQueryDocumentNotFound: {
    id: 'Errors.DocumentQuery.Document.NotFound',
    defaultMessage: 'The document was not found.',
  },
  AccessDeniedCancelContractSignature: {
    id: 'Errors.AccessDeniedCancelContractSignature',
    defaultMessage:
      'Access Denied: cancelling contract signature is not allowed.',
  },
  AccessDeniedCreateDocument: {
    id: 'Errors.AccessDeniedCreateDocument',
    defaultMessage: 'Access denied: creating document is not authorized.',
  },
  AccessDeniedCreateDocumentFolder: {
    id: 'Errors.AccessDeniedCreateDocumentFolder',
    defaultMessage: 'Access denied: creating folder is not authorized.',
  },
  AccessDeniedDeleteContract: {
    id: 'Errors.AccessDeniedDeleteContract',
    defaultMessage: 'Access denied: deleting contract is not authorized.',
  },
  AccessDeniedDeleteDocument: {
    id: 'Errors.AccessDeniedDeleteDocument',
    defaultMessage: 'Access denied: deleting document is not authorized.',
  },
  AccessDeniedDeleteDocumentFolder: {
    id: 'Errors.AccessDeniedDeleteDocumentFolder',
    defaultMessage: 'Access denied: deleting folder is not authorized.',
  },
  AccessDeniedDetailDocument: {
    id: 'Errors.AccessDeniedDetailDocument',
    defaultMessage:
      'Access denied: displaying detail document is not authorized.',
  },
  AccessDeniedDetailDocumentFolder: {
    id: 'Errors.AccessDeniedDetailDocumentFolder',
    defaultMessage:
      'Access denied: displaying detail folder is not authorized.',
  },
  AccessDeniedRenegociateContract: {
    id: 'Errors.AccessDeniedRenegociateContract',
    defaultMessage: 'Access denied: renegociating contract is not authorized.',
  },
  AccessDeniedUpdateDocument: {
    id: 'Errors.AccessDeniedUpdateDocument',
    defaultMessage: 'Access denied: updating document is not authorized.',
  },
  AccessDeniedUpdateDocumentFolder: {
    id: 'Errors.AccessDeniedUpdateDocumentFolder',
    defaultMessage: 'Access denied: updating folder is not authorized.',
  },
  AccessDeniedShareDocument: {
    id: 'Errors.AccessDeniedShareDocument',
    defaultMessage: 'Access denied: sharing document is not authorized.',
  },
  AccessDeniedShareDocumentFolder: {
    id: 'Errors.AccessDeniedShareDocumentFolder',
    defaultMessage: 'Access denied: sharing folder is not authorized.',
  },
  UnauthorizedBuAccess: {
    id: 'Errors.UnauthorizedBuAccess',
    defaultMessage: 'You do not have access to this business unit.',
  },
  DocumentAlreadyExists: {
    id: 'Errors.CreateDocumentCommand.Document.AlreadyExists',
    defaultMessage: 'A contract with this name already exists',
  },
  Forbidden: {
    id: 'Errors.Forbidden',
    defaultMessage:
      'You are not allowed to access this resource or do this action.',
  },
});
