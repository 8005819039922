import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useWindowTitle from '@hooks/useWindowTitle';

export type Breadcrumb = {
  key?: string;
  label?: string;
  defaultMessage?: string;
  message?: string;
  external?: string;
  to: string;
};

export default function Breadcrumbs({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) {
  const location = useLocation();
  const intl = useIntl();

  const getBreadcrumbLabel = useCallback(
    (breadcrumb: Breadcrumb) => {
      if (breadcrumb.label) return breadcrumb.label;
      const label = intl.formatMessage({
        id: breadcrumb.message,
        defaultMessage: breadcrumb.defaultMessage,
      });

      return label;
    },
    [intl]
  );

  const title = useMemo(
    () =>
      breadcrumbs
        .map(getBreadcrumbLabel)
        .filter(l => !!l)
        .reverse()
        .join(' - '),
    [breadcrumbs, getBreadcrumbLabel]
  );

  useWindowTitle(title);

  return (
    <MuiBreadcrumbs separator={<ChevronRightIcon />}>
      {breadcrumbs.map((breadcrumb, index) => {
        const { to, external, message, defaultMessage, label, key } =
          breadcrumb;
        const last = index === breadcrumbs.length - 1;
        if (last) {
          if (label) {
            return <Typography key={key ?? label}>{label}</Typography>;
          }
          return (
            <FormattedMessage
              id={message}
              key={key ?? message}
              defaultMessage={defaultMessage}
            >
              {chunks => <Typography>{chunks}</Typography>}
            </FormattedMessage>
          );
        }
        if (external) {
          if (label) {
            return (
              <MuiLink key={key ?? label} href={to}>
                {label}
              </MuiLink>
            );
          }
          return (
            <FormattedMessage
              id={message}
              key={key ?? message}
              defaultMessage={defaultMessage}
            >
              {chunks => <MuiLink href={to}>{chunks}</MuiLink>}
            </FormattedMessage>
          );
        }
        if (label) {
          return (
            <MuiLink
              key={key ?? label}
              component={RouterLink}
              to={to}
              state={location.state}
            >
              {label}
            </MuiLink>
          );
        }
        return (
          <MuiLink
            key={key ?? message}
            component={RouterLink}
            to={to}
            state={location.state}
          >
            <FormattedMessage id={message} defaultMessage={defaultMessage} />
          </MuiLink>
        );
      })}
    </MuiBreadcrumbs>
  );
}
