/* eslint-disable */
/* NOTE: This file is a copy-paste for the https://github.com/bghveding/use-fetch library.
 * Source: https://github.com/bghveding/use-fetch/blob/6a4d6e495c7106c860e789b1b5bf0bedf973e3a4/src/useFetch.js
 * Try not to make changes here.
 * -- Louis
 */

import { useEffect, useRef, useReducer, useCallback } from "react";
import { fetchDedupe, getRequestKey } from "fetch-dedupe";
/* NOTE: Reuse the same fetch context from the library.
 * -- Louis
 */
import { useFetchContext } from "@bjornagh/use-fetch";

/* NOTE: These functions come from the utils.js file.
 * -- Louis
 */
export const getHeader = (headers, keyToFind) => {
  if (!headers) {
    return null;
  }

  // Headers' get() is case insensitive
  if (headers instanceof Headers) {
    return headers.get(keyToFind);
  }

  const keyToFindLowercase = keyToFind.toLowerCase();
  // Convert keys to lowerCase so we don't run into case sensitivity issues
  const headerKey = Object.keys(headers).find(
    headerKey => headerKey.toLowerCase() === keyToFindLowercase
  );

  return headerKey ? headers[headerKey] : null;
};

export const stringifyIfJSON = fetchOptions => {
  const contentType = getHeader(fetchOptions.headers, "Content-Type");

  if (contentType && contentType.indexOf("application/json") !== -1) {
    return JSON.stringify(fetchOptions.body);
  }

  return fetchOptions.body;
};

export const isReadRequest = method => {
  method = method.toUpperCase();

  return method === "GET" || method === "HEAD" || method === "OPTIONS";
};

const CACHE_POLICIES = {
  NETWORK_ONLY: "network-only",
  CACHE_AND_NETWORK: "cache-and-network",
  EXACT_CACHE_AND_NETWORK: "exact-cache-and-network",
  CACHE_FIRST: "cache-first"
};

const getDefaultCacheStrategy = method => {
  method = method.toUpperCase();

  if (method === "GET" || method === "HEAD" || method === "OPTIONS") {
    return CACHE_POLICIES.CACHE_FIRST;
  }

  return CACHE_POLICIES.NETWORK_ONLY;
};

const defaultOnError = error => console.error(error);
const defaultOnSuccess = () => null;

function reducer(state, action) {
  switch (action.type) {
    case "in-flight": {
      // Avoid updating state unnecessarily
      // By returning unchanged state React won't re-render
      if (state.fetching === true) {
        return state;
      }

      return {
        ...state,
        fetching: true
      };
    }

    case "response": {
      return {
        ...state,
        error: null,
        fetching: action.payload.fetching,
        response: action.payload.response
      };
    }

    case "error":
      return {
        ...state,
        fetching: false,
        error: action.payload.error
      };

    default:
      return state;
  }
}

const defaultRefreshDoFetch = requestKey => requestKey;

function useFetch({
  url,
  method = "GET",
  lazy = null,
  requestKey = null,
  init = {},
  dedupeOptions = {},
  cachePolicy = null,
  cacheResponse = null,
  onError = defaultOnError,
  onSuccess = defaultOnSuccess,
  refreshDoFetch = defaultRefreshDoFetch
}) {
  const responseCache = useFetchContext();

  const abortControllerRef = useRef();

  /* NOTE: Added this to prevent changing state after unmounting.
   * -- Louis
   */
  const unmountedRef = useRef(false);

  // Default GET|HEAD|OPTIONS requests to non-lazy (automatically request onMount)
  // all else lazy (only requested when doFetch is called)
  const isRead = isReadRequest(method);
  const isLazy = lazy == null ? !isRead : lazy;

  const cacheStrategy =
    cachePolicy === null ? getDefaultCacheStrategy(method) : cachePolicy;

  const [state, dispatch] = useReducer(reducer, {
    response: null,
    fetching: !isLazy,
    error: null
  });

  // Builds a key based on URL, method and headers.
  // requestKey is used to determine if the request parameters have changed
  // and as key in the response cache.
  const finalRequestKey = requestKey
    ? requestKey
    : getRequestKey({ url, method: method.toUpperCase(), ...init });

  function setFetching() {
    dispatch({ type: "in-flight" });
  }

  function setResponse(response, fetching = false) {
    dispatch({ type: "response", payload: { response, fetching } });
  }

  function setError(error) {
    dispatch({ type: "error", payload: { error } });
  }

  function cancelRunningRequest() {
    if (abortControllerRef.current) {
      // Cancel current request
      abortControllerRef.current.abort();
    }
  }

  function shouldCacheResponse() {
    if (cacheResponse !== null) {
      return cacheResponse;
    }

    return isReadRequest(method);
  }

  const doFetch = useCallback(
    (doFetchInit = {}, doFetchDedupeOptions = {}) => {
      cancelRunningRequest();

      abortControllerRef.current = new AbortController();

      setFetching(true);

      const finalInit = {
        ...init,
        ...doFetchInit
      };

      const finalDedupeOptions = {
        ...dedupeOptions,
        ...doFetchDedupeOptions
      };

      return fetchDedupe(
        finalInit.url || url,
        {
          ...finalInit,
          method,
          signal: abortControllerRef.current.signal,
          body: finalInit.body ? stringifyIfJSON(finalInit) : undefined
        },
        finalDedupeOptions
      )
        .then(response => {
          if (!unmountedRef.current) {
            if (!response.ok) {
              setError(response);
              onError(response);
            } else {
              if (shouldCacheResponse()) {
                responseCache.set(finalRequestKey, response);
              }
              setResponse(response);
              onSuccess(response);
            }
          }

          return response;
        })
        .catch(error => {
          if (!unmountedRef.current) {
            if (!abortControllerRef.current.signal.aborted) {
              setError(error);
            }

            onError(error);
          }

          return error;
        })
        .finally(() => {
          // Remove the abort controller now that the request is done
          abortControllerRef.current = null;
        });
    },
    [refreshDoFetch(finalRequestKey)]
  );

  // Start requesting onMount if not lazy
  // Start requesting if isLazy goes from true to false
  // Start requesting every time the request key changes (i.e. URL, method, init.body or init.responseType) if not lazy
  useEffect(() => {
    // Do not start request automatically when in lazy mode
    if (isLazy === true) {
      return;
    }

    const cachedResponse = responseCache.get(finalRequestKey);

    // Return cached response if it exists
    if (cacheStrategy === CACHE_POLICIES.CACHE_FIRST && cachedResponse) {
      onSuccess(cachedResponse);
      return setResponse(cachedResponse);
    }

    // Return any cached data immediately, but initiate request anyway in order to refresh any stale data
    if (cacheStrategy === CACHE_POLICIES.CACHE_AND_NETWORK && cachedResponse) {
      onSuccess(cachedResponse);
      setResponse(cachedResponse, true);
    }

    // Almost like 'cache-and-network', but this clears the previous request key's response
    // and only shows a cached response if the request keys are identical
    // In other words it won't show the previous request key's data while fetching
    if (cacheStrategy === CACHE_POLICIES.EXACT_CACHE_AND_NETWORK) {
      if (cachedResponse) {
        onSuccess(cachedResponse);
        setResponse(cachedResponse, true);
      } else {
        setResponse(null);
      }
    }

    // Always fetch new data if request params change
    if (cacheStrategy === CACHE_POLICIES.NETWORK_ONLY) {
      // Reset response state since we are only interested in any cached response from previous request
      setResponse(null);
    }

    doFetch(init);
  }, [finalRequestKey, isLazy]);

  // Cancel any running request when unmounting to avoid updating state after component has unmounted
  // This can happen if a request's promise resolves after component unmounts
  useEffect(() => {
    return () => {
      /* NOTE: Added check. Do not cancel requests that might have side effects.
       * -- Louis
       */
      if (isRead) {
        cancelRunningRequest();
      }
    };
  }, [isRead]);

  /* NOTE: Added this to prevent changing state after unmounting.
   * -- Louis
   */
  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  return {
    response: state.response,
    data: state.response ? state.response.data : null,
    fetching: state.fetching,
    error: state.error,
    requestKey: finalRequestKey,
    doFetch
  };
}

export { useFetch };