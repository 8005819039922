import { useCallback, useMemo, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import { refresh } from '@utils/authentication';
import {
  tenantTokenSelector,
  tenantAuthenticationSelector,
} from '@recoil/tenant-token';
import { useFetch as useLibFetch } from './useLibFetch';

const urlPrefix = process.env.REACT_APP_API_URI;

type DoFetch<T = any> = (options: any) => Promise<T>;

type OnSuccess = (...args: any[]) => void;

const processUrl = (urlProp?: string | null) => {
  if (urlProp === undefined) return undefined;
  if (urlProp === null) return null;
  const slash = urlProp.startsWith('/') ? '' : '/';
  return `${urlPrefix}${slash}${urlProp}`;
};

const singledPromise = <Arguments extends unknown[], Result>(
  getPromise: (...args: Arguments) => Promise<Result>
) => {
  let promise: Promise<Result> | null = null;
  let promiseTime: Date | null = null;
  return (...args: Arguments) => {
    const currentTime = new Date();
    if (promise) {
      if (promiseTime) {
        const deltaTimeMilliseconds =
          currentTime.valueOf() - promiseTime.valueOf();
        const minDeltaTimeMilliseconds = 30 * 1000;
        if (deltaTimeMilliseconds < minDeltaTimeMilliseconds) {
          return promise;
        }
      }
    }

    promise = getPromise(...args);
    promiseTime = currentTime;

    return promise.finally(() => {
      promise = null;
      promiseTime = null;
    });
  };
};

const singledRefresh = singledPromise(refresh);

export default function useFetch<T = any>(props: any) {
  const ref = useRef<{ didFetch: boolean; onSuccess: OnSuccess | null }>({
    didFetch: false,
    onSuccess: null,
  });

  const optionsRef = useRef({});
  const doFetchRef = useRef<DoFetch<T> | null>();
  const navigate = useNavigate();
  const token = useRecoilValue(tenantTokenSelector);
  const localState = useRecoilValue(tenantAuthenticationSelector);
  const setLocalState = useSetRecoilState(tenantAuthenticationSelector);

  const url = processUrl(props?.url);
  const cachePolicy = props?.cachePolicy || 'network-only';

  ref.current.onSuccess = props?.onSuccess;
  const onSuccess = useCallback((...args) => {
    optionsRef.current = {};
    ref.current.onSuccess?.(...args);
  }, []);

  const onErrorProp = props?.onError;
  // TODO: check if onError can call stale methods
  const onError = useCallback(
    async responseOrError => {
      const isError = responseOrError instanceof Error;
      if (isError && responseOrError.name === 'AbortError') {
        return;
      }

      let ignoreErrorAndRetry = false;

      if (responseOrError.status === 401) {
        let tokenForRetry;
        const apiToken = await singledRefresh(
          localState.token.value,
          localState.refreshToken.value
        );
        if (!apiToken.succeeded) {
          const now = new Date();
          const storedToken = getRecoil(tenantAuthenticationSelector);
          const storedTokenExpires = storedToken?.token?.expires
            ? new Date(storedToken.token.expires)
            : null;
          const isStoredTokenValidAfterApiCall =
            storedTokenExpires && now < storedTokenExpires;

          if (!isStoredTokenValidAfterApiCall) {
            optionsRef.current = {};
            setLocalState(null);
            navigate('/signin');
            tokenForRetry = null;
            ignoreErrorAndRetry = false;
          } else {
            // Here, we skipped a race condition if multiple refresh requests happen at the same time.
            tokenForRetry = storedToken;
            ignoreErrorAndRetry = true;
          }
        } else {
          setLocalState(apiToken);
          tokenForRetry = apiToken;
          ignoreErrorAndRetry = true;
        }

        if (ignoreErrorAndRetry) {
          let headers: Record<string, string> = {
            Authorization: `Bearer ${tokenForRetry.token.value}`,
          };
          if (!(props?.noContentType ?? false)) {
            headers['Content-Type'] = 'application/json';
          }
          headers = { ...headers, ...props?.init?.headers };
          if (doFetchRef.current) {
            doFetchRef.current({
              ...optionsRef.current,
              headers,
            });
          }
        }
      }

      if (!ignoreErrorAndRetry) {
        onErrorProp?.(responseOrError);
      }
    },
    [
      onErrorProp,
      localState?.refreshToken.value,
      localState?.token.value,
      navigate,
      setLocalState,
      props?.noContentType,
      props?.init,
    ]
  );

  let headers: Record<string, string> = {
    Authorization: `Bearer ${token}`,
  };
  if (!(props?.noContentType ?? false)) {
    headers['Content-Type'] = 'application/json';
  }
  headers = { ...headers, ...props?.init?.headers };

  const result = useLibFetch({
    ...props,
    url,
    cachePolicy,
    init: {
      ...props?.init,
      headers,
    },
    onSuccess,
    onError,
  });

  const innerDoFetch = result.doFetch;
  const doFetch = useCallback(
    (options?: any) => {
      ref.current.didFetch = true;
      optionsRef.current = options || {};
      const processedUrl = processUrl(options?.url);
      return innerDoFetch({
        ...options,
        url: processedUrl,
      });
    },
    [innerDoFetch]
  );

  useEffect(() => {
    doFetchRef.current = doFetch;
  }, [doFetch]);

  ref.current.didFetch ||= Boolean(result.fetching);
  const { didFetch } = ref.current;
  const succeeded = didFetch && !result.fetching && !result.error;
  const errored = didFetch && !result.fetching && Boolean(result.error);

  return useMemo(
    () =>
      ({
        ...result,
        doFetch,
        didFetch,
        succeeded,
        errored,
        data: result.data as T | null,
      } as const),
    [result, doFetch, didFetch, succeeded, errored]
  );
}
