import React from 'react';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import FormikProvider from '@components/Formik/FormikProvider';
import FormikTextField from '@components/Formik/FormikTextField';
import useFetch from '@hooks/useFetch';
import { SigninLayout } from '../SignIn/SigninView';

export const defaultSchema = yup.object().shape({
  email: yup.string().ensure().trim().required('Generic.Fields.Required'),
});

export const defaultValues = {
  email: '',
};

function ForgotPasswordForm({ successUrl }) {
  const navigate = useNavigate();

  const forgotPassword = useFetch({
    method: 'POST',
    url: 'nexity-avenant-travaux/authentication/password/forgot',
    onSuccess: () => {
      navigate(successUrl);
    },
  });

  const onSubmitCallback = values => {
    forgotPassword.doFetch({ body: values });
  };

  const success =
    !forgotPassword.fetching &&
    forgotPassword.didFetch &&
    !forgotPassword.error;

  return (
    <Box>
      <FormikProvider
        schema={defaultSchema}
        initialValues={defaultValues}
        onSubmit={onSubmitCallback}
      >
        <ErrorMessage error={forgotPassword.error} sx={{ mt: 1, mb: 2 }} />
        {success ? (
          <>
            <Alert severity="success" sx={{ mt: 1, mb: 2 }}>
              Un email de réinitialisation de mot de passe vous a été envoyé.
            </Alert>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              component={Link}
              to="/"
            >
              <FormattedMessage id="ForgotPassword.GoToSignIn" />
            </Button>
          </>
        ) : (
          <>
            <FormikTextField
              as="fastfield"
              name="email"
              required
              autoComplete="email"
              label={
                <FormattedMessage id="ForgotPassword.Fields.Email.Label" />
              }
              disabled={forgotPassword.fetching}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              disabled={forgotPassword.fetching}
            >
              <FormattedMessage id="ForgotPassword.Button" />
            </Button>
          </>
        )}
      </FormikProvider>
    </Box>
  );
}

function ForgotPasswordView({ successUrl }) {
  return (
    <SigninLayout hideWelcome>
      <ForgotPasswordForm successUrl={successUrl} />
    </SigninLayout>
  );
}

export default ForgotPasswordView;
