import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Typography, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { SigninLayout } from '../SignIn/SigninView';

function ResetConfirmation({ signinUrl }) {
  return (
    <SigninLayout hideWelcome>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          <FormattedMessage
            id="ResetConfirmation.Success.Message"
            defaultMessage="Votre mot de passe a bien été réinitialisé !"
          />
        </Typography>
        <Button
          component={Link}
          variant="contained"
          color="primary"
          to={signinUrl || '/'}
          sx={{ ml: 4, width: '200px' }}
        >
          <FormattedMessage id="Signin.Button" defaultMessage="Se connecter" />
        </Button>
      </Box>
    </SigninLayout>
  );
}

export default ResetConfirmation;
