import React from 'react';
import { Plugin, PluginKey } from 'prosemirror-state';

import { TiptapContext } from './use-tiptap-context';

const ReactSyncExternalStoreKey = new PluginKey('ReactSyncExternalStore');

function TiptapProvider({ children }) {
  const [editor, setEditor] = React.useState(undefined);
  const [users, setUsers] = React.useState([]);

  const [listeners, subscribe, ReactSyncExternalStorePlugin] =
    React.useMemo(() => {
      const listeners = new Set();
      const subscribe = listener => {
        listeners.add(listener);
        return () => listeners.delete(listener);
      };

      /**
       * Direct plugin that updates all editor selectors.
       * https://prosemirror.net/docs/ref/#view.DirectEditorProps.plugins
       */
      const ReactSyncExternalStorePlugin = new Plugin({
        key: ReactSyncExternalStoreKey,
        view: view => ({
          update(view, prevState) {
            listeners.forEach(l => l(view, prevState));
          },
          destroy() {
            listeners.forEach(l => l(view, undefined));
          },
        }),
      });

      return [listeners, subscribe, ReactSyncExternalStorePlugin];
    }, []);

  return (
    <TiptapContext.Provider
      value={{
        editor,
        setEditor,
        users,
        setUsers,
        listeners,
        subscribe,
        ReactSyncExternalStorePlugin,
      }}
    >
      {children}
    </TiptapContext.Provider>
  );
}

export default TiptapProvider;
