import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import withPermissions from '@components/withTenantPermissions';
import tenantPermissions from '@utils/tenant-permissions';
import { EditTermSheetSchemaValueCardWithPermissions } from './EditTermSheetSchemaValueCard';
import { TermSheetSchemaEditorValue } from './template-term-sheet-schema-values';

type EditTermSheetSchemaValueItemProps = {
  value: TermSheetSchemaEditorValue;
  selected: boolean;
  onSelect: () => void;
};

const EditTermSheetSchemaValueItem = React.forwardRef<
  HTMLDivElement | null,
  EditTermSheetSchemaValueItemProps
>((props, ref) => {
  const { value, selected, onSelect, ...rest } = props;
  return (
    <ListItemButton
      ref={ref}
      disableGutters
      dense
      sx={{ px: 1 }}
      disableRipple
      onClick={onSelect}
      {...rest}
    >
      <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }} data-movable-handle>
        <DragIndicatorIcon />
      </ListItemIcon>
      <ListItemText
        onClick={onSelect}
        disableTypography
        primary={
          <EditTermSheetSchemaValueCardWithPermissions
            permissions={[tenantPermissions.TermSheetElementsUpdate]}
            value={value}
            selected={selected}
          />
        }
      />
    </ListItemButton>
  );
});

export const EditTermSheetSchemaValueItemWithPermissions = withPermissions(
  EditTermSheetSchemaValueItem
);

export default EditTermSheetSchemaValueItem;
