import React from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import DialogOutlet from './DialogOutlet';

export default function DialogOutletRoutes({
  path = 'modals/*',
  onClose: onCloseProp,
  children,
}) {
  const navigate = useNavigate();
  const handleClose = onCloseProp ?? (() => navigate('.'));

  return (
    <Routes>
      <Route path={path} element={<DialogOutlet open onClose={handleClose} />}>
        {children}
      </Route>
    </Routes>
  );
}
