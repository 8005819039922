import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Pagination from '@components/Pagination/Pagination';
import withPermissions from '@components/withTenantPermissions';
import TemplatesHeader from './TemplatesHeader';
import TemplatesList from './TemplatesList';

const LIMIT = 10;

function DriveTable({
  items,
  limit = LIMIT,
  page,
  setPage,
  sortMode,
  setSortMode,
  trash = false,
  parentId,
  useLocalTemplateEditorByDefault,
}) {
  const currentPageItems = items.slice((page - 1) * limit, page * limit);

  return (
    <Box>
      <Grid container>
        <TemplatesHeader sortMode={sortMode} setSortMode={setSortMode} />
        <Box sx={{ width: '100%', borderBottom: '1px solid #CCCCCC', my: 3 }} />
        <TemplatesList
          items={currentPageItems}
          trash={trash}
          parentId={parentId}
          useLocalTemplateEditorByDefault={useLocalTemplateEditorByDefault}
        />
      </Grid>
      <Pagination
        total={items?.length}
        limit={limit}
        page={page}
        setPage={setPage}
      />
    </Box>
  );
}

export const DriveTableWithPermissions = withPermissions(DriveTable);

export default DriveTable;
