import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Fetching from '@components/Fetching/Fetching';
import useFetch from '@hooks/useFetch';

import HtmlEditor from '@views/Tiptap/HtmlEditor';
import useCustomizedSnackbar from '@hooks/useCustomizedSnackbar';
import { ContractNoteResponse, ContractNotesListResponse } from '@api/types';
import FormattedDateTime from '@components/FormattedDates/FormattedDateTime';
import { getUserFullName } from '@utils/username-helpers';

const allowCreatingNote = false;

type ContractNotesViewProps = {
  contractId: string;
};

const titlePerNotificationType: Record<number, string> = {
  201: 'Rejet de document',
} as const;

function getNoteTitle(note: ContractNoteResponse) {
  if (note.notificationType) {
    return note.notificationType in titlePerNotificationType
      ? titlePerNotificationType[note.notificationType]
      : 'Note';
  }

  return note.title || 'Note';
}

function ContractNotesView({ contractId }: ContractNotesViewProps) {
  const { enqueueSnackbar } = useCustomizedSnackbar();
  const { formatMessage } = useIntl();

  const listFetch = useFetch<ContractNotesListResponse>({
    url: `/contracts/${contractId}/notes`,
    cachePolicy: 'network-only',
  });

  const createFetch = useFetch({
    url: '/html-contents',
    method: 'POST',
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: 'Editor.Notebook.Save.Success' }), {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(formatMessage({ id: 'Editor.Save.Failure' }), {
        variant: 'error',
      });
    },
  });

  const { doFetch: doCreate } = createFetch;
  const onSaveCallback = React.useCallback(
    newContent => {
      doCreate({
        body: {
          contentType: 'html',
          content: newContent,
        },
      });
    },
    [doCreate]
  );

  const rgpdAlert = (
    <Alert severity="info" sx={{ p: 2 }}>
      <AlertTitle>Mention RGPD</AlertTitle>
      Attention : cette zone de commentaire vous permet de vous exprimer
      librement et de rendre compte de votre activité. Afin de respecter la vie
      privée des personnes que vous pourriez citer, vous devez impérativement
      rédiger des commentaires objectifs et jamais excessifs ou insultants, à
      l’exclusion de toute donnée considérée comme sensible (origine raciale ou
      ethnique, opinions politiques, philosophiques ou religieuses, appartenance
      syndicale, données relatives à la santé ou à la vie sexuelle, infractions,
      condamnations, mesure de sûreté).
    </Alert>
  );

  return (
    <Stack direction="column" sx={{ width: '100%', mb: 5 }}>
      {allowCreatingNote ? (
        <HtmlEditor
          content={undefined}
          published={undefined}
          onSave={onSaveCallback}
          saveBtnProps={{ disabled: true }}
          prelude={rgpdAlert}
        />
      ) : (
        rgpdAlert
      )}
      <Fetching
        fetching={listFetch.fetching || createFetch.fetching}
        sx={{ pt: 2 }}
      >
        <List>
          {listFetch.data?.notes?.map(note => (
            <Fragment key={note.id}>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        component="span"
                        fontWeight="bold"
                        color="primary"
                      >
                        {getNoteTitle(note)}
                      </Typography>
                      {' - '}
                      <Typography component="span" fontWeight="bold">
                        {getUserFullName(note.creatorUser)}
                      </Typography>
                      {' - '}
                      <Typography component="span">
                        <FormattedDateTime value={note.creationTime} />
                      </Typography>
                    </>
                  }
                  secondary={note.content || ''}
                />
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
      </Fetching>
    </Stack>
  );
}

export default ContractNotesView;
